import React, { useState } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Input from '../../common/input';

function ScheduleStats() {
  const locationId = localStorage.getItem('locationId');

  const [stats, setStats] = useState({});

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const response = await http.post(`/report/getScheduleStatsReport`, {
        startDate,
        endDate,
        locationId,
      });
      setStats(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
      </div>
      <button
        className="my-3 me-5 btn-filled-primary input-width"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Run
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {isLoading && (
        <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
      )}
      {!isLoading && (
        <React.Fragment>
          <h2>Surgery Hours: {stats.surgeryHours}</h2>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ScheduleStats;

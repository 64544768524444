import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import http from '../../services/httpService';

import Header from './header';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import Input from '../common/input';

import Constants from '../../constants';

function ConsentForm({ documentTypeId, header, body }) {
  const params = useParams();
  const formId = params.formId;

  const [form, setForm] = useState(null);

  const [authorizingParty, setAuthorizingParty] = useState('');

  const sigCanvas = useRef();
  const [isSigned, setIsSigned] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getForm = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');

        const response = await http.get(`/forms/getById?form_id=${formId}`);
        setForm(response.data);

        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      }
    };

    if (formId) {
      getForm();
    }
  }, [formId]);

  const handleSign = () => {
    const canvas = sigCanvas.current.getCanvas();
    canvas.toBlob(async blob => {
      const formData = new FormData();

      formData.append('file', blob);
      formData.append('formId', form.id);
      formData.append('authorizingParty', authorizingParty);

      try {
        setErrorMessage('');
        await http.post('/forms/signConsentForm', formData, {
          'Content-type': 'multipart/form-data',
        });

        navigate(-1);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    });
  };

  return (
    <div className="py-5 background-gray d-flex flex-column flex-grow-1">
      <div className="container">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h2 className="sura fw-bolder">{header}</h2>
        {isLoading && (
          <i className="flex-centered w-100 p-4 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}
        <Header
          form={form}
          showPetDetails={documentTypeId === Constants.EUTHANASIA_CONSENT_FORM}
          handleIsLoading={setIsLoading}
          handleOwnerChange={setAuthorizingParty}
        />
        {!isLoading && (
          <React.Fragment>
            <Input
              className="mt-4"
              name="authorizingParty"
              value={authorizingParty}
              label="Authorizing Party"
              onChange={setAuthorizingParty}
            />
            {body}
            <div className="d-flex mt-5">
              <div>
                <label className="mb-2 font-16">Signature</label>
                <div
                  className="background-white"
                  style={{ width: '800px', height: '200px', border: '1px solid black' }}
                >
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{ className: 'w-100 h-100' }}
                    onEnd={() => setIsSigned(true)}
                  />
                </div>
              </div>
              <button
                className="ms-4 align-self-end"
                onClick={() => {
                  sigCanvas.current.clear();
                  setIsSigned(false);
                }}
              >
                <Icon name="fa fa-times-circle" />
                Clear
              </button>
            </div>
            <ButtonPanel
              primaryButtonText="Submit"
              handleCancel={() => navigate(-1)}
              handleSubmit={handleSign}
              handleErrorMessage={setErrorMessage}
              disabled={!authorizingParty || !isSigned}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default ConsentForm;

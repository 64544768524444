import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import http from '../../services/httpService';

import AdminClientOptions from './adminClientOptions';

import Icon from '../common/icon';
import { NotMobile } from '../common/responsive';
import Page from '../common/page';

import Billing from './billing';
import ClientHealthPlans from './clientHealthPlans';
import ClientPets from './clientPets';
import ClientPaymentPlans from './clientPaymentPlans';
import Documents from './documents';
import OneTimePayment from './oneTimePayment';
import Pet from '../pet/pet';
import PaymentMethods from './paymentMethods';

import { formatCurrency } from '../../util';

import '../../styles/components/client.scss';

function Client() {
  const location = useLocation();
  const params = useParams();

  const locationId = localStorage.getItem('locationId');
  const isLoveland = Number(locationId) === 1;

  const [client, setClient] = useState({
    pets: [],
    isCurrent: true,
    overdueHealthPlans: [],
    overduePaymentPlans: [],
  });

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getClient = async () => {
      try {
        setIsLoading(true);
        const { data: client } = await http.get(
          `/client/getCompleteById?client_id=${params.clientId}`
        );
        setClient(client);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getClient();
  }, [params.clientId]);

  const selectedSection = location.pathname.includes('pet')
    ? 'PET'
    : location.pathname.includes('health-plans')
    ? 'HEALTH_PLANS'
    : location.pathname.includes('payment-plans')
    ? 'PAYMENT_PLANS'
    : location.pathname.includes('billing')
    ? 'BILLING'
    : location.pathname.includes('payment-methods')
    ? 'PAYMENT_METHODS'
    : location.pathname.includes('documents')
    ? 'DOCUMENTS'
    : 'OVERVIEW';

  const clientIsActive = client.is_active === 'Y';

  return (
    <Page selectedTab="home">
      {errorMessage && <p className="error flex-centered h-100 background-white">{errorMessage}</p>}
      {!errorMessage && (
        <React.Fragment>
          <div className="client-info border-bottom">
            {isLoading && (
              <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle" />
            )}
            {!isLoading && (
              <React.Fragment>
                <div className="flex-row-aligned justify-content-between">
                  <h1 className="me-2">
                    <span className={`${client.isCurrent ? 'black' : 'red'}`}>
                      {client.first_name} {client.last_name}
                    </span>
                    <span className="ms-2 blue font-18">({client.daysmart_client_id})</span>
                  </h1>
                  <AdminClientOptions client={client} />
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div>
                    <div className="attribute">
                      <label>Status:</label>
                      {clientIsActive && <p>Active</p>}
                      {!clientIsActive && <p className="orange">Inactive</p>}
                    </div>
                    <div className="attribute">
                      <label>Address:</label>
                      <div className="mb-2">
                        {client.address && <p>{client.address}</p>}
                        {(client.city || client.state || client.zipcode) && (
                          <p>
                            {client.city &&
                              client.state &&
                              `${client.city}, ${client.state} ${client.zipcode || ''}`}
                            {!client.city &&
                              client.state &&
                              `${client.state} ${client.zipcode || ''}`}
                            {client.city &&
                              !client.state &&
                              `${client.city} ${client.zipcode || ''}`}
                            {!client.city && !client.state && `${client.zipcode || ''}`}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="attribute">
                      <label>Client Portal:</label>
                      <p>{client.username ? 'Registered' : 'Not Registered'}</p>
                    </div>
                  </div>
                  <div>
                    <div className="attribute">
                      <label>Email:</label>
                      <p>
                        {client.email}
                        {clientIsActive && client.email && client.remind_by_email === 'Y' && (
                          <Icon
                            className="ms-3 fa fa-bell attention"
                            tooltipText="Email Reminders Enabled"
                          />
                        )}
                        {clientIsActive && client.email && client.remind_by_email !== 'Y' && (
                          <Icon
                            className="ms-3 fa fa-bell-slash error"
                            tooltipText="Email Reminders Disabled"
                          />
                        )}
                      </p>
                    </div>
                    <div className="attribute">
                      <label>Mobile Phone:</label>
                      <p>
                        {client.mobile}
                        {clientIsActive && client.mobile && client.remind_by_text === 'Y' && (
                          <Icon
                            className="ms-3 fa fa-bell attention"
                            tooltipText="Text Reminders Enabled"
                          />
                        )}
                        {clientIsActive && client.mobile && client.remind_by_text !== 'Y' && (
                          <Icon
                            className="ms-3 fa fa-bell-slash error"
                            tooltipText="Text Reminders Disabled"
                          />
                        )}
                      </p>
                    </div>
                    <div className="attribute">
                      <label>Home Phone:</label>
                      <p>{client.home}</p>
                    </div>
                    <div className="attribute">
                      <label>Work Phone:</label>
                      <p>{client.work}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="d-flex">
                        <label className="black">Balance:</label>
                        <Link
                          to={`/client/${client.id}/billing`}
                          className="ms-3 mb-2 align-self-end btn-text-secondary"
                        >
                          <p
                            className={`${
                              client.balance === 0 ? 'black' : 'red'
                            } font-16 fw-bolder`}
                          >
                            {formatCurrency(client.balance)}
                          </p>
                        </Link>
                      </div>
                      {!isLoveland && client.balance > 0 && (
                        <button
                          className="btn-rounded-secondary mb-2 align-self-end"
                          onClick={() => setShowPaymentModal(true)}
                        >
                          Make Payment
                        </button>
                      )}
                    </div>
                    {client.overdueHealthPlans.length > 0 && (
                      <React.Fragment>
                        <label className="black">Overdue Health Plans:</label>
                        {client.overdueHealthPlans.map((t, i) => {
                          return (
                            <Link
                              key={i}
                              to={`/client/${client.id}/health-plans`}
                              className="mb-2 align-self-end btn-text-error"
                            >
                              <p className="font-16 red fw-bolder">
                                {t.name} - {formatCurrency(t.amount_due)}
                              </p>
                            </Link>
                          );
                        })}
                      </React.Fragment>
                    )}
                    {client.overduePaymentPlans.length > 0 && (
                      <React.Fragment>
                        <label className="black">Overdue Payment Plans:</label>
                        {client.overduePaymentPlans.map((t, i) => {
                          return (
                            <Link
                              key={i}
                              to={`/client/${client.id}/payment-plans`}
                              className="align-self-end btn-text-error"
                            >
                              <p className="font-16 red fw-bolder">
                                {formatCurrency(t.amount_due)}
                              </p>
                            </Link>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="d-flex mx-0 flex-grow-1">
            <div className="client-menu background-white box-shadow">
              <button
                className={`option flex-row-aligned ${
                  ['OVERVIEW', 'PET'].includes(selectedSection) ? 'selected' : ''
                }`}
                onClick={() => navigate(`/client/${client.id}`)}
                disabled={isLoading}
              >
                <NotMobile>
                  <Icon name="fa fa-paw" />
                </NotMobile>
                Pets
              </button>
              <button
                className={`option flex-row-aligned ${
                  selectedSection === 'HEALTH_PLANS' ? 'selected' : ''
                }`}
                onClick={() => navigate(`/client/${client.id}/health-plans`)}
                disabled={isLoading}
              >
                Health Plans
              </button>
              <button
                className={`option flex-row-aligned ${
                  selectedSection === 'PAYMENT_PLANS' ? 'selected' : ''
                }`}
                onClick={() => navigate(`/client/${client.id}/payment-plans`)}
                disabled={isLoading}
              >
                Payment Plans
              </button>
              <button
                className={`option flex-row-aligned ${
                  selectedSection === 'BILLING' ? 'selected' : ''
                }`}
                onClick={() => navigate(`/client/${client.id}/billing`)}
                disabled={isLoading}
              >
                Billing
              </button>
              <button
                className={`option flex-row-aligned ${
                  selectedSection === 'PAYMENT_METHODS' ? 'selected' : ''
                }`}
                onClick={() => navigate(`/client/${client.id}/payment-methods`)}
                disabled={isLoading}
              >
                Payment Methods
              </button>
              <button
                className={`option flex-row-aligned ${
                  selectedSection === 'DOCUMENTS' ? 'selected' : ''
                }`}
                onClick={() => navigate(`/client/${client.id}/documents`)}
                disabled={isLoading}
              >
                Documents
              </button>
            </div>
            <div className="d-flex flex-column w-100">
              {selectedSection === 'OVERVIEW' && (
                <ClientPets client={client} isLoading={isLoading} />
              )}
              {selectedSection === 'PET' && <Pet petId={params.petId} client={client} />}
              {selectedSection === 'HEALTH_PLANS' && <ClientHealthPlans client={client} />}
              {selectedSection === 'PAYMENT_PLANS' && <ClientPaymentPlans client={client} />}
              {selectedSection === 'BILLING' && <Billing clientId={client.id} />}
              {selectedSection === 'PAYMENT_METHODS' && <PaymentMethods clientId={client.id} />}
              {selectedSection === 'DOCUMENTS' && <Documents clientId={client.id} />}
            </div>
          </div>
          {showPaymentModal && (
            <OneTimePayment
              balance={client.balance}
              clientId={client.id}
              handleClose={() => setShowPaymentModal(false)}
            />
          )}
        </React.Fragment>
      )}
    </Page>
  );
}

export default Client;

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import InputTextarea from '../common/inputTextarea';

function ClockOutModal({ handleClose }) {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));
  const date = moment().tz('America/Denver').format('YYYY-MM-DD');

  const [activeTimepunch, setActiveTimepunch] = useState({});

  const [overrideCurrentTime, setOverrideCurrentTime] = useState(false);
  const [time, setTime] = useState(moment().tz('America/Denver').format('HH:mm'));
  const [notes, setNotes] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getActiveTimepunch = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(
          `/employee/getActiveTimepunch?employee_id=${loggedInUser.id}`
        );
        setActiveTimepunch(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getActiveTimepunch();
  }, [loggedInUser.id]);

  const handleSubmit = async () => {
    try {
      setErrorMessage('');
      const params = { notes, isOverride: 'N' };

      if (overrideCurrentTime) {
        params.isOverride = 'Y';
        params.overrideDate = date;
        params.overrideTime = time;
      }

      await http.post('/employee/clockOut', params);

      const loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));
      loggedInUser.is_clocked_in = false;
      localStorage.setItem('loggedIn', JSON.stringify(loggedInUser));
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {isLoading && <i className="fa fa-circle-notch fa-spin gray" />}
        {!isLoading && (
          <React.Fragment>
            <p className="error">{errorMessage}</p>
            <h1 className="mb-3">Clock Out</h1>
            <div className="mb-1 d-flex align-items-center">
              <h3>Employee: </h3>
              <p className="ms-3 sura">
                {loggedInUser.first_name} {loggedInUser.last_name}
              </p>
            </div>
            <div className="mb-1 d-flex align-items-center">
              <h3>Location: </h3>
              <p className="ms-3 sura">{activeTimepunch.location}</p>
            </div>
            <div className="mb-4 d-flex align-items-center">
              <h3>Clock In: </h3>
              <p className="ms-3 sura">
                {moment
                  .tz(activeTimepunch.clock_in_ts, 'America/Denver')
                  .format('MM-DD-YYYY hh:mm A')}
              </p>
            </div>
            <InputCheckbox
              className="mb-3"
              name="overrideCurrentTime"
              label="Override Current Time"
              checked={overrideCurrentTime}
              onChange={setOverrideCurrentTime}
            />
            {overrideCurrentTime && (
              <div className="input-width d-flex mb-3">
                <Input name="date" type="date" value={date} label="Date" disabled={true} />
                <Input name="time" type="time" value={time} label="Time" onChange={setTime} />
              </div>
            )}
            <InputTextarea name="notes" value={notes} label="Notes" rows="2" onChange={setNotes} />
            <ButtonPanel
              primaryButtonText="Clock Out"
              handleCancel={handleClose}
              handleSubmit={handleSubmit}
              turnOffProcessing={true}
              disabled={overrideCurrentTime && !notes}
            />
          </React.Fragment>
        )}
      </form>
    </Modal>
  );
}

export default ClockOutModal;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import http from '../../services/httpService';

import { formatCurrency } from '../../util';
import { Mobile, NotMobile } from '../common/responsive';

function DelinquencyReport() {
  const [healthPlans, setHealthPlans] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getDelinquentHealthPlans = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('/report/getDelinquencyReport');
        setHealthPlans(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDelinquentHealthPlans();
  }, []);

  return (
    <React.Fragment>
      <h1 className="pb-4">Delinquent Health Plans</h1>
      {isLoading && <i className="flex-centered m-5 fa fa-circle-notch fa-spin fa-2x subtle" />}
      {!isLoading && errorMessage && <p className="error">{errorMessage}</p>}
      {!isLoading && !errorMessage && (
        <React.Fragment>
          <Mobile>
            <React.Fragment>
              {healthPlans.map((t, i) => (
                <div
                  key={i}
                  className="d-flex flex-column border rounded p-3 mt-3"
                  onClick={() => navigate(`/client/${t.clientId}/pet/${t.petId}`)}
                >
                  <div className="flex-row-aligned justify-content-between">
                    <h2>{`${t.clientFirstName} ${t.clientLastName}`}</h2>
                  </div>
                  <span className="mt-2 ps-3">
                    <span className="sura me-2">Pet:</span>
                    {t.petName}
                  </span>
                  <span className="ps-3">
                    <span className="sura me-2">Amount Due:</span>
                    {formatCurrency(t.amountDue)}
                  </span>
                  <span className="ps-3">
                    <span className="sura me-2">Delinquency Start Date:</span>
                    {t.delinquencyStartDate}
                  </span>
                  <span className="ps-3">
                    <span className="sura me-2">Last Communication:</span>
                    {t.lastCommunication}
                  </span>
                </div>
              ))}
            </React.Fragment>
          </Mobile>
          <NotMobile>
            <div className="w-100">
              <table className="w-100">
                <thead className="border-bottom border-top">
                  <tr>
                    <th className="py-3 px-2">Client</th>
                    <th>Pet</th>
                    <th>Amount Due</th>
                    <th>Delinquency Start Date</th>
                    <th>Last Communication</th>
                  </tr>
                </thead>
                <tbody>
                  {healthPlans.map((t, i) => (
                    <tr key={i} className={i % 2 === 0 ? `background-gray` : ''}>
                      <td
                        className="p-2 btn-text-secondary pointer"
                        onClick={() => navigate(`/client/${t.clientId}`)}
                      >
                        {`${t.clientFirstName} ${t.clientLastName}`}
                      </td>
                      <td
                        className="btn-text-secondary pointer"
                        onClick={() => navigate(`/client/${t.clientId}/pet/${t.petId}`)}
                      >
                        {t.petName}
                      </td>
                      <td>{formatCurrency(t.amountDue)}</td>
                      <td>{t.delinquencyStartDate}</td>
                      <td>{t.lastCommunication}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </NotMobile>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default DelinquencyReport;

import React from 'react';
import moment from 'moment-timezone';

import { formatCurrency } from '../../util';

function ContractPreview({ previewInfo, isHealthPlan }) {
  const { isMonthly, paymentDates, initialPaymentInfo, monthlyPaymentInfo } = previewInfo;

  return (
    <div className="w-100">
      {isHealthPlan && (
        <table className="static-info-table">
          <tbody>
            <tr>
              <td style={{ width: '220px' }}>Health Plan</td>
              <td>{previewInfo.contractName}</td>
            </tr>
          </tbody>
        </table>
      )}

      <table className="mt-3 static-info-table">
        <tbody>
          <tr>
            <td style={{ width: '220px' }}>Contract Start Date</td>
            <td>{moment(previewInfo.startDate, 'YYYY-MM-DD').utc().format('MM-DD-YYYY')}</td>
          </tr>
          <tr>
            <td>Contract End Date</td>
            <td>{moment(previewInfo.endDate, 'YYYY-MM-DD').utc().format('MM-DD-YYYY')}</td>
          </tr>
        </tbody>
      </table>

      <table className="mt-3 static-info-table">
        <tbody>
          {isHealthPlan && (
            <tr>
              <td>Annual Participation Charge</td>
              <td>{formatCurrency(previewInfo.totalCost)}</td>
            </tr>
          )}
          {isHealthPlan && (
            <tr>
              <td>Payment Option</td>
              <td>{isMonthly ? 'Monthly' : 'Annual'}</td>
            </tr>
          )}
          {isMonthly && (
            <tr>
              <td style={{ width: '220px' }}>Billing Day</td>
              <td>{previewInfo.billingDay}</td>
            </tr>
          )}
        </tbody>
      </table>

      <table className="mt-3 static-info-table">
        <tbody>
          <tr>
            <td style={{ width: '220px' }}>Total Due</td>
            <td>{formatCurrency(previewInfo.totalCost)}</td>
          </tr>
        </tbody>
      </table>

      {isMonthly && monthlyPaymentInfo.items.length > 1 && (
        <div className="pt-5 pb-3">
          <p className="sura">
            <span className="bold">Monthly Payment:</span>
          </p>
          <div className="px-3">
            <table className="w-100">
              <tbody>
                {monthlyPaymentInfo.items.map((t, i) => (
                  <tr key={i}>
                    <td>{t.description}</td>
                    <td className="text-end">{formatCurrency(t.amount)}</td>
                  </tr>
                ))}
                <tr className="bold border-top border-dark">
                  <td>Total</td>
                  <td className="text-end">{formatCurrency(monthlyPaymentInfo.amount)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {initialPaymentInfo.items.length > 1 && (
        <div className="py-3">
          <p className="sura">
            <span className="bold">Due {paymentDates.firstPaymentDate}:</span>
          </p>
          <div className="px-3">
            <table className="w-100">
              <tbody>
                {initialPaymentInfo.items.map((t, i) => (
                  <tr key={i}>
                    <td>{t.description}</td>
                    <td className="text-end">{formatCurrency(t.amount)}</td>
                  </tr>
                ))}
                <tr className="bold border-top border-dark">
                  <td>Total</td>
                  <td className="text-end">{formatCurrency(initialPaymentInfo.amount)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {initialPaymentInfo.items.length === 1 && (
        <p className="pt-4 pb-2">
          <span className="bold me-2 sura">Due {paymentDates.firstPaymentDate}:</span>
          {formatCurrency(initialPaymentInfo.items[0].amount)}
        </p>
      )}
      {isMonthly && paymentDates.remainingPaymentDates.length > 0 && (
        <div className="pt-2 pb-3">
          <p className="mb-1 sura">
            Remaining Payments of{' '}
            <span className="text-decoration-underline">
              <nobr>{formatCurrency(monthlyPaymentInfo.amount)}</nobr>
            </span>{' '}
            will be charged on the following dates:
          </p>
          {paymentDates.remainingPaymentDates.map(t => (
            <div key={t} className="ms-4">
              {t}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ContractPreview;

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Constants from '../../constants';

function Documents({ clientId }) {
  const [documents, setDocuments] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const response = await http.get(`/client/getDocuments?client_id=${clientId}`);
        const grouped = response.data.reduce((rv, x) => {
          (rv[x.name] = rv[x.name] || []).push(x);
          return rv;
        }, {});
        setDocuments(grouped);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    if (clientId) {
      getDocuments();
    }
  }, [clientId]);

  const getTable = documents => {
    return (
      <table>
        <thead>
          <tr className="border-bottom">
            <th>Document Type</th>
            <th>Creation Date</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((t, i) => (
            <tr key={i}>
              <td>
                <a style={{ color: 'black' }} href={t.aws_url} target="_blank" rel="noreferrer">
                  {
                    Constants.DOCUMENT_TYPE_MAP.find(s => s.value === Number(t.document_type_id))
                      .name
                  }
                </a>
              </td>
              <td>{moment(t.insert_ts).format('MM-DD-YYYY hh:mm A')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="content-panel">
      {!clientId && <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle" />}
      {clientId && (
        <React.Fragment>
          {errorMessage && <p className="error">{errorMessage}</p>}
          <div>
            {Object.keys(documents).length > 0 && (
              <div className="p-2">
                {documents['null'] && <div className="mb-5">{getTable(documents['null'])}</div>}
                {Object.keys(documents)
                  .sort((a, b) => a.localeCompare(b))
                  .map((t, i) => {
                    if (t === 'null') return null;
                    return (
                      <div key={i} className="mb-5">
                        <h2 className="mb-4">{t}</h2>
                        {getTable(documents[t])}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Documents;

import React from 'react';
import { useNavigate } from 'react-router-dom';

import DelinquencyReport from './delinquencyReport';
import EmployeeDashboard from './employeeDashboard';

import Icon from '../common/icon';
import Page from '../common/page';
import { Mobile, NotMobile } from '../common/responsive';

function Admin() {
  const navigate = useNavigate();

  return (
    <Page selectedTab="admin">
      <div className="background-white box-shadow flex-grow-1">
        <Mobile>
          <div className="p-3">
            <EmployeeDashboard />
            <div className="mt-5">
              <DelinquencyReport />
            </div>
          </div>
        </Mobile>
        <NotMobile>
          <div className="p-5">
            <button className="my-3" onClick={() => navigate('/review-dashboard')}>
              <Icon name="fa fa-brands fa-google" />
              <span className="hover-underline">Review Dashboard</span>
            </button>
            <EmployeeDashboard />
            <button className="mt-3" onClick={() => navigate('/timesheets')}>
              <Icon name="fa fa-clock" />
              <span className="hover-underline">View Employee Time Cards</span>
            </button>
            <div className="mt-5">
              <DelinquencyReport />
            </div>
          </div>
        </NotMobile>
      </div>
    </Page>
  );
}

export default Admin;

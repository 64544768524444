import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ContractPreview from './contractPreview';

import ButtonPanel from '../common/buttonPanel';

function FinalizeContract({ contract, handleClose }) {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');
    await http.post(
      `/${contract.isHealthPlan ? 'healthPlan' : 'paymentPlan'}/finalize?contract_id=${
        contract.id
      }&contract_type_id=${contract.contract_type_id}`
    );
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h2 className="mb-4">Preview</h2>
        <ContractPreview
          previewInfo={JSON.parse(contract.initial_setup_info)}
          isHealthPlan={contract.isHealthPlan}
        />
        <ButtonPanel
          primaryButtonText="Finalize Plan"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default FinalizeContract;

import React, { useState, useEffect } from 'react';

import http from '../../../services/httpService';

import { formatCurrency } from '../../../util';

function PaymentPlanStats() {
  const [results, setResults] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getPaymentPlanStats = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/report/getPaymentPlanStats`);
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getPaymentPlanStats();
  }, []);

  if (isLoading || !results) return <i className="mt-3 fa fa-circle-notch fa-spin subtle" />;
  if (errorMessage) return <p className="error">{errorMessage}</p>;

  return (
    <React.Fragment>
      <div>
        <table>
          <thead>
            <tr>
              <th className="input-width"></th>
              <th className="input-width">Loveland</th>
              <th className="input-width">Evans</th>
              <th className="input-width">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Count</td>
              <td>{results.lovelandCount}</td>
              <td>{results.evansCount}</td>
              <td>{results.totalCount}</td>
            </tr>
            <tr>
              <td>MRR</td>
              <td>{formatCurrency(results.lovelandMRR)}</td>
              <td>{formatCurrency(results.evansMRR)}</td>
              <td>{formatCurrency(results.totalMRR)}</td>
            </tr>
            <tr>
              <td>MRR added in the last 30 days</td>
              <td>{formatCurrency(results.lovelandNewMRR)}</td>
              <td>{formatCurrency(results.evansNewMRR)}</td>
              <td>{formatCurrency(results.totalNewMRR)}</td>
            </tr>
            <tr>
              <td>MRR ending in the next 30 days</td>
              <td>{formatCurrency(results.lovelandEndingMRR)}</td>
              <td>{formatCurrency(results.evansEndingMRR)}</td>
              <td>{formatCurrency(results.totalEndingMRR)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default PaymentPlanStats;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';

function ClientModal({ client, handleClose }) {
  const [firstName, setFirstName] = useState(client ? client.first_name : '');
  const [lastName, setLastName] = useState(client ? client.last_name : '');
  const [address, setAddress] = useState(client ? client.address || '' : '');
  const [city, setCity] = useState(client ? client.city || '' : '');
  const [state, setState] = useState(client ? client.state || '' : 'CO');
  const [zipcode, setZipcode] = useState(client ? client.zipcode || '' : '');
  const [email, setEmail] = useState(client ? client.email || '' : '');
  const [mobile, setMobile] = useState(client ? client.mobile || '' : '');
  const [home, setHome] = useState(client ? client.home || '' : '');
  const [work, setWork] = useState(client ? client.work || '' : '');
  const [remindByEmail, setRemindByEmail] = useState(
    client && client.email ? client.remind_by_email === 'Y' : true
  );
  const [remindByText, setRemindByText] = useState(
    client && client.mobile ? client.remind_by_text === 'Y' : true
  );

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      firstName,
      lastName,
      address,
      city,
      state,
      zipcode,
      email,
      mobile,
      home,
      work,
      remindByEmail: email && remindByEmail ? 'Y' : 'N',
      remindByText: mobile && remindByText ? 'Y' : 'N',
    };

    if (client) {
      params.clientId = client.id;
      await http.put('/client/update', params);
      navigate(0);
    } else {
      const response = await http.post('/client/create', params);
      navigate(`client/${response.data}`);
    }
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form className="background-gray box-shadow-bottom p-3 rounded-bottom">
        <div className="p-4">
          {errorMessage && <p className="error align-self-start">{errorMessage}</p>}
          <h1 className="mb-4">New Client</h1>
          <Input name="firstName" value={firstName} label="First Name*" onChange={setFirstName} />
          <Input name="lastName" value={lastName} label="Last Name*" onChange={setLastName} />
          <Input name="address" value={address} label="Address" onChange={setAddress} />
          <Input name="city" value={city} label="City" onChange={setCity} />
          <Input name="state" value={state} label="State" onChange={setState} />
          <Input name="zipcode" value={zipcode} label="Zipcode" onChange={setZipcode} />
          <Input name="email" type="email" value={email} label="Email" onChange={setEmail} />
          {email && (
            <InputCheckbox
              className="mb-3 d-flex"
              name="remindByEmail"
              label="Allow Email Reminders"
              checked={remindByEmail}
              onChange={setRemindByEmail}
            />
          )}
          <Input name="mobile" value={mobile} label="Mobile Phone" onChange={setMobile} />
          {mobile && (
            <InputCheckbox
              className="mb-3 d-flex"
              name="remindByText"
              label="Allow Text Reminders"
              checked={remindByText}
              onChange={setRemindByText}
            />
          )}
          <Input name="home" value={home} label="Home Phone" onChange={setHome} />
          <Input name="work" value={work} label="Work Phone" onChange={setWork} />
          <ButtonPanel
            primaryButtonText="Save"
            handleCancel={handleClose}
            handleSubmit={() => handleSubmit(false)}
            turnOffProcessing={true}
            handleErrorMessage={setErrorMessage}
          />
        </div>
      </form>
    </Modal>
  );
}

export default ClientModal;

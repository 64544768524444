import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import PaymentMethodWidget from '../payment-method/paymentMethodWidget';

import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import ButtonPanel from '../common/buttonPanel';

import { formatCurrency, validateCurrency } from '../../util';

import Constants from '../../constants';

function OneTimePayment({ balance, clientId, contractId, handleClose }) {
  const locationId = localStorage.getItem('locationId');

  const [paymentMethod, setPaymentMethod] = useState({ isStored: 'Y', isPreview: 'N' });
  const [fullOrPartial, setFullOrPartial] = useState('full');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [previewPaymentAmount, setPreviewPaymentAmount] = useState(balance);

  const [emailReceipt, setEmailReceipt] = useState(false);
  const [email, setEmail] = useState('');

  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getClient = async () => {
      try {
        const { data: client } = await http.get(`/client/getById?client_id=${clientId}`);
        setEmail(client.email);
        setEmailReceipt(Boolean(client.email));
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };

    if (!contractId) {
      getClient();
    }
  }, [clientId, contractId]);

  const handleFullOrPartialChange = value => {
    setFullOrPartial(value);
    setPaymentAmount(value === 'full' ? balance : 0);
    setPreviewPaymentAmount(value === 'full' ? balance : 0);
  };

  const handlePaymentAmountChange = ({ target }) => {
    if (!validateCurrency(target.value)) {
      return;
    }
    setPaymentAmount(target.value);
    setPreviewPaymentAmount(Math.round(target.value * 100));
  };

  const handleSendToTerminal = async () => {
    setErrorMessage('');
    const response = await http.post('/terminal/createPaymentIntent', {
      clientId,
      contractId,
      paymentAmount: previewPaymentAmount,
      readerId: paymentMethod.readerId,
      locationId,
      emailReceipt: emailReceipt ? 'Y' : 'N',
      email: emailReceipt ? email : '',
    });
    setPaymentIntent(response.data);
    setShowTerminalModal(true);
  };

  const handleCancelPaymentIntent = async () => {
    await http.post('/terminal/cancelPaymentIntent', {
      paymentIntentId: paymentIntent.id,
      readerId: paymentMethod.readerId,
    });
    setPaymentIntent('');
    setShowTerminalModal(false);
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      clientId,
      contractId,
      paymentAmount: previewPaymentAmount,
      paymentMethod: paymentMethod,
      locationId,
      emailReceipt: emailReceipt ? 'Y' : 'N',
      email: emailReceipt ? email : '',
    };

    await http.post('/client/oneTimePayment', params);
    setTimeout(() => navigate(0), 3000);
  };

  if (showTerminalModal) {
    return (
      <TerminalModal
        paymentIntentId={paymentIntent.id}
        emailReceipt={emailReceipt}
        email={email}
        handleClose={handleCancelPaymentIntent}
      />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h2>Make a Payment</h2>
        <span className="fw-bolder font-16 my-2">{`${formatCurrency(balance)} due now`}</span>
        <div className="radio-line align-items-start mb-2">
          <input
            className="mt-2"
            type="radio"
            id="full"
            name="fullOrPartial"
            checked={fullOrPartial === 'full'}
            value="full"
            onChange={({ target }) => handleFullOrPartialChange(target.value)}
          />
          <label className="fw-bolder news-cycle font-16" htmlFor="full">
            Pay Outstanding Balance: {formatCurrency(balance)}
          </label>
        </div>
        <div className="radio-line align-items-start mb-3">
          <input
            className="mt-2"
            type="radio"
            id="partial"
            name="fullOrPartial"
            checked={fullOrPartial === 'partial'}
            value="partial"
            onChange={({ target }) => handleFullOrPartialChange(target.value)}
          />
          <label className="fw-bolder news-cycle font-16" htmlFor="partial">
            Pay a different amount
            {fullOrPartial === 'partial' && (
              <React.Fragment>
                <input
                  className="d-block border-0 mt-2"
                  type="text"
                  id="paymentAmount"
                  name="paymentAmount"
                  value={paymentAmount}
                  onChange={handlePaymentAmountChange}
                  disabled={fullOrPartial === 'full'}
                />
                <p className="mt-1 ms-1">
                  *Remaining balance: {formatCurrency(balance - previewPaymentAmount)}
                </p>
              </React.Fragment>
            )}
          </label>
        </div>
        <PaymentMethodWidget
          clientId={clientId}
          selectedPaymentMethod={paymentMethod}
          handleErrorMessage={setErrorMessage}
          handlePaymentMethodChange={paymentMethod => setPaymentMethod({ ...paymentMethod })}
        />

        {!contractId && (
          <InputCheckbox
            className="my-3"
            name="emailReceipt"
            label="Email Receipt"
            checked={emailReceipt}
            onChange={setEmailReceipt}
          />
        )}

        {emailReceipt && (
          <Input name="email" className="mb-3" label="Email" value={email} onChange={setEmail} />
        )}

        {paymentMethod.category !== Constants.TERMINAL && (
          <ButtonPanel
            primaryButtonText="Process Payment"
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            handleErrorMessage={setErrorMessage}
          />
        )}

        {paymentMethod.category === Constants.TERMINAL && (
          <ButtonPanel
            primaryButtonText="Send to Terminal"
            handleCancel={handleClose}
            handleSubmit={handleSendToTerminal}
            handleErrorMessage={setErrorMessage}
            turnOffProcessing={true}
            disabled={!previewPaymentAmount || !paymentMethod.readerId}
          />
        )}
      </form>
    </Modal>
  );
}

function TerminalModal({ paymentIntentId, emailReceipt, email, handleClose }) {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await http.get(
        `/terminal/getPaymentIntent?payment_intent_id=${paymentIntentId}`
      );
      const paymentIntent = response.data;

      if (paymentIntent.status === 'requires_capture') {
        await http.post('/terminal/capturePaymentIntent', {
          paymentIntentId,
          emailReceipt: emailReceipt ? 'Y' : 'N',
          email: emailReceipt ? email : '',
        });
        navigate(0);
      } else if (paymentIntent.last_payment_error) {
        setErrorMessage(paymentIntent.last_payment_error.message);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [paymentIntentId, emailReceipt, email, navigate]);

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && (
          <React.Fragment>
            <p className="sura font-16">The payment failed with the following error:</p>
            <p className="sura error mt-2">{errorMessage}</p>
          </React.Fragment>
        )}
        {!errorMessage && (
          <React.Fragment>
            <i className="flex-centered w-100 p-4 fa fa-circle-notch fa-spin fa-2x subtle" />
            <p className="sura">Waiting for client to provide payment method</p>
          </React.Fragment>
        )}
        <button
          className="btn-filled-primary mt-4 align-self-center"
          onClick={e => {
            e.preventDefault();
            handleClose();
          }}
        >
          {errorMessage ? 'Back' : 'Cancel'}
        </button>
      </form>
    </Modal>
  );
}

export default OneTimePayment;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';
import Page from '../common/page';

import EmailEstimateModal from './emailEstimateModal';

import EstimateConstants from '../../estimateConstants';

function Estimates() {
  const [estimates, setEstimates] = useState([]);

  const [searchString, setSearchString] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getEstimates = async () => {
      try {
        const response = await http.get('/estimate/getEstimates');
        setEstimates(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    getEstimates();
  }, []);

  const handleDeleteEstimate = async () => {
    await http.delete(`/estimate/delete?estimate_id=${selectedEstimate.id}`);
    navigate(0);
  };

  return (
    <Page selectedTab="estimates">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1 className="pb-4">Estimates</h1>
        <Input name="searchString" value={searchString} label="Search" onChange={setSearchString} />
        <button
          className="mb-3 me-5 btn-text-primary align-self-end"
          onClick={() => navigate('/estimate-generator')}
        >
          <Icon name="add" />
          Create New
        </button>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div>
          {estimates.length > 0 && (
            <div className="p-2">
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th>Pet</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Health Plan</th>
                    <th>Creation Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {estimates
                    .filter(t => {
                      if (!searchString) return t;
                      if (t.name.toLowerCase().includes(searchString)) return t;
                      if (t.last_name.toLowerCase().includes(searchString)) return t;
                      if (t.daysmart_pet_id.includes(searchString)) return t;
                      return null;
                    })
                    .map((t, i) => {
                      const info = JSON.parse(t.info);
                      const isHpComparison = Number(info.type) === EstimateConstants.HP_COMPARISON;
                      return (
                        <tr key={i}>
                          <td
                            className="pointer hover-underline"
                            onClick={() => navigate(`/client/${t.client_id}/pet/${t.pet_id}`)}
                          >
                            {t.name} {t.last_name} ({t.daysmart_pet_id})
                          </td>
                          <td>{info.title}</td>
                          <td>{isHpComparison ? 'HP Comparison' : 'Treatment Plan'}</td>
                          <td>
                            {isHpComparison
                              ? ''
                              : EstimateConstants.HP_OPTIONS_MAP[
                                  info.displayVersions.findIndex(t => t)
                                ].name}
                          </td>
                          <td>{moment(t.insert_ts).format('MM-DD-YYYY')}</td>
                          <td>
                            <div className="d-flex">
                              <button onClick={() => navigate(`/estimate-generator/${t.id}`)}>
                                <Icon name="edit" className="blue m-0" />
                              </button>
                              <button
                                className="ms-2"
                                onClick={() => navigate(`/estimate-generator/duplicate/${t.id}`)}
                              >
                                <Icon name="duplicate" className="orange m-0" />
                              </button>
                              <button
                                className="ms-2"
                                type="button"
                                onClick={() => {
                                  setSelectedEstimate(t);
                                  setShowEmailModal(true);
                                }}
                              >
                                <Icon name="email" className="attention m-0" />
                              </button>
                              <Icon
                                name="ms-2 fa fa-user align-self-center"
                                tooltipText={info.createdBy ? `Created by ${info.createdBy}` : ''}
                                className="m-0"
                              />
                              <button
                                className="ms-2"
                                type="button"
                                onClick={() => {
                                  setSelectedEstimate(t);
                                  setShowDeleteConfirmationModal(true);
                                }}
                              >
                                <Icon name="delete" className="error m-0" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {showEmailModal && (
        <EmailEstimateModal
          estimateId={selectedEstimate.id}
          handleClose={() => setShowEmailModal(false)}
        />
      )}
      {showDeleteConfirmationModal && (
        <Confirmation
          message="Are you sure you would like to delete this document?"
          handleConfirm={handleDeleteEstimate}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
    </Page>
  );
}

export default Estimates;

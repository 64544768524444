import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Input from '../../common/input';
import TooltipWrapper from '../../common/tooltipWrapper';

import { formatCurrency } from '../../../util';

function RevenueStats() {
  const [results, setResults] = useState(null);

  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').tz('America/Denver').format('YYYY-MM-DD')
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getRevenueStats = async () => {
      try {
        setIsLoading(true);
        const response = await http.post(`/report/getRevenueStats`, {
          startDate,
          endDate,
        });
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getRevenueStats();
  }, [startDate, endDate]);

  if (isLoading || !results) return <i className="mt-3 fa fa-circle-notch fa-spin subtle" />;
  if (errorMessage) return <p className="error">{errorMessage}</p>;

  return (
    <React.Fragment>
      <div className="d-flex mt-2">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Previous Month
        </button>
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .add(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .add(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Next Month
        </button>
      </div>

      <div className="mt-4">
        <table className="ms-4">
          <thead>
            <tr>
              <th style={{ width: '200px' }}></th>
              <th style={{ width: '100px' }}>Loveland</th>
              <th style={{ width: '100px' }}>Evans</th>
              <th style={{ width: '100px' }}>Total</th>
            </tr>
          </thead>
        </table>
        <h3 className="mb-2">Completed</h3>
        <table className="ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Doctor hours</td>
              <td style={{ width: '100px' }}>{results[1].doctorHours.toFixed(2)}</td>
              <td style={{ width: '100px' }}>{results[2].doctorHours.toFixed(2)}</td>
              <td style={{ width: '100px' }}>
                {(results[1].doctorHours + results[2].doctorHours).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{results[1].surgeryHours.toFixed(2)}</td>
              <td>{results[2].surgeryHours.toFixed(2)}</td>
              <td>{(results[1].surgeryHours + results[2].surgeryHours).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{results[1].roomHours.toFixed(2)}</td>
              <td>{results[2].roomHours.toFixed(2)}</td>
              <td>{(results[1].roomHours + results[2].roomHours).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Appointments</td>
              <td style={{ width: '100px' }}>{results[1].apptCount}</td>
              <td style={{ width: '100px' }}>{results[2].apptCount}</td>
              <td style={{ width: '100px' }}>{results[1].apptCount + results[2].apptCount}</td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{results[1].surgeryApptCount}</td>
              <td>{results[2].surgeryApptCount}</td>
              <td>{results[1].surgeryApptCount + results[2].surgeryApptCount}</td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{results[1].roomApptCount}</td>
              <td>{results[2].roomApptCount}</td>
              <td>{results[1].roomApptCount + results[2].roomApptCount}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Appointments/Doctor Hour</td>
              <td style={{ width: '100px' }}>{results[1].apptPerDoctorHour.toFixed(2)}</td>
              <td style={{ width: '100px' }}>{results[2].apptPerDoctorHour.toFixed(2)}</td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{results[1].sxPerSxHour.toFixed(2)}</td>
              <td>{results[2].sxPerSxHour.toFixed(2)}</td>
              <td></td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{results[1].roomApptsPerRoomHour.toFixed(2)}</td>
              <td>{results[2].roomApptsPerRoomHour.toFixed(2)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Retail Value</td>
              <td style={{ width: '100px' }}>{formatCurrency(results[1].retailValue)}</td>
              <td style={{ width: '100px' }}>{formatCurrency(results[2].retailValue)}</td>
              <td style={{ width: '100px' }}>
                {formatCurrency(results[1].retailValue + results[2].retailValue)}
              </td>
            </tr>
            <tr>
              <td>Total Revenue</td>
              <td>{formatCurrency(results[1].totalRevenue)}</td>
              <td>{formatCurrency(results[2].totalRevenue)}</td>
              <td>{formatCurrency(results[1].totalRevenue + results[2].totalRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-3">In-Clinic</td>
              <td>{formatCurrency(results[1].inClinicRevenue)}</td>
              <td>{formatCurrency(results[2].inClinicRevenue)}</td>
              <td>{formatCurrency(results[1].inClinicRevenue + results[2].inClinicRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-5">Regular Doctor</td>
              <td>{formatCurrency(results[1].regularInClinicRevenue)}</td>
              <td>{formatCurrency(results[2].regularInClinicRevenue)}</td>
              <td>
                {formatCurrency(
                  results[1].regularInClinicRevenue + results[2].regularInClinicRevenue
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-5">Randy</td>
              <td>{formatCurrency(results[1].randyRevenue)}</td>
              <td>{formatCurrency(results[2].randyRevenue)}</td>
              <td>{formatCurrency(results[1].randyRevenue + results[2].randyRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan</td>
              <td>{formatCurrency(results[1].healthPlanRevenue)}</td>
              <td>{formatCurrency(results[2].healthPlanRevenue)}</td>
              <td>{formatCurrency(results[1].healthPlanRevenue + results[2].healthPlanRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-3">Payment Plan</td>
              <td>{formatCurrency(results[1].paymentPlanRevenue)}</td>
              <td>{formatCurrency(results[2].paymentPlanRevenue)}</td>
              <td>
                {formatCurrency(results[1].paymentPlanRevenue + results[2].paymentPlanRevenue)}
              </td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="Does not include Randy"
                element={<td>Total Revenue (Adj.)</td>}
              />
              <td>{formatCurrency(results[1].adjTotalRevenue)}</td>
              <td>{formatCurrency(results[2].adjTotalRevenue)}</td>
              <td>{formatCurrency(results[1].adjTotalRevenue + results[2].adjTotalRevenue)}</td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="This is the amount we diverted to Payment Plans. It is not included in revenue. Anything we collected on these new payment plans is captured as Payment Plan revenue."
                element={<td>Paid by Payment Plan</td>}
              />
              <td>{formatCurrency(results[1].paidByPaymentPlan)}</td>
              <td>{formatCurrency(results[2].paidByPaymentPlan)}</td>
              <td>{formatCurrency(results[1].paidByPaymentPlan + results[2].paidByPaymentPlan)}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Retail/Doctor Hour</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Total</td>
              <td>{formatCurrency(results[1].totalRetailPerDoctorHour)}</td>
              <td>{formatCurrency(results[2].totalRetailPerDoctorHour)}</td>
              <td></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="Does not include Randy"
                element={<td className="ps-3">Total (Adj.)</td>}
              />
              <td>{formatCurrency(results[1].adjRetailPerDoctorHour)}</td>
              <td>{formatCurrency(results[2].adjRetailPerDoctorHour)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Revenue/Doctor Hour</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="Revenue collected from Health Plans, Payment Plans, and in clinic. Does not include Randy"
                element={<td className="ps-3">Total (Adj.)</td>}
              />
              <td>{formatCurrency(results[1].totalRevenuePerDoctorHour)}</td>
              <td>{formatCurrency(results[2].totalRevenuePerDoctorHour)}</td>
              <td></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="Revenue collected in clinic. Does not include Randy"
                element={<td className="ps-3">In-Clinic</td>}
              />
              <td>{formatCurrency(results[1].inClinicRevenuePerDoctorHour)}</td>
              <td>{formatCurrency(results[2].inClinicRevenuePerDoctorHour)}</td>
              <td></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="In-Clinic revenue plus revenue that would have been collected without Payment Plans"
                element={<td className="ps-3">In-Clinic (Adj.)</td>}
              />
              <td>{formatCurrency(results[1].adjInClinicRevenuePerDoctorHour)}</td>
              <td>{formatCurrency(results[2].adjInClinicRevenuePerDoctorHour)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Revenue/Appointment</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="Revenue collected from Health Plans, Payment Plans, and in clinic. Does not include Randy"
                element={<td className="ps-3">Total (Adj.)</td>}
              />
              <td>{formatCurrency(results[1].totalRevenuePerAppt)}</td>
              <td>{formatCurrency(results[2].totalRevenuePerAppt)}</td>
              <td></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="Revenue collected in clinic. Does not include Randy"
                element={<td className="ps-3">In-Clinic</td>}
              />
              <td>{formatCurrency(results[1].inClinicRevenuePerAppt)}</td>
              <td>{formatCurrency(results[2].inClinicRevenuePerAppt)}</td>
              <td></td>
            </tr>
            <tr>
              <TooltipWrapper
                tooltipText="In-Clinic revenue plus revenue that would have been collected without Payment Plans"
                element={<td className="ps-3">In-Clinic (Adj.)</td>}
              />
              <td>{formatCurrency(results[1].adjInClinicRevenuePerAppt)}</td>
              <td>{formatCurrency(results[2].adjInClinicRevenuePerAppt)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      {moment().tz('America/Denver').isBefore(endDate) && (
        <div className="mt-5">
          <h3 className="mb-1">Projected</h3>
          <table className="ms-4">
            <tbody>
              <tr>
                <td style={{ width: '200px' }}>Doctor hours</td>
                <td style={{ width: '100px' }}>{results[1].projectedDoctorHours.toFixed(2)}</td>
                <td style={{ width: '100px' }}>{results[2].projectedDoctorHours.toFixed(2)}</td>
                <td style={{ width: '100px' }}>
                  {(results[1].projectedDoctorHours + results[2].projectedDoctorHours).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Surgery</td>
                <td>{results[1].projectedSurgeryHours.toFixed(2)}</td>
                <td>{results[2].projectedSurgeryHours.toFixed(2)}</td>
                <td>
                  {(results[1].projectedSurgeryHours + results[2].projectedSurgeryHours).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Rooms</td>
                <td>{results[1].projectedRoomHours.toFixed(2)}</td>
                <td>{results[2].projectedRoomHours.toFixed(2)}</td>
                <td>
                  {(results[1].projectedRoomHours + results[2].projectedRoomHours).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="mt-3 ms-4">
            <tbody>
              <tr>
                <td style={{ width: '200px' }}>Appointments</td>
                <td style={{ width: '100px' }}>{results[1].projectedAppts}</td>
                <td style={{ width: '100px' }}>{results[2].projectedAppts}</td>
                <td style={{ width: '100px' }}>
                  {results[1].projectedAppts + results[2].projectedAppts}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="mt-3 ms-4">
            <tbody>
              <tr>
                <td style={{ width: '200px' }}>Total Revenue</td>
                <td style={{ width: '100px' }}>
                  {formatCurrency(results[1].projectedTotalRevenue)}
                </td>
                <td style={{ width: '100px' }}>
                  {formatCurrency(results[2].projectedTotalRevenue)}
                </td>
                <td style={{ width: '100px' }}>
                  {formatCurrency(
                    results[1].projectedTotalRevenue + results[2].projectedTotalRevenue
                  )}
                </td>
              </tr>
              <tr>
                <td className="ps-3">In-Clinic</td>
                <td>{formatCurrency(results[1].projectedInClinicRevenue)}</td>
                <td>{formatCurrency(results[2].projectedInClinicRevenue)}</td>
                <td>
                  {formatCurrency(
                    results[1].projectedInClinicRevenue + results[2].projectedInClinicRevenue
                  )}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Health Plan</td>
                <td>{formatCurrency(results[1].projectedHealthPlanRevenue)}</td>
                <td>{formatCurrency(results[2].projectedHealthPlanRevenue)}</td>
                <td>
                  {formatCurrency(
                    results[1].projectedHealthPlanRevenue + results[2].projectedHealthPlanRevenue
                  )}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Payment Plan</td>
                <td>{formatCurrency(results[1].projectedPaymentPlanRevenue)}</td>
                <td>{formatCurrency(results[2].projectedPaymentPlanRevenue)}</td>
                <td>
                  {formatCurrency(
                    results[1].projectedPaymentPlanRevenue + results[2].projectedPaymentPlanRevenue
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
}

export default RevenueStats;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import moment from 'moment';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import InputEitherOr from '../common/inputEitherOr';
import InputSelect from '../common/inputSelect';
import PaymentMethodWidget from '../payment-method/paymentMethodWidget';
import SchedulePayment from './schedulePayment';

import { formatDate, formatCurrency } from '../../util';
import Constants from '../../constants';

function UpdateRenewal({ contract, handleClose }) {
  const { renewalInfo } = contract;

  const [isRenewing, setIsRenewing] = useState(renewalInfo.isRenewing);
  const [percentageOff, setPercentageOff] = useState(
    isRenewing ? Number(renewalInfo.percentage_off_id) : Constants.FIFTY_PERCENT
  );
  const [paymentInterval, setPaymentInterval] = useState(
    isRenewing ? Number(renewalInfo.payment_interval_id) : Constants.MONTHLY
  );
  const [paymentMethod, setPaymentMethod] = useState({ isStored: 'Y' });
  const [renewalCost, setRenewalCost] = useState(0);
  const [showSchedulePayment, setShowSchedulePayment] = useState(false);
  const [cancellationReason, setCancellationReason] = useState(Constants.REHOMED);

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const renewalDate = moment(contract.start_date).add(1, 'years').format('MM/DD/YYYY');
  const pricingVersion = Number(renewalInfo.pricing_version) || Constants.V3;

  useEffect(() => {
    const getRenewalCost = async () => {
      const response = await http.get(
        `/healthPlan/getRenewalCost?species_id=${contract.species_id}&percentage_off_id=${percentageOff}&payment_interval_id=${paymentInterval}&pricing_version=${pricingVersion}`
      );
      setRenewalCost(Number(response.data.renewalCost));
    };
    getRenewalCost();
  }, [isRenewing, contract.species_id, percentageOff, paymentInterval, pricingVersion]);

  const handleIsRenewingChange = value => {
    setIsRenewing(JSON.parse(value));
    if (paymentInterval === Constants.ANNUAL) {
      setPaymentMethod({ isStored: 'Y' });
    }
  };

  const handlePaymentIntervalChange = value => {
    const paymentInterval = Number(value);
    setPaymentInterval(paymentInterval);
    setPaymentMethod({ isStored: 'Y' });
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      contractId: contract.id,
      percentageOffId: percentageOff,
      paymentIntervalId: paymentInterval,
      cancellationReason,
      isRenewing: isRenewing ? 'Y' : 'N',
      isMonthly: contract.isMonthly ? 'Y' : 'N',
    };

    if (!contract.isMonthly && Number(params.paymentIntervalId) === Constants.MONTHLY) {
      params.paymentMethodId = paymentMethod.id;
    }

    await http.post('/healthPlan/updateRenewalInfo', params);
    if (
      isRenewing &&
      !contract.isMonthly &&
      Number(params.paymentIntervalId) === Constants.ANNUAL
    ) {
      setShowSchedulePayment(true);
    } else {
      navigate(0);
    }
  };

  if (showSchedulePayment) {
    return (
      <SchedulePayment
        contract={contract}
        annualParticipationCharge={renewalCost}
        handleClose={() => navigate(0)}
      />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h2 className="mb-3">Renewal Information</h2>
        <InputEitherOr
          name="isRenewing"
          label={`Renew ${contract.name}'s Health Plan?`}
          value={isRenewing}
          option1={{ name: 'Renew', value: true }}
          option2={{ name: "Don't Renew", value: false }}
          onChange={({ target }) => handleIsRenewingChange(target.value)}
        />
        {isRenewing && (
          <React.Fragment>
            <InputEitherOr
              name="percentageOff"
              label="Type of Health Plan"
              value={percentageOff}
              option1={{ name: '50%', value: Constants.FIFTY_PERCENT }}
              option2={{ name: '90%', value: Constants.NINETY_PERCENT }}
              onChange={({ target }) => setPercentageOff(Number(target.value))}
            />
            {!contract.isMonthly && (
              <React.Fragment>
                <InputEitherOr
                  name="paymentInterval"
                  label="Payment Option"
                  value={paymentInterval}
                  option1={{ name: 'Monthly', value: Constants.MONTHLY }}
                  option2={{ name: 'Annual', value: Constants.ANNUAL }}
                  onChange={({ target }) => handlePaymentIntervalChange(target.value)}
                />
                {paymentInterval === Constants.MONTHLY && (
                  <PaymentMethodWidget
                    clientId={contract.client_id}
                    labelText="Select payment method for Monthly Payments:"
                    selectedPaymentMethod={paymentMethod}
                    displayStoredOnly={true}
                    handleErrorMessage={setErrorMessage}
                    handlePaymentMethodChange={setPaymentMethod}
                  />
                )}
              </React.Fragment>
            )}
            <div className="p-2 w-100 border rounded my-3">
              <p className="font-14 sura">
                Health Plan will automatically renew on{' '}
                <span className="fw-bold">{renewalDate}</span>
              </p>
              {paymentInterval === Constants.MONTHLY && (
                <p className="font-14 sura">
                  Monthly Participation Charge:{' '}
                  <span className="fw-bold">{formatCurrency(renewalCost / 12)}</span>
                </p>
              )}
              {paymentInterval === Constants.ANNUAL && (
                <p className="font-14 sura">
                  Annual Participation Charge:{' '}
                  <span className="fw-bold">{formatCurrency(renewalCost)}</span>
                </p>
              )}
            </div>
          </React.Fragment>
        )}
        {!isRenewing && (
          <React.Fragment>
            <InputSelect
              name="cancellationReason"
              value={cancellationReason}
              label="Reason for Not Renewing"
              optionConfig={Constants.CANCELLATION_REASON_MAP.filter(
                t => t.value >= Constants.REHOMED
              )}
              onChange={setCancellationReason}
            />
            <div className="border rounded p-4 mt-2">
              <p className="sura font-14">
                By declining renewal of {contract.name}'s Health Plan, the current plan will expire
                on <nobr>{formatDate(contract.end_date)}</nobr> and {contract.name}{' '}
                <strong>
                  will not be eligible to sign up for another Health Plan until{' '}
                  <nobr>
                    {moment(contract.start_date, 'YYYY-MM-DD').add(2, 'years').format('MM/DD/YYYY')}
                  </nobr>
                  .
                </strong>
              </p>
              <p className="sura font-14 mt-3">
                <strong>
                  If you wish to avoid this waiting period, you may opt to renew {contract.name}'s
                  current Health Plan
                </strong>
              </p>
            </div>
          </React.Fragment>
        )}
        <ButtonPanel
          primaryButtonText="Confirm Changes"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default UpdateRenewal;

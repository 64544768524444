import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import http from '../../services/httpService';

import Header from './header';

import ContractPreview from '../contract/contractPreview';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';

import '../../styles/components/health-plan-contract.scss';

function PaymentPlanContract() {
  const params = useParams();
  const formId = params.formId;

  const [form, setForm] = useState(null);

  const [authorizingParty, setAuthorizingParty] = useState('');
  const [contract, setContract] = useState({
    initial_setup_info:
      '{"paymentDates":{"remainingPaymentDates":[]},"initialPaymentInfo":{"items":[]},"monthlyPaymentInfo":{"items":[]}}',
  });

  const [authStatement1, setAuthStatement1] = useState(false);
  const [authStatement2, setAuthStatement2] = useState(false);

  const sigCanvas = useRef();
  const [isSigned, setIsSigned] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getForm = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');

        const formResponse = await http.get(`/forms/getById?form_id=${formId}`);
        const form = formResponse.data;
        setForm(form);

        const { contractId } = JSON.parse(form.params);
        const contractInfoResponse = await http.get(
          `/forms/getContractInfo?contract_id=${contractId}`
        );
        setContract(contractInfoResponse.data);

        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      }
    };

    if (formId) {
      getForm();
    }
  }, [formId]);

  const handleSign = () => {
    const canvas = sigCanvas.current.getCanvas();
    canvas.toBlob(async blob => {
      const formData = new FormData();

      formData.append('file', blob);
      formData.append('formId', form.id);
      formData.append('authorizingParty', authorizingParty);

      try {
        setErrorMessage('');
        await http.post('/forms/signPaymentPlanContract', formData, {
          'Content-type': 'multipart/form-data',
        });

        navigate('/forms');
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    });
  };

  const setupInfo = JSON.parse(contract.initial_setup_info);

  return (
    <div className="py-5 background-gray d-flex flex-column flex-grow-1">
      <div className="container">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h2 className="mb-4 sura fw-bolder">Payment Plan Agreement</h2>
        {isLoading && (
          <i className="flex-centered w-100 p-4 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}
        {form && (
          <React.Fragment>
            <p className="my-2 sura">
              This is a payment plan to cover the cost of the services performed today, as detailed
              in this agreement. This is not a subscription or payment plan for the future receipt
              of services and/or products.
            </p>

            <Header
              form={form}
              handleIsLoading={setIsLoading}
              handleOwnerChange={setAuthorizingParty}
            />

            <div className="mt-5 agreement-section">
              <h3>Payment Details</h3>
              <ContractPreview previewInfo={setupInfo} isHealthPlan={false} />
            </div>

            <InputCheckbox
              className="mt-5"
              name="authStatement1"
              label="I understand that I am responsible for complete payment of this plan, even if my pet is re-homed or passes away before the plan has been paid in full. This payment plan is not transferable to any other party."
              checked={authStatement1}
              onChange={setAuthStatement1}
            />

            <InputCheckbox
              className="mt-4"
              name="authStatement2"
              label="I authorize Twin Peaks Veterinary Clinic to charge my account for the scheduled payments as per the agreed-upon billing schedule until the total payment amount is fulfilled. I acknowledge that this document is legally binding and that I have read, understood, and agree to the terms and responsibilities outlined."
              checked={authStatement2}
              onChange={setAuthStatement2}
            />

            <Input
              className="mt-5"
              name="authorizingParty"
              value={authorizingParty}
              label="Authorizing Party"
              onChange={setAuthorizingParty}
            />

            <div className="d-flex mt-5">
              <div>
                <label className="mb-2 font-16">Signature</label>
                <div
                  className="background-white"
                  style={{ width: '800px', height: '200px', border: '1px solid black' }}
                >
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{ className: 'w-100 h-100' }}
                    onEnd={() => setIsSigned(true)}
                  />
                </div>
              </div>
              <button
                className="ms-4 align-self-end"
                onClick={() => {
                  sigCanvas.current.clear();
                  setIsSigned(false);
                }}
              >
                <Icon name="fa fa-times-circle" />
                Clear
              </button>
            </div>
            <ButtonPanel
              primaryButtonText="Submit"
              handleCancel={() => navigate(-1)}
              handleSubmit={handleSign}
              handleErrorMessage={setErrorMessage}
              disabled={!authorizingParty || !isSigned || !authStatement1 || !authStatement2}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default PaymentPlanContract;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import http from '../../services/httpService';

import Header from './header';

import ContractPreview from '../contract/contractPreview';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';

import Constants from '../../constants';

import '../../styles/components/health-plan-contract.scss';

function HealthPlanContract() {
  const params = useParams();
  const formId = params.formId;

  const [form, setForm] = useState(null);
  const [currentStep, setCurrentStep] = useState('clientPetInfo');

  const [authorizingParty, setAuthorizingParty] = useState('');
  const [contract, setContract] = useState({
    initial_setup_info: '{"paymentDates":{"remainingPaymentDates":[]},"monthlyPaymentInfo":{}}',
  });

  const [authStatement1, setAuthStatement1] = useState(false);
  const [authStatement2, setAuthStatement2] = useState(false);
  const [authStatement3, setAuthStatement3] = useState(false);
  const [authStatement4, setAuthStatement4] = useState(false);
  const [authStatement5, setAuthStatement5] = useState(false);

  const sigCanvas = useRef();
  const [isSigned, setIsSigned] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getForm = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');

        const formResponse = await http.get(`/forms/getById?form_id=${formId}`);
        const form = formResponse.data;
        setForm(form);

        const { contractId } = JSON.parse(form.params);
        const contractInfoResponse = await http.get(
          `/forms/getContractInfo?contract_id=${contractId}`
        );
        setContract(contractInfoResponse.data);

        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      }
    };

    if (formId) {
      getForm();
    }
  }, [formId]);

  const handleSign = () => {
    const canvas = sigCanvas.current.getCanvas();
    canvas.toBlob(async blob => {
      const formData = new FormData();

      formData.append('file', blob);
      formData.append('formId', form.id);
      formData.append('authorizingParty', authorizingParty);

      try {
        setErrorMessage('');
        await http.post('/forms/signHealthPlanContract', formData, {
          'Content-type': 'multipart/form-data',
        });

        navigate('/forms');
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    });
  };

  const setupInfo = JSON.parse(contract.initial_setup_info);
  const isFifty = Number(contract.percentage_off_id) === Constants.FIFTY_PERCENT;

  const clientPetInfo = (
    <React.Fragment>
      <p className="my-2 sura">
        This contract outlines the agreement between Twin Peaks Veterinary Clinic and the signing
        owner for enrolling one specific pet on a Health Plan for one year.
      </p>

      <Header form={form} handleIsLoading={setIsLoading} handleOwnerChange={setAuthorizingParty} />

      <ButtonPanel
        primaryButtonText="Next"
        handleSubmit={() => setCurrentStep('paymentTerms')}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const paymentTerms = (
    <React.Fragment>
      <div className="agreement-section">
        <h3>Payment Terms</h3>
        <p>
          The Annual Participation Charge can be paid in one lump sum annually or 12 monthly
          payments on a specific Billing Day. Twin Peaks reserves the right to require a lump sum
          payment for any reason.
        </p>
        <p>
          If monthly payment is selected, a $60 Down Payment is required for pets that have not been
          on a Health Plan for 6 months or more. The Down Payment will be deducted from the Annual
          Participation Charge, reducing the monthly payment by $5 for the first year. There is no
          Down Payment required at renewal. The Down Payment and first monthly payment are due on
          the Date of Enrollment. Subsequent monthly payments will be automatically charged on the
          Billing Day each month. If the Billing Day is not present in a month, the payment will be
          charged on the last day of the month. The payment method for recurring payments can be
          changed as needed.
        </p>
      </div>

      <div className="agreement-section">
        <h3>Payment Details</h3>
        <ContractPreview previewInfo={setupInfo} isHealthPlan={true} />
      </div>

      <ButtonPanel
        primaryButtonText="Next"
        handleBack={() => setCurrentStep('clientPetInfo')}
        handleSubmit={() => setCurrentStep('benefits')}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const benefits = (
    <React.Fragment>
      <p className="mb-3 sura font-16">The following benefits are provided by this Health Plan:</p>
      <div className="agreement-section">
        <h3>Office Visit Charge: discounted to $20 per visit</h3>
      </div>

      <div className="agreement-section">
        <h3>
          Included Services: The following services, performed as needed during an Office Visit:
        </h3>
        <ul>
          <li>Routine preventive care exams</li>
          <li>All recommended core vaccines and boosters for the area</li>
          <li>Nail trims</li>
          <li>Anal gland expressions</li>
          <li>Fecal floats</li>
          <li>Routine deworming for roundworm and hookworm</li>
          <li>Heartworm tests (dogs) and FELV/FIV tests (cats)</li>
          <li>Discount on Spay/Neuter surgery</li>
          <li>Discount on Heartworm prevention medication (dogs)</li>
          <li>Euthanasia</li>
        </ul>
      </div>

      <div className="agreement-section">
        <h3>
          Discounted Services: A {contract.percentage_off} discount on services provided at Twin
          Peaks by our doctors:
        </h3>
        <p>
          <span className="bold">Examples</span> of these services include:
        </p>
        <ul>
          <li>Microchip</li>
          <li>X-rays</li>
          <li>Surgery</li>
          <li>In-house bloodwork</li>
          <li>In-house diagnostic testing</li>
          <li>In-house hospitalization</li>
          <li>Sick patient care</li>
          <li>IV fluid therapy</li>
          <li>Medical observation</li>
          <li>Sedation or chemical restraint</li>
          <li>Laceration repair and other local anesthetic procedures</li>
        </ul>
        {!isFifty && (
          <p className="mt-2">
            *Note that elective or prophylactic surgeries, including gastropexy, are discounted at
            50%
          </p>
        )}
      </div>

      <ButtonPanel
        primaryButtonText="Next"
        handleBack={() => setCurrentStep('paymentTerms')}
        handleSubmit={() => setCurrentStep('exclusions')}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const exclusions = (
    <React.Fragment>
      <div className="agreement-section">
        <h3>Excluded Services: NOT included or discounted:</h3>
        <ul>
          <li>Products and medications</li>
          <ul className="mb-2">
            <li>Pharmacy</li>
            <li>E-collar</li>
            <li>Pet food</li>
          </ul>
          <li>Dental services</li>
          <ul className="mb-2">
            <li>Dental cleaning</li>
            <li>Tooth extraction</li>
            <li>Oral surgery</li>
            <li>Dental X-ray</li>
          </ul>
          <li>Any service arranged by Twin Peaks with another company</li>
          <ul className="mb-2">
            <li>Off-site laboratory services (including pathology, cytology, histopathology)</li>
            <li>Surgeries or diagnostics performed onsite by a specialist</li>
            <li>Cremation</li>
          </ul>
          <li>Services performed at other clinics, including emergency care</li>
        </ul>
      </div>
      <p className="mt-3 sura font-16">*The examples above are not all-inclusive.</p>
      <ButtonPanel
        primaryButtonText="Next"
        handleBack={() => setCurrentStep('benefits')}
        handleSubmit={() => setCurrentStep('renewal')}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const renewal = (
    <React.Fragment>
      <div className="agreement-section">
        <h3>Renewal Process</h3>
        <p>
          The Health Plan will automatically renew for a full year each year on the anniversary date
          of the original enrollment. The terms and conditions (including pricing) of the Health
          Plan may be modified by Twin Peaks at renewal. The new contract will be available at least
          30 days before the Renewal Date.
        </p>
        <p>Health Plans can be set to not renew upon request prior to the Renewal Date.</p>
        <p>Twin Peaks reserves the right to decline Health Plan renewals at its discretion.</p>
      </div>

      <div className="agreement-section">
        <h3>Renewal Payments</h3>
        <p>
          Payments for Monthly Health Plans will continue to access funds at renewal as previously
          established. Lower monthly payments (due to a Down Payment during the first year) will
          increase to the full monthly payment of the new Health Plan at renewal.
        </p>
        <p>Payment for Annual Health Plans will be due on the Renewal Date.</p>
        <p>Payments can be switched to annual or monthly payments at renewal upon request.</p>
      </div>

      <div className="agreement-section">
        <h3>Changing Plans at Renewal</h3>
        <p>Pets that are already on 90% plans are eligible to renew to any plan.</p>
        <p>
          Pets that are on 50% plans may only upgrade to 90% plans at renewal if they meet
          Pre-existing Condition requirements as determined by clinic policy.
        </p>
        <p>Requests to change plans should be made within 30 days of renewal.</p>
      </div>
      <ButtonPanel
        primaryButtonText="Next"
        handleBack={() => setCurrentStep('exclusions')}
        handleSubmit={() => setCurrentStep('otherTerms')}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const otherTerms = (
    <React.Fragment>
      <div className="agreement-section">
        <h3>Not Transferable</h3>
        <p>
          Health Plans may not be transferred to any other pet or person. If pets change owners, the
          owner who enrolled on the Health Plan will remain responsible for payment of the Annual
          Participation Charge.
        </p>
      </div>

      <div className="agreement-section">
        <h3>Cancellation or Death</h3>
        <p>
          In the event of death, euthanasia, or early cancellation, the remaining Health Plan
          balance is still due. If the Annual Participation Charge was is paid monthly, the
          remaining balance can either continue to be paid monthly or can be paid in one lump sum.
        </p>
      </div>

      <div className="agreement-section">
        <h3>Waiting Period</h3>
        <p>
          Once a Health Plan is cancelled or not renewed, there is a mandatory one-year Waiting
          Period before that owner/household may sign up that pet on a Health Plan again. During the
          Waiting Period, retail prices will be charged for services for that pet.
        </p>
      </div>

      <div className="agreement-section">
        <h3>Outstanding Account Balance</h3>
        <p>
          Health Plan services cannot be used if there is an outstanding account balance at Twin
          Peaks for any pet in the household. This includes missed Health Plan payments.
        </p>
      </div>

      <div className="agreement-section">
        <h3>Health Plan Delinquency</h3>
        <p>
          If Health Plan payments are missed, the Health Plan will be cancelled after 90 days of
          non-payment. The pet will enter a Waiting Period, and the remaining Health Plan balance
          will be due.
        </p>
      </div>
      <ButtonPanel
        primaryButtonText="Next"
        handleBack={() => setCurrentStep('renewal')}
        handleSubmit={() => setCurrentStep('sign')}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const sign = (
    <React.Fragment>
      <InputCheckbox
        className="mt-5"
        name="authStatement1"
        label="I understand that I am enrolling my pet in a 1-year Health Plan and agree to the terms and conditions of this contract"
        checked={authStatement1}
        onChange={setAuthStatement1}
      />

      {!isFifty && (
        <InputCheckbox
          className="mt-3"
          name="authStatement2"
          label="I understand that Elective procedures are covered at 50% on this Health Plan"
          checked={authStatement2}
          onChange={setAuthStatement2}
        />
      )}

      <InputCheckbox
        className="mt-3"
        name="authStatement3"
        label="I understand that dental services are not covered or discounted on this Health Plan"
        checked={authStatement3}
        onChange={setAuthStatement3}
      />

      <InputCheckbox
        className="mt-3"
        name="authStatement4"
        label="I understand that this Health Plan will automatically renew every year unless otherwise requested, and the contract will renew for a full year. I acknowledge that the payment amount may change at renewal, based on the contract terms at that time."
        checked={authStatement4}
        onChange={setAuthStatement4}
      />

      <InputCheckbox
        className="mt-3"
        name="authStatement5"
        label="I authorize Twin Peaks to charge my account according to the terms of this contract"
        checked={authStatement5}
        onChange={setAuthStatement5}
      />

      <Input
        className="mt-5"
        name="authorizingParty"
        value={authorizingParty}
        label="Authorizing Party"
        onChange={setAuthorizingParty}
      />

      <div className="d-flex mt-5">
        <div>
          <label className="mb-2 font-16">Signature</label>
          <div
            className="background-white"
            style={{ width: '800px', height: '200px', border: '1px solid black' }}
          >
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{ className: 'w-100 h-100' }}
              onEnd={() => setIsSigned(true)}
            />
          </div>
        </div>
        <button
          className="ms-4 align-self-end"
          onClick={() => {
            sigCanvas.current.clear();
            setIsSigned(false);
          }}
        >
          <Icon name="fa fa-times-circle" />
          Clear
        </button>
      </div>
      <ButtonPanel
        primaryButtonText="Submit"
        handleCancel={() => navigate(-1)}
        handleBack={() => setCurrentStep('otherTerms')}
        handleSubmit={handleSign}
        handleErrorMessage={setErrorMessage}
        disabled={
          !authorizingParty ||
          !isSigned ||
          !authStatement1 ||
          (!isFifty && !authStatement2) ||
          !authStatement3 ||
          !authStatement4 ||
          !authStatement5
        }
      />
    </React.Fragment>
  );

  return (
    <div className="py-5 background-gray d-flex flex-column flex-grow-1">
      <div className="container">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h2 className="mb-4 sura fw-bolder">
          Health Plan Agreement - {setupInfo.contractName} Health Plan
        </h2>
        {isLoading && (
          <i className="flex-centered w-100 p-4 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}

        {form && currentStep === 'clientPetInfo' && clientPetInfo}
        {currentStep === 'paymentTerms' && paymentTerms}
        {currentStep === 'benefits' && benefits}
        {currentStep === 'exclusions' && exclusions}
        {currentStep === 'renewal' && renewal}
        {currentStep === 'otherTerms' && otherTerms}
        {currentStep === 'sign' && sign}
      </div>
    </div>
  );
}

export default HealthPlanContract;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import '../../styles/global/react-popper-tooltip.css';
import { usePopperTooltip } from 'react-popper-tooltip';

import http from '../../services/httpService';

import AnnualToMonthly from './annualToMonthly';
import EarlyRenewal from './earlyRenewal';
import SettlementAnalysis from './settlementAnalysis';

import ButtonPanel from '../common/buttonPanel';
import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';

import Constants from '../../constants';
import { formatCurrency } from '../../util';

function AdminContractOptions({ contract }) {
  const [showModal, setShowModal] = useState(null);
  const [visible, setVisible] = useState(false);

  const userIsManager =
    Number(JSON.parse(localStorage.getItem('loggedIn')).role_id) === Constants.MANAGER;
  const endPointName = contract.isHealthPlan ? 'healthPlan' : 'paymentPlan';

  const navigate = useNavigate();

  const handleCancelContract = async () => {
    const params = { contractId: contract.id };
    if (contract.isHealthPlan && contract.isEmployeePlan) {
      params.cancellationReason = Constants.EMPLOYEE_PLAN;
    }

    await http.post(`/${endPointName}/cancel`, params);
    navigate(0);
  };

  const handleDeleteContract = async () => {
    await http.delete(`/${endPointName}/delete?contract_id=${contract.id}`);
    navigate(0);
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger: 'click',
    onVisibleChange: () => setVisible(!visible),
  });

  const toggleModal = modalToDisplay => {
    setVisible(false);
    setShowModal(modalToDisplay);
  };

  const dropdownOptions = [];
  const activeContract =
    contract.contract_phase === Constants.OPEN &&
    contract.billing_active === 'Y' &&
    !contract.early_cancellation_date &&
    !contract.isEmployeePlan;

  if (activeContract) {
    if (
      contract.isHealthPlan &&
      contract.deceased !== 'Y' &&
      contract.isCurrent &&
      !contract.hasRemainingBalance
    ) {
      dropdownOptions.push({
        text: 'Early Renewal',
        handleClick: () => toggleModal('early_renewal'),
      });
    }

    if (!contract.isMonthly && (!contract.isCurrent || contract.hasRemainingBalance)) {
      dropdownOptions.push({
        text: 'Convert to Monthly Payments',
        handleClick: () => toggleModal('annual_to_monthly'),
      });
    }

    //don't allow settlement analysis for payment plans paid in full
    if (!(!contract.isHealthPlan && contract.isCurrent && !contract.hasRemainingBalance)) {
      dropdownOptions.push({
        text: 'Run Settlement Analysis',
        handleClick: () => toggleModal('settlement_analysis'),
      });
    }
  }

  if (userIsManager) {
    if (!contract.isEmployeePlan) {
      dropdownOptions.push({
        text: 'Apply Clinic Credit',
        handleClick: () => toggleModal('apply_clinic_credit'),
      });
    }

    if (activeContract || contract.isEmployeePlan) {
      dropdownOptions.push({
        text: 'Cancel Plan',
        handleClick: () => toggleModal('cancel'),
      });
    }
  }

  if (Number(contract.contract_status_id) === Constants.INITIATED) {
    dropdownOptions.push({
      text: 'Send Activation Email',
      handleClick: async () => {
        await http.post(`/${endPointName}/sendActivationEmail?contract_id=${contract.id}`);
      },
    });
  }

  if (Number(contract.contract_status_id) === Constants.ACTIVATED && contract.isMonthly) {
    dropdownOptions.push({
      text: 'Retry Activation',
      handleClick: async () => {
        await http.post(`/${endPointName}/retryActivation?contract_id=${contract.id}`);
        navigate(0);
      },
    });
  }

  if (contract.contract_phase === Constants.PENDING) {
    dropdownOptions.push({
      text: 'Generate Contract',
      handleClick: async () => {
        await http.post(`/${endPointName}/generateContract?contract_id=${contract.id}`);
        navigate(0);
      },
    });
  }

  if (
    process.env.REACT_APP_ENABLE_UNSAFE_DELETE === 'Y' ||
    contract.contract_phase === Constants.PENDING ||
    contract.transactions.filter(t => t.type === Constants.PAYMENT && t.category).length === 0
  ) {
    dropdownOptions.push({
      text: 'Delete Plan',
      handleClick: () => toggleModal('delete'),
    });
  }

  if (dropdownOptions.length === 0) {
    return null;
  }

  return (
    <div ref={setTriggerRef}>
      <Icon name="options_dots" className="me-0" tooltipText="Admin Options" />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container options' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="d-flex flex-column">
            {dropdownOptions.map((t, i) => (
              <button key={i} type="button" onClick={t.handleClick}>
                {t.text}
              </button>
            ))}
          </div>
        </div>
      )}
      {showModal === 'early_renewal' && (
        <EarlyRenewal contract={contract} handleClose={toggleModal} />
      )}
      {showModal === 'annual_to_monthly' && (
        <AnnualToMonthly contract={contract} handleClose={toggleModal} />
      )}
      {showModal === 'apply_clinic_credit' && (
        <ApplyClinicCredit contractId={contract.id} handleClose={toggleModal} />
      )}
      {showModal === 'cancel' && (
        <Confirmation
          message={`If you proceed, this plan will be terminated immediately. The
          ${
            Number(contract.amount_due) > 0
              ? ` outstanding balance of ${formatCurrency(contract.amount_due)} as well as the`
              : ''
          }
          remaining balance of ${formatCurrency(contract.remaining_balance)}
           will be written off, and the client will no longer be required to make payment of any kind toward this. Are you sure you would like to proceed with cancellation?`}
          handleConfirm={handleCancelContract}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'delete' && (
        <Confirmation
          message={`Are you sure you would like to delete this Plan? This action cannot be undone.`}
          handleConfirm={handleDeleteContract}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'settlement_analysis' && (
        <SettlementAnalysis contract={contract} handleClose={toggleModal} />
      )}
    </div>
  );
}

function ApplyClinicCredit({ contractId, handleClose }) {
  const [amount, setAmount] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    await http.post(`/billing/applyClinicCredit`, {
      contractId,
      amount: parseInt(Math.fround(amount * 100)),
    });
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <Input
          type="number"
          name="amount"
          value={amount}
          label="Credit Amount"
          onChange={setAmount}
        />
        <ButtonPanel
          primaryButtonText="Submit"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!amount}
        />
      </form>
    </Modal>
  );
}

export default AdminContractOptions;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputTextarea from '../common/inputTextarea';
import Page from '../common/page';

function ReviewDashboard() {
  const locationId = localStorage.getItem('locationId');

  const [requests, setRequests] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showCompletedConfirmationModal, setShowCompletedConfirmationModal] = useState(false);
  const [showDeclineConfirmationModal, setShowDeclineConfirmationModal] = useState(false);
  const [showTextReviewRequestModal, setShowTextReviewRequestModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getRequests = async () => {
      try {
        const response = await http.get(`/report/getReviewRequests?location_id=${locationId}`);
        setRequests(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    getRequests();
  }, [locationId]);

  const handleReviewRequestCompleted = async () => {
    await http.post(`/client/completeReviewRequest?client_id=${selectedRequest.id}`);
    navigate(0);
  };

  const handleDeclineToRequestReview = async () => {
    await http.post(`/client/declineToRequestReview?client_id=${selectedRequest.id}`);
    navigate(0);
  };

  return (
    <Page selectedTab="admin">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h1 className="mb-4">Potential Review Requests</h1>
        <h2 className="mb-3">Count: {requests.length}</h2>
        <div className="p-2">
          <table>
            <thead>
              <tr className="border-bottom">
                <th>Client</th>
                <th>Most Recent Appointment</th>
                <th>Client Since</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((t, i) => {
                return (
                  <tr key={i}>
                    <td
                      className="pointer hover-underline"
                      onClick={() => navigate(`/client/${t.id}`)}
                    >
                      {t.first_name} {t.last_name}
                    </td>
                    <td>{moment.tz(t.most_recent_appt, 'America/Denver').format('MM-DD-YYYY')}</td>
                    <td>{moment.tz(t.insert_ts, 'America/Denver').format('MM-DD-YYYY')}</td>
                    <td>
                      <div className="d-flex">
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedRequest(t);
                            setShowTextReviewRequestModal(true);
                          }}
                        >
                          <Icon
                            name="fa fa-comment blue"
                            className="m-0"
                            tooltipText="Text request for review"
                          />
                        </button>
                        <button
                          className="ms-3"
                          type="button"
                          onClick={() => {
                            setSelectedRequest(t);
                            setShowCompletedConfirmationModal(true);
                          }}
                        >
                          <Icon
                            name="fa fa-check-circle attention"
                            className="m-0"
                            tooltipText="Review requested"
                          />
                        </button>
                        <button
                          className="ms-3"
                          type="button"
                          onClick={() => {
                            setSelectedRequest(t);
                            setShowDeclineConfirmationModal(true);
                          }}
                        >
                          <Icon
                            name="fa fa-times-circle error"
                            className="m-0"
                            tooltipText="Do not request review"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showTextReviewRequestModal && (
        <TestReviewRequstModal
          client={selectedRequest}
          locationId={locationId}
          handleClose={() => setShowTextReviewRequestModal(false)}
        />
      )}
      {showCompletedConfirmationModal && (
        <Confirmation
          message={`Are you sure you have asked ${selectedRequest.first_name} ${selectedRequest.last_name} for a review?`}
          handleConfirm={handleReviewRequestCompleted}
          handleClose={() => setShowCompletedConfirmationModal(false)}
        />
      )}
      {showDeclineConfirmationModal && (
        <Confirmation
          message={`Are you sure you don't want to ask ${selectedRequest.first_name} ${selectedRequest.last_name} for a review?`}
          handleConfirm={handleDeclineToRequestReview}
          handleClose={() => setShowDeclineConfirmationModal(false)}
        />
      )}
    </Page>
  );
}

function TestReviewRequstModal({ client, locationId, handleClose }) {
  const [phone, setPhone] = useState(client.mobile || '');
  const [message, setMessage] = useState(
    `Hi ${client.first_name}! This is Julia from Twin Peaks Veterinary Clinic. Google reviews make it easier for people to find us online. If you could take a minute to leave us a 5-star review, we would really appreciate it! Here's the link: https://g.page/r/CTeVBxt0e5GTEBM/review. Hope you have a great day :)`
  );

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      /*await http.post('/client/textReviewRequest', {
        clientId: client.id,
        phone,
        message,
        locationId,
      });*/
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal className="email-estimate" show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        <Input name="phone" value={phone} label="Client Phone" onChange={setPhone} />
        <InputTextarea
          className="w-100"
          name="message"
          value={message}
          label="Message"
          rows="8"
          onChange={setMessage}
        />
        <ButtonPanel
          primaryButtonText="Send"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={true /*!phone || !message*/}
        />
      </form>
    </Modal>
  );
}

export default ReviewDashboard;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';

import EstimateConstants from '../../estimateConstants';

import '../../styles/components/email-estimate.scss';

function EmailEstimateModal({ estimateId, appt, handleClose }) {
  const [templateType, setTemplateType] = useState(1);
  const [estimateType, setEstimateType] = useState('');
  const [petName, setPetName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getEstimate = async () => {
      try {
        const response = await http.get(`/estimate/getById?estimate_id=${estimateId}`);
        const estimate = response.data;
        setPetName(estimate.name);
        setEmail(estimate.email);

        const estimateType = JSON.parse(estimate.info).type;
        setEstimateType(estimateType);

        if (appt) {
          setTemplateType(estimateType === EstimateConstants.HP_COMPARISON ? 2 : 4);
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    getEstimate();
  }, [estimateId, appt]);

  useEffect(() => {
    const handleUpdateTemplateType = () => {
      const comparisonIntro = `Attached is an estimate for ${petName}'s procedure.`;
      const txPlanIntro = `Attached is the treatment plan for ${petName}'s`;
      const scheduledInfo = `\n\n${petName} is scheduled for check-in ${
        appt ? moment(appt.start).format('hh:mm A') : 'SURGERY_CHECK_IN_TIME'
      } on ${
        appt ? moment(appt.start).format('MM/DD/YYYY') : 'SURGERY_DATE'
      }. Please withhold food starting at 10 pm the night before the procedure, but your pet may have access to water. If ${petName} takes daily medications, please contact the clinic for instructions.`;
      const notScheduledInfo =
        '\n\nThis procedure is not yet scheduled, please contact us when you are ready to do so.';
      const conclusion =
        '\n\nIf you have any questions or concerns please call/text us at 970-663-0218 or reply to this email.';

      let updatedMessage, updatedSubject;
      switch (Number(templateType)) {
        case 2:
          updatedMessage = `${comparisonIntro}${scheduledInfo}${conclusion}`;
          updatedSubject = 'Estimate';
          break;
        case 3:
          updatedMessage = `${comparisonIntro}${notScheduledInfo}${conclusion}`;
          updatedSubject = 'Estimate';
          break;
        case 4:
          updatedMessage = `${txPlanIntro} upcoming procedure.${scheduledInfo}${conclusion}`;
          updatedSubject = 'Treatment Plan';
          break;
        case 5:
          updatedMessage = `${txPlanIntro} recommended procedure.${notScheduledInfo}${conclusion}`;
          updatedSubject = 'Treatment Plan';
          break;
        default:
          updatedMessage = '';
          updatedSubject = '';
      }
      setMessage(updatedMessage);
      setSubject(updatedSubject);
    };

    handleUpdateTemplateType(templateType);
  }, [templateType, petName, appt]);

  const handleSubmit = async () => {
    try {
      await http.post('/estimate/emailToClient', {
        estimateId,
        email,
        subject,
        message: `<pre style="overflow-x: auto;white-space: pre-wrap;word-wrap: break-word;font-family:Arial;">${message}</pre>`,
      });
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal className="email-estimate" show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        {!appt && (
          <InputSelect
            name="templateType"
            value={templateType}
            label="Email Template Type"
            optionConfig={
              Number(estimateType) === EstimateConstants.HP_COMPARISON
                ? [
                    { value: 1, name: 'Select Template Type' },
                    { value: 2, name: 'HP Comparison (Scheduled)' },
                    { value: 3, name: 'HP Comparison (Not Scheduled)' },
                  ]
                : [
                    { value: 1, name: 'Select Template Type' },
                    { value: 4, name: 'Tx Plan (Scheduled)' },
                    { value: 5, name: 'Tx Plan (Not Scheduled)' },
                  ]
            }
            onChange={setTemplateType}
          />
        )}
        <Input name="email" type="email" value={email} label="Client Email" onChange={setEmail} />
        <Input name="subject" value={subject} label="Subject Line" onChange={setSubject} />
        <InputTextarea
          className="w-100"
          name="message"
          value={message}
          label="Message"
          rows="15"
          onChange={setMessage}
        />
        <ButtonPanel
          primaryButtonText="Send"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!email || !subject || !message}
        />
      </form>
    </Modal>
  );
}

export default EmailEstimateModal;

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

function PetReminders({ petId }) {
  const [reminders, setReminders] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getReminders = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/reminder/getPetReminders?pet_id=${petId}`);
        setReminders(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };

    if (petId) {
      getReminders();
    }
  }, [petId]);

  return (
    <div>
      {errorMessage && <p className="error flex-centered h-100 background-white">{errorMessage}</p>}
      {isLoading && (
        <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle mt-5" />
      )}
      {!isLoading && (
        <React.Fragment>
          <h3>Reminders</h3>
          {reminders.length === 0 && <p className="sura pt-2">None</p>}
          {reminders.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Given</th>
                  <th>Due</th>
                </tr>
              </thead>
              <tbody>
                {reminders.map(t => {
                  const isOverdue = moment(t.due_date).isBefore(moment());
                  const isUpcoming =
                    !isOverdue && moment(t.due_date).isBefore(moment().add(1, 'month'));
                  return (
                    <tr key={t.id}>
                      <td className="pe-5">
                        <i
                          style={{ color: isOverdue ? 'red' : isUpcoming ? 'orange' : 'green' }}
                          className="fa fa-circle pe-2"
                        />
                        {t.name}
                      </td>
                      <td className="pe-4">
                        {t.given_date ? moment(t.given_date).format('MMM DD, YYYY') : ''}
                      </td>
                      <td>{moment(t.due_date).format('MMM DD, YYYY')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default PetReminders;

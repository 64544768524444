import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import '../../styles/global/react-popper-tooltip.css';
import { usePopperTooltip } from 'react-popper-tooltip';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';

import Constants from '../../constants';
import { formatCurrency } from '../../util';

function AdminBillingOptions({ transaction }) {
  const [showModal, setShowModal] = useState(null);
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const userIsManager =
    Number(JSON.parse(localStorage.getItem('loggedIn')).role_id) === Constants.MANAGER;

  const handleDeleteCharge = async () => {
    await http.delete(`/billing/deleteCharge?charge_id=${transaction.id}`);
    navigate(0);
  };

  const handleDeletePayment = async () => {
    await http.delete(`/billing/deletePayment?payment_id=${transaction.id}`);
    navigate(0);
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger: 'click',
    onVisibleChange: () => setVisible(!visible),
  });

  const toggleModal = modalToDisplay => {
    setVisible(false);
    setShowModal(modalToDisplay);
  };

  const dropdownOptions = [];

  if (userIsManager) {
    if (transaction.type === Constants.CHARGE) {
      dropdownOptions.push({
        text: 'Edit',
        handleClick: () => toggleModal('edit_charge'),
      });
      dropdownOptions.push({
        text: 'Delete',
        handleClick: () => toggleModal('delete_charge'),
      });
    }

    if (transaction.type === Constants.PAYMENT) {
      if (transaction.stripeChargeId && !transaction.isRefund) {
        dropdownOptions.push({
          text: 'Refund',
          handleClick: () => toggleModal('refund_payment'),
        });
      }
      if (process.env.NODE_ENV !== 'production' || !transaction.stripe_charge_id) {
        dropdownOptions.push({
          text: 'Edit',
          handleClick: () => toggleModal('edit_payment'),
        });
        dropdownOptions.push({
          text: 'Delete',
          handleClick: () => toggleModal('delete_payment'),
        });
      }
    }
  }

  if (dropdownOptions.length === 0) {
    return null;
  }

  return (
    <div ref={setTriggerRef}>
      <Icon name="options_dots" className="me-0" tooltipText="Admin Options" />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container options' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="d-flex flex-column">
            {dropdownOptions.map((t, i) => (
              <button key={i} type="button" onClick={t.handleClick}>
                {t.text}
              </button>
            ))}
          </div>
        </div>
      )}
      {showModal === 'edit_charge' && (
        <EditTransaction transaction={transaction} handleClose={toggleModal} />
      )}
      {showModal === 'delete_charge' && (
        <Confirmation
          message="Are you sure you would like to delete this charge? This action cannot be undone."
          handleConfirm={handleDeleteCharge}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'edit_payment' && (
        <EditTransaction transaction={transaction} handleClose={toggleModal} />
      )}
      {showModal === 'delete_payment' && (
        <Confirmation
          message="Are you sure you would like to delete this payment? This action cannot be undone."
          handleConfirm={handleDeletePayment}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'refund_payment' && (
        <RefundPayment payment={transaction} handleClose={toggleModal} />
      )}
    </div>
  );
}

function EditTransaction({ transaction, handleClose }) {
  const [amount, setAmount] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const isPayment = transaction.type === Constants.PAYMENT;

    const params = { amount: parseInt(Math.fround(amount * 100)) };
    params[isPayment ? 'paymentId' : 'chargeId'] = transaction.id;

    await http.post(`/billing/${isPayment ? 'editPayment' : 'editCharge'}`, params);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <div className="form-control-read-only mb-3">
          <label className="mb-2" htmlFor="currentAmount">
            Current Amount:
          </label>
          <p id="currentAmount">{formatCurrency(Math.abs(transaction.amount))}</p>
        </div>
        <Input type="number" name="amount" value={amount} label="New Amount" onChange={setAmount} />
        <ButtonPanel
          primaryButtonText="Submit"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!amount}
        />
      </form>
    </Modal>
  );
}

function RefundPayment({ payment, handleClose }) {
  const [refundAmount, setRefundAmount] = useState(0);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const params = {
      paymentId: payment.id,
      refundAmount: parseInt(Math.fround(refundAmount * 100)),
    };

    await http.post('/billing/refundPayment', params);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <div className="form-control-read-only mb-3">
          <label className="mb-2" htmlFor="currentAmount">
            Payment Amount:
          </label>
          <p id="currentAmount">{formatCurrency(Math.abs(payment.amount))}</p>
        </div>
        <Input
          type="number"
          name="amount"
          value={refundAmount}
          label="Amount to Refund"
          onChange={setRefundAmount}
        />
        <ButtonPanel
          primaryButtonText="Submit"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!refundAmount}
        />
      </form>
    </Modal>
  );
}

export default AdminBillingOptions;

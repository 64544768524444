import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import InputCheckbox from '../common/inputCheckbox';
import Page from '../common/page';

function CancellationList() {
  const locationId = localStorage.getItem('locationId');

  const [cancellationList, setCancellationList] = useState([]);
  const [surgeryOnly, setSurgeryOnly] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const getCancellationList = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(
          `/appointment/getCancellationList?location_id=${locationId}`
        );
        setCancellationList(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };

    getCancellationList();
  }, [locationId]);

  return (
    <Page>
      <div className="background-gray box-shadow rounded-bottom p-4">
        <h1>Cancellation List</h1>
        <div className="w-100 mt-4">
          {errorMessage && <p className="error">{errorMessage}</p>}
          {isLoading && <i className="fa fa-circle-notch fa-spin" />}
          {!isLoading && (
            <React.Fragment>
              <InputCheckbox
                className="my-2"
                name="surgeryOnly"
                label="Surgeries Only"
                checked={surgeryOnly}
                onChange={setSurgeryOnly}
              />
              <table className="w-100">
                <thead className="border-bottom border-top">
                  <tr>
                    <th>Pet</th>
                    <th>Client</th>
                    <th>Appointment Type</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Complaint</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {cancellationList
                    .filter(t => !surgeryOnly || t.appointment_type === 'Surgery')
                    .map((t, i) => (
                      <tr key={i}>
                        <td>{t.name}</td>
                        <td style={{ width: '110px' }}>
                          {t.first_name} {t.last_name}
                        </td>
                        <td>{t.appointment_type}</td>
                        <td style={{ width: '90px' }}>{moment(t.start).format('MM-DD-YYYY')}</td>
                        <td>{moment(t.start).format('hh:mm')}</td>
                        <td>{t.complaint}</td>
                        <td>{t.notes}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  );
}

export default CancellationList;

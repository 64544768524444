import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

//import AdminBillingOptions from './adminBillingOptions';
import GenerateInvoiceModal from '../admin/generateInvoiceModal';

import { formatCurrency, formatPaymentMethod } from '../../util';

function Billing({ clientId }) {
  const [transactions, setTransactions] = useState([]);

  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getBillingActivity = async () => {
      try {
        const response = await http.get(`/billing/getBillingActivity?client_id=${clientId}`);
        setTransactions(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    if (clientId) {
      getBillingActivity();
    }
  }, [clientId]);

  /*if (transactions.length === 0) {
    return (
      <div className="billing-activity border-top">
        <div className="header flex-row-aligned py-3" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded && <Icon name="less" />}
          {!isExpanded && <Icon name="more" />}
          <h3>Billing Activity</h3>
        </div>
        <p className="ms-4 sura">
          No billing activity has been recorded in this system for this client
        </p>
      </div>
    );
  }*/

  return (
    <React.Fragment>
      <div className="content-panel">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div className="header flex-row-aligned pb-3">
          <h3>Billing Activity</h3>
        </div>
        <div className="d-flex flex-column">
          <div className="w-100">
            <table className="w-100">
              <thead className="border-bottom border-top">
                <tr>
                  <th>Date</th>
                  <th className="description">Description</th>
                  <th className="payment-method">Payment Method</th>
                  <th>Amount</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((t, i) => (
                  <tr key={i} className={i % 2 === 0 ? `background-gray` : ''}>
                    <td>{moment(t.insertTs).format('MM/DD/YYYY')}</td>
                    <td className="description d-flex">
                      {t.isInvoice && (
                        <button
                          className="btn-text-secondary sura font-14"
                          onClick={e => {
                            e.preventDefault();
                            setSelectedInvoice(t);
                            setShowGenerateInvoiceModal(true);
                          }}
                        >
                          {t.description}
                        </button>
                      )}
                      {!t.isInvoice && t.description}
                    </td>
                    <td className="payment-method">
                      {t.category ? formatPaymentMethod(t, true) : ''}
                    </td>
                    <td>{formatCurrency(t.amount)}</td>
                    <td>{formatCurrency(t.balance)}</td>
                    <td>{/*<AdminBillingOptions transaction={t} />*/}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showGenerateInvoiceModal && (
        <GenerateInvoiceModal
          invoiceId={selectedInvoice.id}
          handleClose={() => setShowGenerateInvoiceModal(false)}
        />
      )}
    </React.Fragment>
  );
}

export default Billing;

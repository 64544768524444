import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import http from '../../services/httpService';

import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import Page from '../common/page';

function DoctorDashboard() {
  const [doctors, setDoctors] = useState([
    { name: 'All', value: 'ALL' },
    { name: 'Relief', value: 'RELIEF' },
  ]);
  const [results, setResults] = useState(null);
  const [chartData, setChartData] = useState(null);

  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').tz('America/Denver').format('YYYY-MM-DD')
  );
  const [employeeId, setEmployeeId] = useState('ALL');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getDoctors = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('/employee/getDoctors');
        const doctorOptions = response.data.map(t => {
          return { name: t.last_name, value: t.id };
        });
        setDoctors([
          { name: 'All', value: 'ALL' },
          { name: 'Relief', value: 'RELIEF' },
          ...doctorOptions,
        ]);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDoctors();
  }, []);

  useEffect(() => {
    const getDoctorStats = async () => {
      try {
        setIsLoading(true);
        const response = await http.post('/report/getDoctorStatsDetailed', {
          startDate,
          endDate,
          employeeId,
          locationId: 1,
        });

        const results = response.data;

        let doctorHours = 0;
        let blockedHours = 0;
        let roomHours = 0;
        let roomApptCount = 0;

        const chartData = [];
        for (const date of Object.keys(results)) {
          const result = results[date];

          chartData.push({
            name: moment.tz(date, 'America/Denver').format('MM/DD'),
            roomCapacity: result.roomCapacity ? result.roomCapacity.toFixed(2) : 0,
            output: result.roomApptsPerRoomHour ? result.roomApptsPerRoomHour.toFixed(2) : 0,
          });

          doctorHours += result.doctorHours || 0;
          blockedHours += result.blockedHours || 0;
          roomHours += result.roomHours || 0;
          roomApptCount += result.roomApptCount || 0;
        }

        setChartData(chartData);
        setResults({
          doctorHours: doctorHours.toFixed(2),
          blockedHours: blockedHours.toFixed(2),
          roomCapacity: roomHours ? (((roomHours - blockedHours) * 3) / roomHours).toFixed(2) : 0,
          roomApptsPerRoomHour: roomHours ? (roomApptCount / roomHours).toFixed(2) : 0,
        });
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDoctorStats();
  }, [startDate, endDate, employeeId]);

  return (
    <Page>
      <div className="background-white box-shadow flex-grow-1">
        <div className="p-5">
          {(isLoading || !results) && <i className="mt-3 fa fa-circle-notch fa-spin subtle" />}
          {errorMessage && <p className="error">{errorMessage}</p>}
          {!isLoading && results && (
            <React.Fragment>
              <div className="d-flex mt-2">
                <Input
                  className="me-2"
                  type="date"
                  name="startDate"
                  label="Start Date"
                  value={startDate}
                  onChange={setStartDate}
                />
                <Input
                  type="date"
                  name="endDate"
                  label="End Date"
                  value={endDate}
                  onChange={setEndDate}
                />
                <button
                  className="mt-2 ms-2 btn-rounded-primary"
                  onClick={() => {
                    setStartDate(
                      moment
                        .tz(startDate, 'America/Denver')
                        .subtract(1, 'month')
                        .startOf('month')
                        .format('YYYY-MM-DD')
                    );
                    setEndDate(
                      moment
                        .tz(endDate, 'America/Denver')
                        .subtract(1, 'month')
                        .endOf('month')
                        .format('YYYY-MM-DD')
                    );
                  }}
                >
                  Previous Month
                </button>
                <button
                  className="mt-2 ms-2 btn-rounded-primary"
                  onClick={() => {
                    setStartDate(
                      moment
                        .tz(startDate, 'America/Denver')
                        .add(1, 'month')
                        .startOf('month')
                        .format('YYYY-MM-DD')
                    );
                    setEndDate(
                      moment
                        .tz(endDate, 'America/Denver')
                        .add(1, 'month')
                        .endOf('month')
                        .format('YYYY-MM-DD')
                    );
                  }}
                >
                  Next Month
                </button>
              </div>

              <InputSelect
                name="employeeId"
                value={employeeId}
                label="Doctor"
                optionConfig={doctors}
                onChange={setEmployeeId}
              />

              <div className="mt-4">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '200px' }}></th>
                      <th style={{ width: '100px' }}>Today</th>
                      <th style={{ width: '100px' }}>Week to Date</th>
                      <th style={{ width: '100px' }}>Month to Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Scheduled Hours</td>
                      <td></td>
                      <td></td>
                      <td>{results.doctorHours}</td>
                    </tr>
                    <tr>
                      <td>Blocked</td>
                      <td></td>
                      <td></td>
                      <td>{results.blockedHours}</td>
                    </tr>
                    <tr>
                      <td>Room Capacity</td>
                      <td></td>
                      <td></td>
                      <td>{results.roomCapacity}</td>
                    </tr>
                    <tr>
                      <td>Output</td>
                      <td></td>
                      <td></td>
                      <td>{results.roomApptsPerRoomHour}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                <LineChart width={1000} height={500} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="roomCapacity"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="output" stroke="#82ca9d" />
                </LineChart>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  );
}

export default DoctorDashboard;

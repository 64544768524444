import React from 'react';

import ConsentForm from './consentForm';

import Constants from '../../constants';

function EuthanasiaConsentForm() {
  return (
    <ConsentForm
      documentTypeId={Constants.EUTHANASIA_CONSENT_FORM}
      header="Euthanasia Consent"
      body={
        <React.Fragment>
          <p className="mt-5 sura">
            I, the undersigned, do hereby certify that I am the owner (or duly authorized agent for
            the owner) of the animal described above; that I do hereby give Hospital Owner, DVM, his
            agents, servants, and representatives full and complete authority to euthanize the said
            animal in whatever manner the said Doctor, his agents, servants, or representatives
            shall deem fit.
          </p>
          <p className="mt-4 sura">
            I do hereby, and by these presents forever release the said Doctor, his agents,
            servants, or representatives from any and all liability for so euthanizing the said
            animal.
          </p>
          <p className="mt-4 sura">
            I do also certify that the said animal has not bitten any person or animal during the
            last fifteen (15) days, and to the best of my knowledge has not been exposed to Rabies.
          </p>
        </React.Fragment>
      }
    />
  );
}

export default EuthanasiaConsentForm;

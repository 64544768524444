import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

function RabiesCertificate({ petId, handleClose }) {
  const [rabiesCertificate, setRabiesCertificate] = useState();

  useEffect(() => {
    const getInvoice = async () => {
      const response = await http.post(
        '/forms/generateRabiesCertificate',
        { petId },
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const pdf = URL.createObjectURL(file);
      setRabiesCertificate(pdf);
    };
    getInvoice();
  }, [petId]);

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        <embed
          src={rabiesCertificate}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}

export default RabiesCertificate;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';

function Timepunch({ timepunch, selectedEmployee, handleClose }) {
  const [employees, setEmployees] = useState([]);

  const [employeeId, setEmployeeId] = useState(
    timepunch ? timepunch.employee_id : selectedEmployee ? selectedEmployee.employee_id : ''
  );
  const [locationId, setLocationId] = useState(timepunch ? timepunch.location_id : '');
  const [clockInDate, setClockInDate] = useState(
    timepunch ? moment(timepunch.clock_in_ts).tz('America/Denver').format('YYYY-MM-DD') : ''
  );
  const [clockInTime, setClockInTime] = useState(
    timepunch ? moment(timepunch.clock_in_ts).tz('America/Denver').format('HH:mm') : ''
  );
  const [clockInNotes, setClockInNotes] = useState(timepunch ? timepunch.clock_in_notes : '');
  const [clockOutDate, setClockOutDate] = useState(
    timepunch
      ? timepunch.clock_out_ts
        ? moment(timepunch.clock_out_ts).tz('America/Denver').format('YYYY-MM-DD')
        : moment(timepunch.clock_in_ts).tz('America/Denver').format('YYYY-MM-DD')
      : ''
  );
  const [clockOutTime, setClockOutTime] = useState(
    timepunch
      ? timepunch.clock_out_ts
        ? moment(timepunch.clock_out_ts).tz('America/Denver').format('HH:mm')
        : ''
      : ''
  );
  const [clockOutNotes, setClockOutNotes] = useState(timepunch ? timepunch.clock_out_notes : '');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getEmployees = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');
        const response = await http.get('/employee/getActive');
        setEmployees([
          { name: 'Select', value: '' },
          ...response.data.map(t => {
            return { name: `${t.first_name} ${t.last_name}`, value: t.id };
          }),
        ]);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    if (!selectedEmployee) {
      getEmployees();
    }
  }, [selectedEmployee]);

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      locationId,
      clockInDate,
      clockInTime,
      clockInNotes,
      clockOutDate,
      clockOutTime,
      clockOutNotes,
    };

    try {
      if (timepunch) {
        params.timepunchId = timepunch.id;
        await http.post('/employee/editTimepunch', params);
      } else {
        params.employeeId = employeeId;
        await http.post('/employee/createTimepunch', params);
      }
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form className="background-gray box-shadow-bottom p-5 rounded-bottom">
        {errorMessage && <p className="error align-self-start">{errorMessage}</p>}
        {isLoading && (
          <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
        )}
        {!isLoading && (
          <React.Fragment>
            <h1 className="mb-3">Timepunch</h1>
            <InputSelect
              name="employeeId"
              value={employeeId}
              label="Employee"
              optionConfig={
                selectedEmployee
                  ? [{ name: selectedEmployee.employee_name, value: employeeId }]
                  : employees
              }
              onChange={setEmployeeId}
              disabled={selectedEmployee || timepunch}
            />
            <InputSelect
              name="locationId"
              value={locationId}
              label="Location"
              optionConfig={[
                { name: 'Select', value: '' },
                { name: 'Loveland', value: '1' },
                { name: 'Evans', value: '2' },
              ]}
              onChange={setLocationId}
              disabled={timepunch}
            />
            <h2 className="mt-4 mb-3">Clock In</h2>
            <div className="input-width d-flex mb-2">
              <Input
                name="clockInDate"
                type="date"
                value={clockInDate}
                label="Date"
                onChange={value => {
                  setClockInDate(value);
                  setClockOutDate(value);
                }}
                disabled={timepunch}
              />
              <Input
                name="clockInTime"
                type="time"
                value={clockInTime}
                label="Time"
                onChange={setClockInTime}
              />
            </div>
            <InputTextarea
              className="w-100"
              name="clockInNotes"
              value={clockInNotes}
              label="Notes*"
              rows="2"
              onChange={setClockInNotes}
            />

            <h2 className="mt-4 mb-3">Clock Out</h2>
            <div className="input-width d-flex mb-2">
              <Input
                name="clockOutDate"
                type="date"
                value={clockOutDate}
                label="Date"
                onChange={setClockOutDate}
                disabled={true}
              />
              <Input
                name="clockOutTime"
                type="time"
                value={clockOutTime}
                label="Time"
                onChange={setClockOutTime}
              />
            </div>
            {clockOutTime && (
              <InputTextarea
                className="w-100"
                name="clockOutNotes"
                value={clockOutNotes}
                label="Notes*"
                rows="2"
                onChange={setClockOutNotes}
              />
            )}

            <ButtonPanel
              primaryButtonText="Save"
              handleCancel={handleClose}
              handleSubmit={() => handleSubmit(false)}
              turnOffProcessing={true}
              handleErrorMessage={setErrorMessage}
              disabled={
                !employeeId ||
                !locationId ||
                !clockInDate ||
                !clockInTime ||
                !clockInNotes ||
                (clockOutTime && !clockOutNotes)
              }
            />
          </React.Fragment>
        )}
      </form>
    </Modal>
  );
}

export default Timepunch;

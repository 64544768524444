import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Input from '../../common/input';
import InputSelect from '../../common/inputSelect';

import { formatCurrency } from '../../../util';

function DoctorStats() {
  const [doctors, setDoctors] = useState([
    { name: 'All', value: 'ALL' },
    { name: 'Relief', value: 'RELIEF' },
  ]);
  const [lovelandStats, setLovelandStats] = useState(null);
  const [evansStats, setEvansStats] = useState(null);

  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').tz('America/Denver').format('YYYY-MM-DD')
  );
  const [employeeId, setEmployeeId] = useState('ALL');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getDoctors = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('/employee/getDoctors');
        const doctorOptions = response.data.map(t => {
          return { name: t.last_name, value: t.id };
        });
        setDoctors([
          { name: 'All', value: 'ALL' },
          { name: 'Relief', value: 'RELIEF' },
          ...doctorOptions,
        ]);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDoctors();
  }, []);

  useEffect(() => {
    const getDoctorStats = async () => {
      try {
        setIsLoading(true);
        const lovelandResponse = await http.post('/report/getDoctorStats', {
          startDate,
          endDate,
          employeeId,
          locationId: 1,
        });
        setLovelandStats(lovelandResponse.data);

        const evansResponse = await http.post('/report/getDoctorStats', {
          startDate,
          endDate,
          employeeId,
          locationId: 2,
        });
        setEvansStats(evansResponse.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDoctorStats();
  }, [startDate, endDate, employeeId]);

  if (isLoading || !lovelandStats || !evansStats) {
    return <i className="mt-3 fa fa-circle-notch fa-spin subtle" />;
  }
  if (errorMessage) return <p className="error">{errorMessage}</p>;

  return (
    <React.Fragment>
      <div className="d-flex mt-2">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Previous Month
        </button>
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .add(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .add(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Next Month
        </button>
      </div>

      <InputSelect
        name="employeeId"
        value={employeeId}
        label="Doctor"
        optionConfig={doctors}
        onChange={setEmployeeId}
      />

      <div className="mt-4">
        <table className="ms-4">
          <thead>
            <tr>
              <th style={{ width: '200px' }}></th>
              <th style={{ width: '100px' }}>Loveland</th>
              <th style={{ width: '100px' }}>Evans</th>
              <th style={{ width: '100px' }}>Total</th>
            </tr>
          </thead>
        </table>
        <h3 className="mb-2">Completed</h3>
        <table className="ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Doctor hours</td>
              <td style={{ width: '100px' }}>{lovelandStats.doctorHours.toFixed(2)}</td>
              <td style={{ width: '100px' }}>{evansStats.doctorHours.toFixed(2)}</td>
              <td style={{ width: '100px' }}>
                {(lovelandStats.doctorHours + evansStats.doctorHours).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{lovelandStats.surgeryHours.toFixed(2)}</td>
              <td>{evansStats.surgeryHours.toFixed(2)}</td>
              <td>{(lovelandStats.surgeryHours + evansStats.surgeryHours).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{lovelandStats.roomHours.toFixed(2)}</td>
              <td>{evansStats.roomHours.toFixed(2)}</td>
              <td>{(lovelandStats.roomHours + evansStats.roomHours).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Appointments</td>
              <td style={{ width: '100px' }}>{lovelandStats.apptCount}</td>
              <td style={{ width: '100px' }}>{evansStats.apptCount}</td>
              <td style={{ width: '100px' }}>{lovelandStats.apptCount + evansStats.apptCount}</td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{lovelandStats.surgeryApptCount}</td>
              <td>{evansStats.surgeryApptCount}</td>
              <td>{lovelandStats.surgeryApptCount + evansStats.surgeryApptCount}</td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{lovelandStats.roomApptCount}</td>
              <td>{evansStats.roomApptCount}</td>
              <td>{lovelandStats.roomApptCount + evansStats.roomApptCount}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Appointments/Doctor Hour</td>
              <td style={{ width: '100px' }}>{lovelandStats.apptPerDoctorHour.toFixed(2)}</td>
              <td style={{ width: '100px' }}>{evansStats.apptPerDoctorHour.toFixed(2)}</td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{lovelandStats.sxPerSxHour.toFixed(2)}</td>
              <td>{evansStats.sxPerSxHour.toFixed(2)}</td>
              <td></td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{lovelandStats.roomApptsPerRoomHour.toFixed(2)}</td>
              <td>{evansStats.roomApptsPerRoomHour.toFixed(2)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Retail Value</td>
              <td style={{ width: '100px' }}>{formatCurrency(lovelandStats.retailValue)}</td>
              <td style={{ width: '100px' }}>{formatCurrency(evansStats.retailValue)}</td>
              <td style={{ width: '100px' }}>
                {formatCurrency(lovelandStats.retailValue + evansStats.retailValue)}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{formatCurrency(lovelandStats.retailValueSurgery)}</td>
              <td>{formatCurrency(evansStats.retailValueSurgery)}</td>
              <td>
                {formatCurrency(lovelandStats.retailValueSurgery + evansStats.retailValueSurgery)}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{formatCurrency(lovelandStats.retailValueRooms)}</td>
              <td>{formatCurrency(evansStats.retailValueRooms)}</td>
              <td>
                {formatCurrency(lovelandStats.retailValueRooms + evansStats.retailValueRooms)}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Retail/Doctor Hour</td>
              <td style={{ width: '100px' }}>
                {formatCurrency(lovelandStats.retailValuePerDoctorHour)}
              </td>
              <td style={{ width: '100px' }}>
                {formatCurrency(evansStats.retailValuePerDoctorHour)}
              </td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{formatCurrency(lovelandStats.retailValueSxPerDoctorHour)}</td>
              <td>{formatCurrency(evansStats.retailValueSxPerDoctorHour)}</td>
              <td></td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{formatCurrency(lovelandStats.retailValueRoomsPerDoctorHour)}</td>
              <td>{formatCurrency(evansStats.retailValueRoomsPerDoctorHour)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default DoctorStats;

import React, { useState } from 'react';
import moment from 'moment-timezone';

import Input from '../common/input';
import Page from '../common/page';

import http from '../../services/httpService';

function AdminTools() {
  const [jobName, setJobName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    await http.post(`/runJob`, { jobName });
    setMessage(
      `Job was submitted to the queue @ ${moment().tz('America/Denver').format('hh:mm:ss A')}`
    );
  };

  return (
    <Page>
      <div className="p-4 background-gray box-shadow rounded-bottom d-flex flex-column">
        <h1 className="pb-4">Run Job Manually</h1>
        <Input name="jobName" value={jobName} label="Job Name" onChange={setJobName} />
        <button className="mb-3 me-5 btn-filled-primary input-width" onClick={handleSubmit}>
          Run
        </button>
        {message && <p className="sura">{message}</p>}
      </div>
    </Page>
  );
}

export default AdminTools;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import InputSelect from '../common/inputSelect';

import EmailEstimateModal from './emailEstimateModal';

import EstimateConstants from '../../estimateConstants';

function SurgeryEstimates({ surgery }) {
  const [showConvertToTreatmentPlanModal, setShowConvertToTreatmentPlanModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const [selectedEstimate, setSelectedEstimate] = useState({});

  const navigate = useNavigate();

  const handleRevertToDraft = async id => {
    await http.put(`/estimate/revertToDraft?estimate_id=${id}`);
    navigate(0);
  };

  const handleLock = async id => {
    await http.put(`/estimate/lock?estimate_id=${id}`);
    navigate(0);
  };

  const handleDetach = async id => {
    await http.put(`/estimate/detach?estimate_id=${id}`);
    navigate(0);
  };

  const handleDelete = async id => {
    await http.delete(`/estimate/delete?estimate_id=${id}`);
    navigate(0);
  };

  return (
    <React.Fragment>
      {surgery.estimates.map((e, k) => {
        const info = JSON.parse(e.info);
        const isHpComparison = info.type === EstimateConstants.HP_COMPARISON;
        const statusId = Number(e.status_id);
        const isDraft = statusId === EstimateConstants.DRAFT;
        const isLocked = statusId === EstimateConstants.LOCKED;
        return (
          <div key={k} className="ms-3">
            <div className="d-flex align-items-center">
              {isDraft && (
                <Icon name="fa fa-drafting-compass" className="error mb-0" tooltipText="Draft" />
              )}
              {isLocked && <Icon name="fa fa-lock" className="attention" tooltipText="Locked" />}
              <button
                className="btn-text-secondary font-14"
                onClick={() =>
                  navigate(`/estimate-generator/${e.id}`, {
                    state: { redirectPath: '/surgery-dashboard' },
                  })
                }
              >
                {info.title} ({isHpComparison ? 'HP Comparison' : 'Treatment Plan'})
              </button>
              {isDraft && (
                <button
                  onClick={() => {
                    setSelectedEstimate(e);
                    setShowEmailModal(true);
                  }}
                >
                  <Icon name="email" className="ms-3" tooltipText="Send to Client" />
                </button>
              )}
              {isDraft && (
                <button onClick={() => handleLock(e.id)}>
                  <Icon name="fa fa-lock" className="ms-2" tooltipText="Lock (no email)" />
                </button>
              )}
              {isLocked && (
                <button onClick={() => handleRevertToDraft(e.id)}>
                  <Icon
                    name="fa fa-drafting-compass"
                    className="ms-2"
                    tooltipText="Revert to Draft"
                  />
                </button>
              )}
              {isHpComparison && (
                <button
                  onClick={() => {
                    setSelectedEstimate(e);
                    setShowConvertToTreatmentPlanModal(true);
                  }}
                >
                  <Icon
                    name="fa fa-exchange"
                    className="ms-2"
                    tooltipText="Convert to Treatment Plan"
                  />
                </button>
              )}
              <button onClick={() => handleDetach(e.id)}>
                <Icon name="fa fa-unlink" className="ms-2" tooltipText="Detach from Surgery" />
              </button>
              <button
                onClick={() => {
                  setSelectedEstimate(e);
                  setShowDeleteConfirmationModal(true);
                }}
              >
                <Icon name="fa fa-trash" className="ms-2" tooltipText="Delete" />
              </button>
              <Icon
                name="ms-2 fa fa-user align-self-center"
                tooltipText={info.createdBy ? `Created by ${info.createdBy}` : ''}
                className="m-0"
              />
            </div>
          </div>
        );
      })}
      {showConvertToTreatmentPlanModal && (
        <ConvertToTreatmentPlanModal
          estimate={selectedEstimate}
          handleClose={() => setShowConvertToTreatmentPlanModal(false)}
        />
      )}
      {showEmailModal && (
        <EmailEstimateModal
          estimateId={selectedEstimate.id}
          appt={surgery}
          handleClose={() => setShowEmailModal(false)}
        />
      )}
      {showDeleteConfirmationModal && (
        <Confirmation
          message="Are you sure you would like to delete this estimate? This action cannot be undone."
          handleConfirm={() => handleDelete(selectedEstimate.id)}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
    </React.Fragment>
  );
}

function ConvertToTreatmentPlanModal({ estimate, handleClose }) {
  const estimateComparisonOptions = JSON.parse(estimate.info).displayVersions;

  const [hpSelection, setHpSelection] = useState(estimateComparisonOptions.findLastIndex(t => t));

  const navigate = useNavigate();

  const handleSubmit = async () => {
    await http.post(
      `/estimate/convertToTreatmentPlan?estimate_id=${estimate.id}&hp_selection=${hpSelection}`
    );
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <InputSelect
          name="hpSelection"
          value={hpSelection}
          label="Health Plan Selection"
          optionConfig={EstimateConstants.HP_OPTIONS_MAP.filter(
            (t, i) => estimateComparisonOptions[i]
          )}
          onChange={setHpSelection}
        />
        <ButtonPanel
          primaryButtonText="Convert"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={hpSelection < 0}
        />
      </form>
    </Modal>
  );
}

export default SurgeryEstimates;

import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-lodash-debounce';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import InputNumber from '../common/inputNumber';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';

import EstimateConstants from '../../estimateConstants';

function EstimatorModal({ handleClose, handleSubmit, selectedService }) {
  const PERCENT = '%';
  const DOLLARS = '$';

  const pricingCategoryMap = [
    { name: 'Retail', value: EstimateConstants.RETAIL },
    { name: 'Elective', value: EstimateConstants.ELECTIVE },
    { name: 'Discounted on Health Plan', value: EstimateConstants.HP_DISCOUNTED },
  ];

  const [inventoryItems, setInventoryItems] = useState([]);

  const [itemName, setItemName] = useState(selectedService ? selectedService.description : '');
  const itemNameDebounced = useDebounce(itemName, 500);
  const [lowerRange, setLowerRange] = useState(selectedService ? selectedService.lowerRange : 0);
  const [variance, setVariance] = useState(0);
  const [varianceUnit, setVarianceUnit] = useState(PERCENT);
  const [upperRange, setUpperRange] = useState(selectedService ? selectedService.upperRange : 0);
  const [pricingCategory, setPricingCategory] = useState(
    selectedService ? selectedService.pricingCategory : EstimateConstants.RETAIL
  );
  const [quantity, setQuantity] = useState(selectedService ? selectedService.quantity || 1 : 1);
  const [decline, setDecline] = useState(selectedService ? selectedService.decline : false);
  const [notes, setNotes] = useState(selectedService ? selectedService.notes : '');

  const [allowSearch, setAllowSearch] = useState(!selectedService);
  const [allowUpperRangeReset, setAllowUpperRangeReset] = useState(!selectedService);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getInventoryItems = async () => {
      try {
        setErrorMessage('');
        const response = await http.get(
          `/estimate/getInventoryItems?search_string=${itemNameDebounced}`
        );

        setInventoryItems(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    if (itemNameDebounced && allowSearch) {
      getInventoryItems();
    }
    setAllowSearch(true);
  }, [itemNameDebounced]);

  useEffect(() => {
    if (!allowUpperRangeReset) {
      setAllowUpperRangeReset(true);
      return;
    }
    const newUpperRange =
      variance === 0
        ? 0
        : varianceUnit === PERCENT
        ? lowerRange * (1 + variance / 100)
        : lowerRange + variance;
    setUpperRange(newUpperRange);
  }, [lowerRange, variance, varianceUnit]);

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        <Input name="itemName" value={itemName} label="Item" onChange={setItemName} />
        {inventoryItems.length > 0 && itemName && (
          <div
            className="background-white p-3"
            style={{ position: 'absolute', top: '110px', zIndex: '3' }}
          >
            {inventoryItems.map((t, i) => (
              <p
                key={i}
                className="sura pointer mt-2"
                onClick={() => {
                  setAllowSearch(false);
                  setItemName(t.displayName);
                  setLowerRange(t.price);
                  setPricingCategory(t.pricingCategory);
                  setInventoryItems([]);
                }}
              >
                {t.name}
              </p>
            ))}
          </div>
        )}
        <InputNumber name="lowerRange" label="Price" value={lowerRange} onChange={setLowerRange} />
        <div className="d-flex">
          <InputNumber
            className="w-75"
            name="variance"
            label="Variance"
            value={variance}
            onChange={setVariance}
          />
          <InputSelect
            className="ms-2 w-25"
            name="varianceUnit"
            value={varianceUnit}
            label="Unit"
            optionConfig={[PERCENT, DOLLARS]}
            rawOptions={true}
            onChange={setVarianceUnit}
          />
        </div>
        <InputNumber
          name="upperRange"
          label="Price (upper range, if applicable)"
          value={upperRange}
          onChange={setUpperRange}
          disabled={variance}
        />
        <InputSelect
          name="pricingCategory"
          value={pricingCategory}
          label="Pricing Category"
          optionConfig={pricingCategoryMap}
          onChange={setPricingCategory}
        />
        <InputNumber name="quantity" label="Quantity" value={quantity} onChange={setQuantity} />
        <InputCheckbox
          className="mb-3"
          name="decline"
          label="Client may decline"
          checked={decline}
          onChange={setDecline}
        />
        <InputTextarea name="notes" value={notes} label="Notes" onChange={setNotes} />
        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={() =>
            handleSubmit({
              description: itemName,
              lowerRange,
              upperRange,
              pricingCategory,
              quantity,
              decline,
              notes,
            })
          }
          disabled={!itemName}
        />
      </form>
    </Modal>
  );
}

export default EstimatorModal;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PetModal from '../pet/petModal';

import Icon from '../common/icon';

import Constants from '../../constants';

function ClientOverview({ client, isLoading }) {
  const [showPetModal, setShowPetModal] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="content-panel">
      {isLoading && <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle" />}
      {!isLoading && client.pets.length === 0 && (
        <p className="sura font-16 mb-4 ms-1">No pets to display for this client</p>
      )}
      {!isLoading && client.pets.length > 0 && (
        <React.Fragment>
          {client.pets.map((pet, i) => (
            <div
              key={i}
              className="pet-snapshot d-flex justify-content-between"
              onClick={() => navigate(`/client/${pet.client_id}/pet/${pet.id}`)}
            >
              <div className="content w-100">
                <div className="flex-row-aligned justify-content-between mb-3">
                  <div className="flex-row-aligned">
                    {Number(pet.species_id) === Constants.CANINE && <Icon name="dog" />}
                    {Number(pet.species_id) === Constants.FELINE && <Icon name="cat" />}
                    <h2 className="me-2">
                      <span>{pet.name}</span>
                      <span className="ms-2 blue font-16">({pet.daysmart_pet_id})</span>
                    </h2>
                  </div>
                  {pet.is_active === 'N' && pet.deceased === 'N' && (
                    <p className="sura font-16 orange">Inactive</p>
                  )}
                  {pet.deceased === 'Y' && <p className="sura font-16 error">Deceased</p>}
                </div>
                <div className="quick-view-items d-flex">
                  <div>
                    <p className="sura">Sex</p>
                    <p>{pet.sex}</p>
                  </div>
                  <div>
                    <p className="sura">Age</p>
                    <p>{pet.age}</p>
                  </div>
                  <div>
                    <p className="sura">Breed</p>
                    <p>{pet.primary_breed}</p>
                  </div>
                </div>
              </div>
              <div className="more-details d-flex justify-content-center align-items-center">
                <Icon name="more" className="m-0" />
              </div>
            </div>
          ))}
        </React.Fragment>
      )}
      <button className="btn-rounded-primary mt-3" onClick={() => setShowPetModal(true)}>
        <Icon name="add" />
        New Pet
      </button>
      {showPetModal && <PetModal clientId={client.id} handleClose={() => setShowPetModal(false)} />}
    </div>
  );
}

export default ClientOverview;

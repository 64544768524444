import React from 'react';

import Icon from '../common/icon';

function OriginalContract({ awsUrl }) {
  if (!awsUrl) return null;

  return (
    <button className="btn-text-secondary pe-4" type="button" onClick={() => window.open(awsUrl)}>
      <Icon name="file" className="blue" />
      <span className="blue sura font-14">Original Contract</span>
    </button>
  );
}

export default OriginalContract;

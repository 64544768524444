import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

function GenerateInvoiceModal({ invoiceId, handleClose }) {
  const [invoice, setInvoice] = useState();

  useEffect(() => {
    const getInvoice = async () => {
      const response = await http.post(
        '/forms/generateInvoice',
        { invoiceId },
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const pdf = URL.createObjectURL(file);
      setInvoice(pdf);
    };
    getInvoice();
  }, [invoiceId]);

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        <embed
          src={invoice}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}

export default GenerateInvoiceModal;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';

function EmployeeHealthPlan({ petId, handleClose }) {
  const [startDate, setStartDate] = useState(moment().tz('America/Denver').format('YYYY-MM-DD'));

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      petId,
      locationId: 1,
      startDate,
    };

    await http.post('/healthPlan/setupEmployeePlan', params);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error input-width">{errorMessage}</p>}
        <Input
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <ButtonPanel
          primaryButtonText="Create Health Plan"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default EmployeeHealthPlan;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputCustom from '../common/inputCustom';
import InputSelect from '../common/inputSelect';

import Constants from '../../constants';

function PetModal({ pet, clientId, handleClose }) {
  const [name, setName] = useState(pet ? pet.name : '');
  const [status, setStatus] = useState(
    pet ? (pet.is_active === 'Y' ? 'Active' : pet.deceased === 'Y' ? 'Deceased' : 'Inactive') : ''
  );
  const [statusDate, setStatusDate] = useState(moment().tz('America/Denver').format('YYYY-MM-DD'));
  const [speciesId, setSpeciesId] = useState(pet ? pet.species_id : Constants.CANINE);
  const [primaryBreedId, setPrimaryBreedId] = useState(pet ? pet.primary_breed_id : '');
  const [secondaryBreedId, setSecondaryBreedId] = useState(pet ? pet.secondary_breed_id || '' : '');
  const [color, setColor] = useState(pet ? pet.color || '' : '');
  const [sexId, setSexId] = useState(pet ? pet.sex_id : 5);
  const [years, setYears] = useState('');
  const [months, setMonths] = useState('');
  const [weeks, setWeeks] = useState('');
  const [birthdate, setBirthdate] = useState(
    pet && pet.birthdate ? moment.utc(pet.birthdate).format('YYYY-MM-DD') : ''
  );
  const [microchip, setMicrochip] = useState(pet ? pet.microchip || '' : '');

  const [otherSpeciesBreedId, setOtherSpeciesBreedId] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getBreeds = async () => {
      const response = await http.get(`/pet/getBreedForOtherSpecies`);
      const breed = response.data;
      setOtherSpeciesBreedId(breed.id);
    };

    getBreeds();
  }, []);

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      name,
      speciesId,
      primaryBreedId,
      secondaryBreedId,
      color,
      sexId,
      birthdate,
      microchip,
    };

    try {
      if (pet) {
        params.petId = pet.id;
        params.status = status;
        params.statusDate = status !== 'Active' ? statusDate : null;
        await http.put('/pet/update', params);
        navigate(0);
      } else {
        params.clientId = clientId;
        const response = await http.post('/pet/create', params);
        navigate(`client/${clientId}/pet/${response.data}`);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleSpeciesChange = speciesId => {
    setPrimaryBreedId(Number(speciesId) === Constants.OTHER ? otherSpeciesBreedId : '');
    setSecondaryBreedId('');
    setSpeciesId(speciesId);
  };

  const handleAgeChange = (years, months, weeks) => {
    setBirthdate(
      moment()
        .tz('America/Denver')
        .subtract(years || 0, 'years')
        .subtract(months || 0, 'months')
        .subtract(weeks || 0, 'weeks')
        .format('YYYY-MM-DD')
    );
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error input-width">{errorMessage}</p>}
        <h1 className="mb-4">New Pet</h1>
        <InputCustom
          selectedId={pet ? pet.client_id : clientId}
          retrievalEndpoint="/client/getById?client_id="
          displayNameField="displayName"
          label="Client"
          disabled={true}
          disableClear={true}
        />
        {pet && (
          <React.Fragment>
            <InputSelect
              name="status"
              label="Status"
              value={status}
              optionConfig={['Active', 'Inactive', 'Deceased']}
              rawOptions={true}
              onChange={setStatus}
            />
            {status !== 'Active' && (
              <div className="input-width">
                <Input
                  className="ms-5 mb-4 w-auto"
                  type="date"
                  name="statusDate"
                  value={statusDate}
                  label="Date"
                  onChange={setStatusDate}
                />
              </div>
            )}
          </React.Fragment>
        )}
        <Input name="name" value={name} label="Name*" onChange={setName} />
        <InputSelect
          name="speciesId"
          label="Species"
          value={speciesId}
          optionConfig={[
            { name: 'Canine', value: Constants.CANINE },
            { name: 'Feline', value: Constants.FELINE },
            { name: 'Other', value: Constants.OTHER },
          ]}
          onChange={speciesId => handleSpeciesChange(speciesId)}
        />
        <InputCustom
          selectedId={primaryBreedId}
          retrievalEndpoint="/pet/getBreedById?breed_id="
          searchEndpoint={`/pet/searchBreed?species_id=${speciesId}&`}
          displayNameField="name"
          label="Primary Breed*"
          handleChange={breed => setPrimaryBreedId(breed.id)}
          handleClear={() => {
            setPrimaryBreedId(null);
            setSecondaryBreedId(null);
          }}
          disabled={!speciesId}
          disableClear={Number(speciesId) === Constants.OTHER}
        />
        {primaryBreedId && Number(speciesId) !== Constants.OTHER && (
          <InputCustom
            selectedId={secondaryBreedId}
            retrievalEndpoint="/pet/getBreedById?breed_id="
            searchEndpoint={`/pet/searchBreed?species_id=${speciesId}&`}
            displayNameField="name"
            label="Secondary Breed"
            handleChange={breed => setSecondaryBreedId(breed.id)}
            handleClear={() => setSecondaryBreedId(null)}
            disabled={!speciesId}
            disableClear={Number(speciesId) === Constants.OTHER}
          />
        )}
        <Input name="color" value={color} label="Color" onChange={setColor} />
        <InputSelect
          name="sexId"
          label="Sex"
          value={sexId}
          optionConfig={[
            { name: 'Male (Intact)', value: 1 },
            { name: 'Male (Neutered)', value: 2 },
            { name: 'Female (Intact)', value: 3 },
            { name: 'Female (spayed)', value: 4 },
            { name: 'Unknown', value: 5 },
          ]}
          onChange={setSexId}
        />
        <div className="my-3">
          <p className="sura font-16 ms-2 mb-1">Age:</p>
          <div className="d-flex input-width">
            <Input
              type="number"
              name="years"
              value={years}
              label="Years"
              onChange={value => {
                setYears(value);
                handleAgeChange(value, months, weeks);
              }}
            />
            <Input
              className="ms-2"
              type="number"
              name="months"
              value={months}
              label="Months"
              onChange={value => {
                setMonths(value);
                handleAgeChange(years, value, weeks);
              }}
            />
            <Input
              className="ms-2"
              type="number"
              name="weeks"
              value={weeks}
              label="Weeks"
              onChange={value => {
                setWeeks(value);
                handleAgeChange(years, months, value);
              }}
            />
          </div>
          <Input
            type="date"
            name="birthdate"
            value={birthdate}
            label="Birthdate"
            onChange={value => {
              setYears('');
              setMonths('');
              setWeeks('');
              setBirthdate(value);
            }}
          />
        </div>
        <Input name="microchip" value={microchip} label="Microchip" onChange={setMicrochip} />
        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          turnOffProcessing={true}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default PetModal;

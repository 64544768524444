import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputEitherOr from '../common/inputEitherOr';
import InputNumber from '../common/inputNumber';
import InputSelect from '../common/inputSelect';
import InputTextArea from '../common/inputTextarea';

function SurgeryBlockModal({ block, scheduleColumns, handleClose }) {
  const isUpdate = block && block.id;

  const [date, setDate] = useState(
    block
      ? moment.tz(block.start, 'America/Denver').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  );
  const [scheduleColumnId, setScheduleColumnId] = useState(
    block && block.schedule_column_id ? block.schedule_column_id : ''
  );
  const [startTime, setStartTime] = useState(
    block && block.start ? moment.tz(block.start, 'America/Denver').format('HH:mm') : '08:00'
  );
  const [endTime, setEndTime] = useState(
    block && block.end ? moment.tz(block.end, 'America/Denver').format('HH:mm') : '14:00'
  );
  const [standardBlocks, setStandardBlocks] = useState(
    block && block.blocks ? block.blocks - Number(block.blocks_urgent) : 6
  );
  const [urgentBlocks, setUrgentBlocks] = useState(
    block && block.blocks_urgent ? Number(block.blocks_urgent) : 0
  );
  const [maxSurgeries, setMaxSurgeries] = useState(
    block && block.max_surgeries ? block.max_surgeries : 3
  );
  const [maxSterile, setMaxSterile] = useState(block && block.max_sterile ? block.max_sterile : 2);
  const [isHold, setIsHold] = useState(block && block.is_hold ? block.is_hold : 'N');
  const [notes, setNotes] = useState(block && block.notes ? block.notes : '');

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const params = {
      scheduleColumnId,
      date,
      startTime,
      endTime,
      blocks: isHold === 'Y' ? 0 : Number(standardBlocks) + Number(urgentBlocks),
      blocksUrgent: isHold === 'Y' ? 0 : Number(urgentBlocks),
      maxSurgeries: isHold === 'Y' ? 0 : maxSurgeries,
      maxSterile: isHold === 'Y' ? 0 : maxSterile,
      isHold,
      notes,
    };

    try {
      if (isUpdate) {
        await http.post(`/surgery/updateBlock`, { blockId: block.id, ...params });
      } else {
        await http.post(`/surgery/createBlock`, params);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        <Input
          name="date"
          type="date"
          value={date}
          label="Date"
          onChange={setDate}
          disabled={isUpdate}
        />
        <InputSelect
          name="scheduleColumnId"
          value={scheduleColumnId}
          label="Doctor"
          optionConfig={scheduleColumns}
          onChange={setScheduleColumnId}
          disabled={isUpdate}
        />
        <Input
          name="startTime"
          type="time"
          value={startTime}
          label="Start Time"
          onChange={setStartTime}
        />
        <Input name="endTime" type="time" value={endTime} label="End Time" onChange={setEndTime} />
        <InputEitherOr
          name="isHold"
          label="Is Hold?"
          value={isHold}
          option1={{ name: 'Yes', value: 'Y' }}
          option2={{ name: 'No', value: 'N' }}
          onChange={({ target }) => setIsHold(target.value)}
        />
        {isHold === 'N' && (
          <React.Fragment>
            <label className="mb-1 ms-2 black">Blocks</label>
            <div className="d-flex input-width">
              <InputNumber
                style={{ width: '20px' }}
                name="standardBlocks"
                value={standardBlocks}
                label="Standard"
                onChange={setStandardBlocks}
              />
              <span className="mt-4 mx-2 font-18">+</span>
              <InputNumber
                style={{ width: '20px' }}
                name="urgentBlocks"
                value={urgentBlocks}
                label="Urgent"
                onChange={setUrgentBlocks}
              />
              <p className="mt-4 ms-2 font-16">
                <nobr>= {Number(standardBlocks) + Number(urgentBlocks)}</nobr>
              </p>
            </div>
            <InputNumber
              name="maxSurgeries"
              label="Max Surgeries"
              value={maxSurgeries}
              onChange={setMaxSurgeries}
            />
            <InputNumber
              name="maxSterile"
              label="Max Sterile"
              value={maxSterile}
              onChange={setMaxSterile}
            />
          </React.Fragment>
        )}
        <InputTextArea
          className="w-100"
          name="notes"
          value={notes}
          label="Notes"
          rows="5"
          onChange={setNotes}
        />
        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={
            !scheduleColumnId ||
            (isHold === 'N' && ((!standardBlocks && !urgentBlocks) || !maxSurgeries))
          }
        />
      </form>
    </Modal>
  );
}

export default SurgeryBlockModal;

import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-lodash-debounce';
import { useNavigate } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

import http from '../../services/httpService';

import Icon from '../common/icon';
import InputCheckbox from '../common/inputCheckbox';
import Input from '../common/input';
import Page from '../common/page';

import InventoryModal from './inventoryModal';

function InventoryDashboard() {
  const locationId = localStorage.getItem('locationId');

  const [inventory, setInventory] = useState([]);
  const [showAllTrackable, setShowAllTrackable] = useState(false);

  const [searchString, setSearchString] = useState('');
  const searchStringDebounced = useDebounce(searchString, 500);

  const [selectedInventory, setSelectedInventory] = useState(null);
  const [showInventoryModal, setShowInventoryModal] = useState(false);

  const [showLowBalanceReport, setShowLowBalanceReport] = useState(false);
  const [showExpirationReport, setShowExpirationReport] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getInventory = async () => {
      try {
        const response = await http.get(
          `/inventory/getInventory?search_string=${searchStringDebounced}&location_id=${locationId}`
        );
        setInventory(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    getInventory();
  }, [searchStringDebounced, locationId]);

  return (
    <Page selectedTab="inventory">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1>Inventory</h1>
        {errorMessage && <p className="error">{errorMessage}</p>}

        <div>
          <h3 className="mt-4" onClick={() => setShowLowBalanceReport(!showLowBalanceReport)}>
            {showLowBalanceReport && <Icon name="less" />}
            {!showLowBalanceReport && <Icon name="more" />}
            Low Balance Report
          </h3>
          <Collapse in={showLowBalanceReport}>
            <div className="my-3 ms-4">
              {inventory
                .filter(t => t.lowBalance)
                .map((t, i) => (
                  <p
                    key={i}
                    className="pointer hover-underline"
                    onClick={() => navigate(`/inventory/${t.id}`)}
                  >
                    {t.name}
                  </p>
                ))}
            </div>
          </Collapse>
        </div>

        <div>
          <h3 className="mt-4" onClick={() => setShowExpirationReport(!showExpirationReport)}>
            {showExpirationReport && <Icon name="less" />}
            {!showExpirationReport && <Icon name="more" />}
            Expiration Report
          </h3>
          <Collapse in={showExpirationReport}>
            <div className="my-3 ms-4">
              {inventory
                .filter(
                  t =>
                    t.purchases.filter(s => Number(s.balance) > 0).filter(s => s.isExpiring)
                      .length > 0
                )
                .map((t, i) => (
                  <p
                    key={i}
                    className="pointer hover-underline"
                    onClick={() => navigate(`/inventory/${t.id}`)}
                  >
                    {t.name}
                  </p>
                ))}
            </div>
          </Collapse>
        </div>

        <Input
          className="mt-4"
          name="searchString"
          value={searchString}
          label="Search"
          onChange={setSearchString}
        />

        <InputCheckbox
          className="my-2"
          name="showAllTrackable"
          label="Show All Trackable Items"
          checked={showAllTrackable}
          onChange={() => {
            setShowAllTrackable(!showAllTrackable);
            const updatedInventory = [...inventory];
            setInventory(updatedInventory);
          }}
        />

        <div className="p-2">
          <table>
            <thead>
              <tr className="border-bottom">
                <th>Name</th>
                <th>Balance</th>
                <th>Units</th>
                <th>Order Point</th>
                <th>Notice Before Exp.</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((t, i) => {
                if (!showAllTrackable && t.order_point === null) return null;
                return (
                  <tr key={i}>
                    <td
                      style={{ maxWidth: '300px' }}
                      className="pointer hover-underline"
                      onClick={() => navigate(`/inventory/${t.id}`)}
                    >
                      {t.name}
                    </td>
                    <td>{t.balance}</td>
                    <td>{t.unit_of_measure}</td>
                    <td>{t.order_point}</td>
                    <td>{t.notice_before_expiration}</td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedInventory(t);
                          setShowInventoryModal(true);
                        }}
                      >
                        <Icon name="edit" className="blue m-0" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {showInventoryModal && (
        <InventoryModal
          inventory={selectedInventory}
          locationId={locationId}
          handleClose={() => setShowInventoryModal(false)}
        />
      )}
    </Page>
  );
}

export default InventoryDashboard;

import React, { useState } from 'react';

import DoctorStats from './doctorStats';
import HealthPlanStats from './healthPlanStats';
import PaymentPlanStats from './paymentPlanStats';
import RetailComparison from './retailComparison';
import RevenueStats from './revenueStats';

import InputSelect from '../../common/inputSelect';
import Page from '../../common/page';

const REPORT_TYPE_MAP = [
  { value: 'REVENUE_STATS', name: 'Revenue Stats' },
  { value: 'DOCTOR_STATS', name: 'Doctor Stats' },
  { value: 'HEALTH_PLAN', name: 'Health Plan Stats' },
  { value: 'PAYMENT_PLAN', name: 'Payment Plan Stats' },
  { value: 'RETAIL_COMPARISON', name: 'Retail Comparison' },
];

function AdminDashboard() {
  const [reportType, setReportType] = useState('REVENUE_STATS');

  return (
    <Page>
      <div className="background-white box-shadow flex-grow-1">
        <div className="p-5">
          <InputSelect
            className="mb-4"
            name="reportType"
            value={reportType}
            label="Report Type"
            optionConfig={REPORT_TYPE_MAP}
            onChange={setReportType}
          />
          {reportType === 'REVENUE_STATS' && <RevenueStats />}
          {reportType === 'DOCTOR_STATS' && <DoctorStats />}
          {reportType === 'HEALTH_PLAN' && <HealthPlanStats />}
          {reportType === 'PAYMENT_PLAN' && <PaymentPlanStats />}
          {reportType === 'RETAIL_COMPARISON' && <RetailComparison />}
        </div>
      </div>
    </Page>
  );
}

export default AdminDashboard;

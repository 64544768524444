import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';

function ClockInModal({ handleClose }) {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));
  const date = moment().tz('America/Denver').format('YYYY-MM-DD');

  const [overrideCurrentTime, setOverrideCurrentTime] = useState(false);
  const [time, setTime] = useState(moment().tz('America/Denver').format('HH:mm'));
  const [locationId, setLocationId] = useState('');
  const [notes, setNotes] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setErrorMessage('');
      const params = { locationId, notes, isOverride: 'N' };

      if (overrideCurrentTime) {
        params.isOverride = 'Y';
        params.overrideDate = date;
        params.overrideTime = time;
      }

      await http.post('/employee/clockIn', params);

      loggedInUser.is_clocked_in = true;
      loggedInUser.prompt_clock_in = false;
      localStorage.setItem('loggedIn', JSON.stringify(loggedInUser));
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        <h1 className="mb-3">Clock In</h1>
        <h3 className="mb-4">
          Employee: {loggedInUser.first_name} {loggedInUser.last_name}
        </h3>
        <InputCheckbox
          className="mb-3"
          name="overrideCurrentTime"
          label="Override Current Time"
          checked={overrideCurrentTime}
          onChange={setOverrideCurrentTime}
        />
        {overrideCurrentTime && (
          <div className="input-width d-flex mb-3">
            <Input name="date" type="date" value={date} label="Date" disabled={true} />
            <Input name="time" type="time" value={time} label="Time" onChange={setTime} />
          </div>
        )}
        <InputSelect
          name="locationId"
          value={locationId}
          label="Location"
          optionConfig={[
            { name: 'Select', value: '' },
            { name: 'Loveland', value: '1' },
            { name: 'Evans', value: '2' },
          ]}
          onChange={setLocationId}
        />
        <InputTextarea
          className="w-100"
          name="notes"
          value={notes}
          label="Notes"
          rows="2"
          onChange={setNotes}
        />
        <ButtonPanel
          primaryButtonText="Clock In"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          turnOffProcessing={true}
          disabled={!locationId || (overrideCurrentTime && !notes)}
        />
      </form>
    </Modal>
  );
}

export default ClockInModal;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import InputSelect from '../common/inputSelect';
import { Mobile, NotMobile } from '../common/responsive';
import Page from '../common/page';

const CLIENT = 'client';
const PET = 'pet';
const APPOINTMENT = 'appointment';
const BLOCK_OFF = 'block_off';
const INVENTORY = 'inventory';
const INVOICE = 'invoice';
const PAYMENT = 'payment';
const REMINDER = 'reminder';
const MEDICAL_NOTE = 'medical_note';
const DIAGNOSIS = 'diagnosis';
const HEALTH_PLAN = 'health_plan';
const PAYMENT_PLAN = 'payment_plan';

function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [lastReconciled, setLastReconciled] = useState([]);
  const [reconType, setReconType] = useState(CLIENT);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [showTaskCompletionConfirmation, setShowTaskCompletionConfirmation] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(false);

  const filePicker = useRef(null);
  const navigate = useNavigate();

  const getTasks = async () => {
    try {
      setIsLoading(true);
      const response = await http.get('/report/getTasks');
      setTasks(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTasks();

    const getDateLastReconciled = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/report/getReconciliationJobInfo?recon_type=${reconType}`);
        setLastReconciled(response.data.last_completion_ts);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };

    getDateLastReconciled();
  }, [reconType]);

  const loadFile = async e => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setFile(formData);
    setFilename(file.name);
    setErrorMessage('');
  };

  const reset = () => {
    filePicker.current.value = '';
    setFile(null);
    setFilename(null);
    setErrorMessage('');
  };

  const runReport = async () => {
    try {
      setIsLoading(true);
      await http.post(`/report/runReconciliation?recon_type=${reconType}`, file, {
        'Content-type': 'multipart/form-data',
      });
      reset();
      setSucceeded(true);
      setIsLoading(false);
      getTasks();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };

  const openPet = async petId => {
    try {
      setIsLoading(true);
      const { data: pet } = await http.get(`/pet/getById?pet_id=${petId}`);
      navigate(`/client/${pet.client_id}/pet/${petId}`);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setIsLoading(false);
    }
  };

  const handleTaskCompletion = async () => {
    await http.post(`/report/markTaskCompleted?task_id=${selectedTask.id}`);
    await getTasks();
    setShowTaskCompletionConfirmation(false);
  };

  const uploadButton = className => {
    return (
      <React.Fragment>
        <InputSelect
          name="reconType"
          value={reconType}
          label="Reconciliation Type"
          optionConfig={[
            { name: 'Client', value: CLIENT },
            { name: 'Pet', value: PET },
            { name: 'Appointment', value: APPOINTMENT },
            { name: 'Block Off', value: BLOCK_OFF },
            { name: 'Inventory', value: INVENTORY },
            { name: 'Invoice', value: INVOICE },
            { name: 'Payment', value: PAYMENT },
            { name: 'Reminder', value: REMINDER },
            { name: 'Medical Note', value: MEDICAL_NOTE },
            { name: 'Diagnosis', value: DIAGNOSIS },
            { name: 'Health Plan', value: HEALTH_PLAN },
            { name: 'Payment Plan', value: PAYMENT_PLAN },
          ]}
          onChange={setReconType}
        />
        <button
          className={`btn-text-primary sura fw-normal ${className}`}
          type="button"
          onClick={() => filePicker.current.click()}
        >
          <Icon name="upload" />
          Upload Report
          <input
            type="file"
            id="filepicker"
            ref={filePicker}
            onChange={loadFile}
            style={{ display: 'none' }}
            accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </button>
      </React.Fragment>
    );
  };

  const reconcileButton = className => {
    return (
      <div className={className}>
        <button
          className="btn-filled-primary mb-2"
          type="button"
          onClick={runReport}
          disabled={(!file && reconType === PAYMENT_PLAN) || isLoading}
        >
          {isLoading && <i className="fa fa-circle-notch fa-spin" />}
          Reconcile
        </button>
        <span className="sura font-14 gray mt-4">
          Last Reconciled{' '}
          <nobr>{moment(lastReconciled).tz('America/Denver').format('MM/DD/YYYY hh:mm A')}</nobr>
        </span>
      </div>
    );
  };

  const fileInfo = file && !succeeded && (
    <div className="d-flex justify-content-between border rounded p-2 mt-3">
      <span className="sura font-16 ps-2">{filename}</span>
      <span onClick={reset}>
        <Icon name="close" tooltipText="Remove File" />
      </span>
    </div>
  );

  const rows = (className, isMobile) => {
    return tasks.map((t, i) => (
      <div key={t.id} className={`${className} ${i % 2 === 0 ? 'background-gray' : ''}`}>
        <span
          className={`font-14 sura py-2 me-3 dark-gray ${
            t.key && t.key_type ? 'btn-text-secondary pointer' : ''
          }`}
          onClick={() => {
            if (t.key_type === 'client' && t.key) {
              navigate(`/client/${t.key}`);
            } else if (t.key_type === 'payment_plan' && t.key) {
              navigate(`/client/${t.key}/payment-plans`);
            } else if (t.key_type === 'surgery' && t.key) {
              navigate(`/surgery-dashboard`);
            } else if (t.key_type === 'pet' && t.key) {
              openPet(t.key);
            }
          }}
        >
          {t.manager_only === 'Y' && '*'}
          {t.description}
        </span>
        <button
          className={`btn-text-primary font-14 sura ${isMobile ? 'mt-3' : ''}`}
          type="button"
          onClick={() => {
            setShowTaskCompletionConfirmation(true);
            setSelectedTask(t);
          }}
        >
          <nobr>Mark as Completed</nobr>
        </button>
      </div>
    ));
  };

  return (
    <Page selectedTab="tasks" numTasksRealTime={tasks.length}>
      <div className="background-gray box-shadow p-4">
        <Mobile>
          <div className="background-white box-shadow rounded p-3 mb-3">
            {errorMessage && <p className="error">{errorMessage}</p>}
            {uploadButton('font-14')}
            {fileInfo}
            {reconcileButton('mt-4')}
          </div>
        </Mobile>
        <NotMobile>
          <div className="background-white box-shadow rounded py-4 px-5 mb-3">
            {errorMessage && <p className="error">{errorMessage}</p>}
            {uploadButton()}
            {fileInfo}
            {reconcileButton('d-flex justify-content-between align-self-end mt-5')}
          </div>
        </NotMobile>
        <Mobile>
          {tasks.length > 0 && (
            <span className="p-2">{`There are ${tasks.length} tasks to complete`}</span>
          )}
        </Mobile>
        <div className="background-white box-shadow rounded py-3 px-4">
          <h2 className="fw-bolder border-bottom pb-2">Tasks</h2>
          <Mobile>{rows('p-3', true)}</Mobile>
          <NotMobile>{rows('flex-row-aligned justify-content-between px-3')}</NotMobile>
          {tasks.length > 0 && tasks.find(t => t.manager_only === 'Y') && (
            <div className="mt-3 ms-3 sura font-12">*Task may only be completed by a manager</div>
          )}
          {isLoading && (
            <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
          )}
          {!isLoading && rows.length === 0 && (
            <div className="flex-centered p-5 sura font-16">
              {succeeded
                ? 'Report is processing. You will receive an email when it is completed.'
                : 'Good work! You have completed all of your tasks'}
            </div>
          )}
        </div>
      </div>
      {showTaskCompletionConfirmation && (
        <Modal show={true} onHide={() => setShowTaskCompletionConfirmation(false)} centered>
          <div>
            {modalErrorMessage && <p className="error">{modalErrorMessage}</p>}
            <p className="sura mb-3">Please confirm that you have completed the following task:</p>
            <p className="sura fw-bolder">{selectedTask.description}</p>
            <ButtonPanel
              primaryButtonText="Confirm"
              handleCancel={() => setShowTaskCompletionConfirmation(false)}
              handleSubmit={handleTaskCompletion}
              handleErrorMessage={setModalErrorMessage}
            />
          </div>
        </Modal>
      )}
    </Page>
  );
}

export default Tasks;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import http from '../services/httpService';

import Icon from './common/icon';

import Constants from '../constants';

function Forms() {
  const [forms, setForms] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getForms = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/forms/getFormsToSign`);
        setForms(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getForms();
  }, []);

  const handleDeleteForm = async formId => {
    await http.delete(`/forms/delete?id=${formId}`);
    navigate(0);
  };

  return (
    <div className="py-5 background-gray d-flex flex-column flex-grow-1">
      <div className="container">
        <h1>Ready to Sign Documents</h1>
        <div className="w-100 mt-4">
          {errorMessage && <p className="error">{errorMessage}</p>}
          {isLoading && <i className="fa fa-circle-notch fa-spin" />}
          {!isLoading && (
            <React.Fragment>
              {forms.map((t, i) => {
                return (
                  <div key={i} className="d-flex align-items-center">
                    <div
                      className="font-18 pointer hover-underline"
                      onClick={() => navigate(`${t.link}/${t.id}`)}
                    >
                      {
                        Constants.DOCUMENT_TYPE_MAP.find(
                          a => a.value === Number(t.document_type_id)
                        ).name
                      }{' '}
                      ({t.name || t.first_name} {t.last_name})
                    </div>
                    <button onClick={() => handleDeleteForm(t.id)}>
                      <Icon name="fa fa-trash" className="ms-2" tooltipText="Delete" />
                    </button>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default Forms;

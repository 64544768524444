const NO_PLAN = 0;
const HP_COVERED = 1;
const HP_DISCOUNTED = 2;
const RETAIL = 5;
const ELECTIVE = 6;

const eCollar = {
  description: 'E-Collar',
  lowerRange: 15,
  upperRange: 25,
  pricingCategory: RETAIL,
  decline: false,
  notes: '',
};

const microchip = {
  description: 'Microchip',
  lowerRange: 38,
  upperRange: 0,
  pricingCategory: HP_DISCOUNTED,
  decline: true,
  notes: '',
};

const DENTAL = blocks => {
  const price = { 0.5: 250, 1: 500, 1.5: 500, 2: 800, 2.5: 1000, 3: 1500, 3.5: 1700, 4: 2000 };
  return [
    {
      description: 'Dental Procedure',
      lowerRange: price[blocks],
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes:
        'Includes pre-anesthetic bloodwork, close anesthesia monitoring, all dental procedures recommended by the veterinarian at this time, postoperative recovery care, and any medication needed to go home.',
    },
  ];
};

const DENTAL_AD_HOC = units => {
  return [
    {
      description: 'Dental Services',
      lowerRange: units * 40,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
    },
  ];
};

const SPAY_NEUTER = type => {
  return [
    {
      description: `${type} Package`,
      lowerRange: 480,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes:
        'Includes pre-anesthetic bloodwork, close anesthesia monitoring, postoperative recovery care, and any medication needed to go home.',
    },
    microchip,
  ];
};

const GENERAL_ANESTHESIA = blocks => {
  return [
    {
      description: 'General Anesthesia Procedure',
      lowerRange: blocks * 960,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes:
        'Includes pre-anesthetic bloodwork, close anesthesia monitoring, postoperative recovery care, and any medication needed to go home.',
    },
  ];
};

const GENERAL_ANESTHESIA_AD_HOC = units => {
  return [
    {
      description: 'General Anesthesia Services',
      lowerRange: units * 80,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
    },
  ];
};

const cremationPackage = [
  {
    description: `Clay Paw Print (add'l)`,
    lowerRange: 37.5,
    upperRange: 0,
    pricingCategory: RETAIL,
    decline: false,
    notes: '',
  },
  {
    description: `Fur Clippings (add'l)`,
    lowerRange: 15,
    upperRange: 0,
    pricingCategory: RETAIL,
    decline: false,
    notes: '',
  },
];

module.exports = {
  HP_COMPARISON: 1,
  TREATMENT_PLAN: 2,

  NO_PLAN,
  HP_COVERED,
  HP_DISCOUNTED,
  RETAIL,
  ELECTIVE,

  HP_OPTIONS_MAP: [
    { name: 'No Health Plan', value: 0, canineHP: 0, felineHp: 0 },
    { name: '50%', value: 1, canineHP: 30000, felineHp: 22800 },
    { name: '90%', value: 2, canineHP: 46800, felineHp: 34800 },
  ],

  PRICING_CATGORY_MAP: [
    { name: 'Covered on Health Plan', value: HP_COVERED },
    { name: 'Discounted on Health Plan', value: HP_DISCOUNTED },
    { name: 'Retail', value: RETAIL },
    { name: 'Elective', value: ELECTIVE },
  ],

  OFFICE_VISIT: {
    description: 'Office Visit',
    lowerRange: 50,
    upperRange: 0,
    pricingCategory: RETAIL,
    decline: false,
    notes: '',
  },
  NAIL_TRIM: [
    {
      description: 'Nail Trim',
      lowerRange: 25,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  ANAL_SAC_EXPRESSION: [
    {
      description: 'Anal Sac Expression',
      lowerRange: 25,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  FECAL: [
    {
      description: 'Fecal Float',
      lowerRange: 30,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  HWT: [
    {
      description: 'Heartworm Test',
      lowerRange: 40,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  TRIPLE: [
    {
      description: 'Feline Triple Test',
      lowerRange: 65,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  PYRANTEL: [
    {
      description: 'Pyrantel',
      lowerRange: 5,
      upperRange: 10,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  BORD: [
    {
      description: 'Bordetella Vaccine',
      lowerRange: 30,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  LEPTO: [
    {
      description: 'Lepto Vaccine',
      lowerRange: 30,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  DISTEMPER: [
    {
      description: 'DAPP Vaccine',
      lowerRange: 30,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  COMBO: [
    {
      description: 'DAPP/Lepto Combo Vaccine',
      lowerRange: 60,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  RABIES: [
    {
      description: 'Rabies Vaccine',
      lowerRange: 30,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  FVRCP: [
    {
      description: 'FVRCP Vaccine',
      lowerRange: 30,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  LEUKEMIA: [
    {
      description: 'Leukemia Vaccine',
      lowerRange: 40,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: true,
      notes: '',
    },
  ],
  INFLUENZA: [
    {
      description: 'Influenza Vaccine',
      lowerRange: 50,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],

  E_COLLAR: [eCollar],
  MICROCHIP: [microchip],
  DEWCLAW_1: [
    {
      description: 'Dewclaw Removal, Grade 1 (Elective)',
      lowerRange: 150,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  DEWCLAW_2: [
    {
      description: 'Dewclaw Removal, Grade 2 (Elective)',
      lowerRange: 250,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  GASTROPEXY: [
    {
      description: 'Gastropexy (Elective)',
      lowerRange: 600,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: `Preventive gastropexy is an elective surgery usually done at the time of spay or neuter in "at risk" breeds. The gastropexy tacks the stomach to the body wall, to prevent GDV (a bloated, twisted stomach) which is a fatal emergency.`,
    },
  ],
  SCROTAL_ABLATION: [
    {
      description: 'Scrotal Ablation (Elective)',
      lowerRange: 275,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: `Scrotal ablation is the removal of the scrotum during the time of castration/neuter. This procedure is commonly performed in adult or larger dogs at the time of the neuter. It drastically reduces the risk of the common post-op complication of a scrotal hematoma developing (scrotum filling with blood post-operatively). The incision heals better given this lack of extra tissue. This procedure has been recommended by your pet's doctor. The scrotal sac will shrink up a little following the neuter procedure without the scrotal ablation, but when your pet is larger at the time of his neuter, the larger scrotum will remain as loose skin. This decision can be discussed with your technician on the day of your pet's surgery.`,
    },
  ],
  PYOMETRA: [
    {
      description: 'Pyometra Surcharge',
      lowerRange: 325,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
  ],
  HERNIA: [
    {
      description: 'Umbilical Hernia Repair',
      lowerRange: 250,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
  ],
  ELECTIVE_EYELID_MR: [
    {
      description: 'Eyelid Mass Removal (Elective)',
      lowerRange: 80,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  ELECTIVE_LUMP_1: [
    {
      description: 'Lumpectomy, Grade 1 (Elective)',
      lowerRange: 100,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  ELECTIVE_LUMP_2: [
    {
      description: 'Lumpectomy, Grade 2 (Elective)',
      lowerRange: 180,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  ELECTIVE_LUMP_3: [
    {
      description: 'Lumpectomy, Grade 3 (Elective)',
      lowerRange: 275,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  ELECTIVE_LUMP_4: [
    {
      description: 'Lumpectomy, Grade 4 (Elective)',
      lowerRange: 415,
      upperRange: 0,
      pricingCategory: ELECTIVE,
      decline: true,
      notes: '',
    },
  ],
  ULTRASOUND: [
    {
      description: 'Ultrasound (Specialist)',
      lowerRange: 515,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    {
      description: 'Cytology by Imaging Specialist',
      lowerRange: 70,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: true,
      notes: 'Includes 1 site. $30 for each additional site',
    },
    {
      description: 'IDEXX Cytology (2811)',
      lowerRange: 150,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: true,
      notes: 'Includes 1 site',
    },
  ],
  URINALYSIS: [
    {
      description: 'Urinalysis w/ Collection',
      lowerRange: 110,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  CHEM_10: [
    {
      description: 'Chemistry analysis',
      lowerRange: 95,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  CHEM_15: [
    {
      description: 'Chemistry analysis',
      lowerRange: 120,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  CHEM_15_LYTES: [
    {
      description: 'Chemistry analysis w/ Lytes',
      lowerRange: 140,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  CBC: [
    {
      description: 'Complete Cell Count',
      lowerRange: 55,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  SDMA: [
    {
      description: 'SDMA',
      lowerRange: 50,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: `SDMA is an added blood test that gives us valuable information about your pet's kidney function. This test is highly recommended prior to any anesthesia procedure.`,
    },
  ],
  SEDATION: [
    {
      description: 'Sedation',
      lowerRange: 58,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  RAD_1: [
    {
      description: 'Radiographs (1 View)',
      lowerRange: 150,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  RAD_2: [
    {
      description: 'Radiographs (2 Views)',
      lowerRange: 150,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  RAD_3: [
    {
      description: 'Radiographs (3 Views)',
      lowerRange: 205,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  RAD_4: [
    {
      description: 'Radiographs (4 Views)',
      lowerRange: 260,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  RAD_5: [
    {
      description: 'Radiographs (5 Views)',
      lowerRange: 315,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],
  RAD_6: [
    {
      description: 'Radiographs (6 Views)',
      lowerRange: 370,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: true,
      notes: '',
    },
  ],

  DENTAL,
  DENTAL_AD_HOC,
  SPAY_NEUTER,
  GENERAL_ANESTHESIA,
  GENERAL_ANESTHESIA_AD_HOC,

  //euthanasia
  EUTHANASIA: [
    {
      description: 'Euthanasia',
      lowerRange: 95,
      upperRange: 0,
      pricingCategory: HP_COVERED,
      decline: false,
      notes: '',
    },
  ],

  CREMATION_COMM_UNDER_5: [
    {
      description: 'Communal Cremation',
      lowerRange: 49,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    ...cremationPackage,
  ],
  CREMATION_COMM_OVER_5: [
    {
      description: 'Communal Cremation',
      lowerRange: 95,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    ...cremationPackage,
  ],
  CREMATION_PRI_UNDER_3: [
    {
      description: 'Private Cremation',
      lowerRange: 99,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    {
      description: 'Urn Engraving',
      lowerRange: 28,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    ...cremationPackage,
  ],
  CREMATION_PRI_OVER_3: [
    {
      description: 'Private Cremation',
      lowerRange: 244,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    {
      description: 'Urn Engraving',
      lowerRange: 28,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    ...cremationPackage,
  ],
  BIOPSY: [
    {
      description: 'Biopsy (Idexx 2711)',
      lowerRange: 216,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: true,
      notes:
        'This is the price to send the removed tissue/mass to the laboratory for histopathology analysis. The specialists will perform a microscopic examination of the tissue to determine what type of mass/tumor it is and to determine if all of the abnormal cells were completely removed. This will help guide any future recommendations and treatment.',
    },
  ],
  FOREIGN_BODY_SX: [
    {
      description: 'Abdominal Explore/GI Foreign Body Package',
      lowerRange: 3900,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
  ],
  BROKEN_TOENAIL: [
    {
      description: 'Sedation/Chemical Restraint',
      lowerRange: 58,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
    {
      description: 'Wound Care',
      lowerRange: 35,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
    {
      description: 'Fractured Toe Nail Repair',
      lowerRange: 25,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
    {
      description: 'Bandage Application',
      lowerRange: 41,
      upperRange: 0,
      pricingCategory: HP_DISCOUNTED,
      decline: false,
      notes: '',
    },
    {
      description: 'Medications',
      lowerRange: 30,
      upperRange: 70,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
    eCollar,
  ],
  TPLO_90: [
    {
      description: 'TPLO < 90lbs Unilateral Package',
      lowerRange: 3100,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_90_120: [
    {
      description: 'TPLO 90-120lbs Unilateral Package',
      lowerRange: 3200,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_120_150: [
    {
      description: 'TPLO 120-150lbs Unilateral Package',
      lowerRange: 3300,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_150: [
    {
      description: 'TPLO >150lbs Unilateral Package',
      lowerRange: 3500,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_90_BI: [
    {
      description: 'TPLO < 90lbs Bilateral Package',
      lowerRange: 5100,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_90_120_BI: [
    {
      description: 'TPLO 90-120lbs Bilateral Package',
      lowerRange: 5300,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_120_150_BI: [
    {
      description: 'TPLO 120-150lbs Bilateral Package',
      lowerRange: 5500,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],
  TPLO_150_BI: [
    {
      description: 'TPLO >150lbs Bilateral Package',
      lowerRange: 5900,
      upperRange: 0,
      pricingCategory: RETAIL,
      decline: false,
      notes: '',
    },
  ],

  //status ids
  DRAFT: 1,
  LOCKED: 2,

  SURGERY_TYPE_MAP: [
    { value: 0, name: 'Select Type' },
    { value: 1, name: 'Dental', title: 'Dental Procedure' },
    { value: 2, name: 'Spay', title: 'Spay' },
    { value: 3, name: 'Neuter', title: 'Neuter' },
    { value: 4, name: 'General Anesthesia', title: 'General Anesthesia' },
  ],
};

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Input from '../../common/input';

import { formatCurrency } from '../../../util';

function RetailComparison() {
  const [results, setResults] = useState(null);

  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').tz('America/Denver').format('YYYY-MM-DD')
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getRetailComparisonStats = async () => {
      try {
        setIsLoading(true);
        const response = await http.post(`/report/getRetailComparisonStats`, {
          startDate,
          endDate,
        });
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getRetailComparisonStats();
  }, [startDate, endDate]);

  if (isLoading || !results) return <i className="mt-3 fa fa-circle-notch fa-spin subtle" />;
  if (errorMessage) return <p className="error">{errorMessage}</p>;

  return (
    <React.Fragment>
      <div className="d-flex mt-2">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Previous Month
        </button>
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .add(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .add(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Next Month
        </button>
      </div>
      <div className="mt-4">
        <table className="ms-4">
          <thead>
            <tr>
              <th style={{ width: '200px' }}></th>
              <th style={{ width: '100px' }}>Loveland</th>
              <th style={{ width: '100px' }}>Evans</th>
              <th style={{ width: '100px' }}>Total</th>
            </tr>
          </thead>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Total</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Retail Value</td>
              <td>{formatCurrency(results[1].retailValue)}</td>
              <td>{formatCurrency(results[2].retailValue)}</td>
              <td>{formatCurrency(results[1].retailValue + results[2].retailValue)}</td>
            </tr>
            <tr>
              <td className="ps-3">Discounts</td>
              <td>{formatCurrency(results[1].discounts)}</td>
              <td>{formatCurrency(results[2].discounts)}</td>
              <td>{formatCurrency(results[1].discounts + results[2].discounts)}</td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan Revenue</td>
              <td>{formatCurrency(results[1].healthPlanRevenue)}</td>
              <td>{formatCurrency(results[2].healthPlanRevenue)}</td>
              <td>{formatCurrency(results[1].healthPlanRevenue + results[2].healthPlanRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-3">Difference from Retail</td>
              <td>{formatCurrency(results[1].diffFromRetail)}</td>
              <td>{formatCurrency(results[2].diffFromRetail)}</td>
              <td>{formatCurrency(results[1].diffFromRetail + results[2].diffFromRetail)}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>No Health Plan</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Retail Value</td>
              <td>{formatCurrency(results[1].retailValueNoHP)}</td>
              <td>{formatCurrency(results[2].retailValueNoHP)}</td>
              <td>{formatCurrency(results[1].retailValueNoHP + results[2].retailValueNoHP)}</td>
            </tr>
            <tr>
              <td className="ps-3">Discounts</td>
              <td>{formatCurrency(results[1].discountsNoHP)}</td>
              <td>{formatCurrency(results[2].discountsNoHP)}</td>
              <td>{formatCurrency(results[1].discountsNoHP + results[2].discountsNoHP)}</td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan Revenue</td>
              <td>{formatCurrency(results[1].healthPlanRevenueNoHP)}</td>
              <td>{formatCurrency(results[2].healthPlanRevenueNoHP)}</td>
              <td>
                {formatCurrency(
                  results[1].healthPlanRevenueNoHP + results[2].healthPlanRevenueNoHP
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Difference from Retail</td>
              <td>{formatCurrency(results[1].diffFromRetailNoHP)}</td>
              <td>{formatCurrency(results[2].diffFromRetailNoHP)}</td>
              <td>
                {formatCurrency(results[1].diffFromRetailNoHP + results[2].diffFromRetailNoHP)}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>50% Health Plan</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Retail Value</td>
              <td>{formatCurrency(results[1].retailValue50HP)}</td>
              <td>{formatCurrency(results[2].retailValue50HP)}</td>
              <td>{formatCurrency(results[1].retailValue50HP + results[2].retailValue50HP)}</td>
            </tr>
            <tr>
              <td className="ps-3">Discounts</td>
              <td>{formatCurrency(results[1].discounts50HP)}</td>
              <td>{formatCurrency(results[2].discounts50HP)}</td>
              <td>{formatCurrency(results[1].discounts50HP + results[2].discounts50HP)}</td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan Revenue</td>
              <td>{formatCurrency(results[1].healthPlanRevenue50HP)}</td>
              <td>{formatCurrency(results[2].healthPlanRevenue50HP)}</td>
              <td>
                {formatCurrency(
                  results[1].healthPlanRevenue50HP + results[2].healthPlanRevenue50HP
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Difference from Retail</td>
              <td>{formatCurrency(results[1].diffFromRetail50HP)}</td>
              <td>{formatCurrency(results[2].diffFromRetail50HP)}</td>
              <td>
                {formatCurrency(results[1].diffFromRetail50HP + results[2].diffFromRetail50HP)}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>90% Health Plan</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Retail Value</td>
              <td>{formatCurrency(results[1].retailValue90HP)}</td>
              <td>{formatCurrency(results[2].retailValue90HP)}</td>
              <td>{formatCurrency(results[1].retailValue90HP + results[2].retailValue90HP)}</td>
            </tr>
            <tr>
              <td className="ps-3">Discounts</td>
              <td>{formatCurrency(results[1].discounts90HP)}</td>
              <td>{formatCurrency(results[2].discounts90HP)}</td>
              <td>{formatCurrency(results[1].discounts90HP + results[2].discounts90HP)}</td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan Revenue</td>
              <td>{formatCurrency(results[1].healthPlanRevenue90HP)}</td>
              <td>{formatCurrency(results[2].healthPlanRevenue90HP)}</td>
              <td>
                {formatCurrency(
                  results[1].healthPlanRevenue90HP + results[2].healthPlanRevenue90HP
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Difference from Retail</td>
              <td>{formatCurrency(results[1].diffFromRetail90HP)}</td>
              <td>{formatCurrency(results[2].diffFromRetail90HP)}</td>
              <td>
                {formatCurrency(results[1].diffFromRetail90HP + results[2].diffFromRetail90HP)}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Employee Health Plan</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Retail Value</td>
              <td>{formatCurrency(results[1].retailValueEmployeeHP)}</td>
              <td>{formatCurrency(results[2].retailValueEmployeeHP)}</td>
              <td>
                {formatCurrency(
                  results[1].retailValueEmployeeHP + results[2].retailValueEmployeeHP
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Discounts</td>
              <td>{formatCurrency(results[1].discountsEmployeeHP)}</td>
              <td>{formatCurrency(results[2].discountsEmployeeHP)}</td>
              <td>
                {formatCurrency(results[1].discountsEmployeeHP + results[2].discountsEmployeeHP)}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan Revenue</td>
              <td>{formatCurrency(results[1].healthPlanRevenueEmployeeHP)}</td>
              <td>{formatCurrency(results[2].healthPlanRevenueEmployeeHP)}</td>
              <td>
                {formatCurrency(
                  results[1].healthPlanRevenueEmployeeHP + results[2].healthPlanRevenueEmployeeHP
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Difference from Retail</td>
              <td>{formatCurrency(results[1].diffFromRetailEmployeeHP)}</td>
              <td>{formatCurrency(results[2].diffFromRetailEmployeeHP)}</td>
              <td>
                {formatCurrency(
                  results[1].diffFromRetailEmployeeHP + results[2].diffFromRetailEmployeeHP
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default RetailComparison;

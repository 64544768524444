import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';

function ApplyWaitingPeriod({ petId, handleClose }) {
  const [waitingPeriodEndDate, setWaitingPeriodEndDate] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = {
      petId,
      waitingPeriodEndDate,
    };

    await http.post('/pet/applyWaitingPeriod', params);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error input-width">{errorMessage}</p>}
        <Input
          className="mt-2"
          type="date"
          name="waitingPeriodEndDate"
          label="Waiting Period End Date"
          value={waitingPeriodEndDate}
          onChange={setWaitingPeriodEndDate}
        />
        <span className="input-width">
          *Note that this date is the final day of the Waiting Period. Ex: If the Waiting Period End
          Date for a pet is set to 6/4/2020, the pet will not be eligible to sign up for a new
          Health Plan until 6/5/2020.
        </span>
        <ButtonPanel
          primaryButtonText="Submit"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default ApplyWaitingPeriod;

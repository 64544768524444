import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import AppointmentConstants from '../../appointmentConstants';

function PetAppointments({ pet }) {
  const [appointments, setAppointments] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAppointments = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/pet/getAppointments?pet_id=${pet.id}`);
        setAppointments(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };

    if (pet.id) {
      getAppointments();
    }
  }, [pet]);

  return (
    <div className="background-white p-3 mt-3">
      {errorMessage && <p className="error flex-centered h-100 background-white">{errorMessage}</p>}
      {isLoading && (
        <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle mt-5" />
      )}
      {!isLoading && (
        <React.Fragment>
          <h2 className="my-4 ms-2">Appointments</h2>
          <table>
            <thead>
              <tr>
                <th style={{ width: '100px' }}>Date</th>
                <th style={{ width: '200px' }}>Compaint</th>
                <th>Notes</th>
                <th style={{ width: '100px' }}>Status</th>
                <th style={{ width: '100px' }}>Location</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map(t => {
                return (
                  <tr
                    key={t.id}
                    className={`border-bottom ${
                      Number(t.appointment_status_id) === AppointmentConstants.CANCELLED
                        ? 'gray'
                        : ''
                    }`}
                    style={{ verticalAlign: 'top' }}
                  >
                    <td>
                      {moment.tz(t.start, 'America/Denver').format('MMM DD, YYYY')}
                      <br />
                      {moment.tz(t.start, 'America/Denver').format('hh:mm A')}
                    </td>
                    <td>{t.complaint}</td>
                    <td>{t.notes}</td>
                    <td>{t.appointment_status}</td>
                    <td>{t.location}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      )}
    </div>
  );
}

export default PetAppointments;

import React from 'react';

import ConsentForm from './consentForm';

import Constants from '../../constants';

function AuthorizationForm() {
  return (
    <ConsentForm
      documentTypeId={Constants.AUTHORIZATION_FORM}
      header="Authorization to Treat"
      body={
        <React.Fragment>
          <p className="mt-5 sura">{Constants.AUTH_STATEMENT_1}</p>
          <p className="mt-4 sura">{Constants.AUTH_STATEMENT_2}</p>
          <p className="mt-4 sura">{Constants.AUTH_STATEMENT_3}</p>
          <p className="mt-4 sura">{Constants.AUTH_STATEMENT_4}</p>
        </React.Fragment>
      }
    />
  );
}

export default AuthorizationForm;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';

function InventoryModal({ inventory, locationId, handleClose }) {
  const isLoveland = Number(locationId) === 1;

  const [orderPoint, setOrderPoint] = useState(
    isLoveland ? inventory.order_point_loveland || '' : inventory.order_point_evans || ''
  );
  const [noticeBeforeExpiration, setNoticeBeforeExpiration] = useState(
    inventory.notice_before_expiration || ''
  );

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await http.post('/inventory/update', {
        id: inventory.id,
        orderPoint: orderPoint || null,
        noticeBeforeExpiration: noticeBeforeExpiration || null,
        locationId,
      });
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>

        <div className="d-flex input-width">
          <Input
            name="orderPoint"
            type="number"
            value={orderPoint}
            label="Order Point"
            onChange={setOrderPoint}
          />
          <span className="align-self-center ms-2 sura">{inventory.unit_of_measure}</span>
        </div>

        <div className="d-flex input-width">
          <Input
            name="noticeBeforeExpiration"
            type="number"
            value={noticeBeforeExpiration}
            label="Notice Before Expiration"
            onChange={setNoticeBeforeExpiration}
          />
          <span className="align-self-center ms-2 sura">days</span>
        </div>

        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
        />
      </form>
    </Modal>
  );
}

export default InventoryModal;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import ButtonPanel from '../common/buttonPanel';

function Confirmation({ message, handleConfirm, handleClose }) {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Modal show={true} onHide={handleClose} size="sm" centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <p className="sura font-16">{message}</p>
        <ButtonPanel
          primaryButtonText="Confirm"
          handleCancel={handleClose}
          handleSubmit={handleConfirm}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default Confirmation;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputNumber from '../common/inputNumber';
import InputSelect from '../common/inputSelect';

import Constants from '../../constants';

import '../../styles/components/user-details.scss';

function EmployeeModal({ employee, handleClose }) {
  const [firstName, setFirstName] = useState(employee ? employee.first_name : '');
  const [lastName, setLastName] = useState(employee ? employee.last_name : '');
  const [email, setEmail] = useState(employee ? employee.email || '' : '');
  const [roleId, setRoleId] = useState(employee ? employee.role_id : Constants.EMPLOYEE);
  const [isHourly, setIsHourly] = useState(employee ? employee.is_hourly : 'Y');
  const [setHours, setSetHours] = useState(employee ? employee.set_hours || 0 : 0);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');

    const params = { firstName, lastName, email, roleId, isHourly };

    if (isHourly === 'Y') {
      params.setHours = setHours;
    }

    try {
      if (employee) {
        params.employeeId = employee.id;
        await http.put('/employee/update', params);
      } else {
        await http.post('/employee/create', params);
      }
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form className="background-gray box-shadow-bottom p-5 rounded-bottom">
        {errorMessage && <p className="error align-self-start">{errorMessage}</p>}
        <h1 className="mb-4">Employee</h1>
        <Input name="firstName" value={firstName} label="First Name" onChange={setFirstName} />
        <Input name="lastName" value={lastName} label="Last Name" onChange={setLastName} />
        <Input name="email" type="email" value={email} label="Email" onChange={setEmail} />
        <InputSelect
          name="roleId"
          value={roleId}
          label="Role"
          optionConfig={[
            { name: 'Employee', value: Constants.EMPLOYEE },
            { name: 'Manager', value: Constants.MANAGER },
          ]}
          onChange={setRoleId}
        />
        <InputSelect
          name="isHourly"
          value={isHourly}
          label="Is Hourly"
          optionConfig={[
            { name: 'Yes', value: 'Y' },
            { name: 'No', value: 'N' },
          ]}
          onChange={setIsHourly}
        />
        {isHourly === 'Y' && (
          <InputNumber name="setHours" label="Set Hours" value={setHours} onChange={setSetHours} />
        )}
        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={() => handleSubmit(false)}
          turnOffProcessing={true}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default EmployeeModal;

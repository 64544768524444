import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useDebounce } from 'use-lodash-debounce';

import http from '../../services/httpService';

import ClientModal from '../client/clientModal';

import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import { Desktop, NotDesktop, NotMobile } from '../common/responsive';
import Page from '../common/page';

import '../../styles/components/search.scss';

function Home() {
  const [firstName, setFirstName] = useState('');
  const firstNameDebounced = useDebounce(firstName, 500);
  const [lastName, setLastName] = useState('');
  const lastNameDebounced = useDebounce(lastName, 500);
  const [petName, setPetName] = useState('');
  const petNameDebounced = useDebounce(petName, 500);
  const [email, setEmail] = useState('');
  const emailDebounced = useDebounce(email, 500);
  const [daysmartClientId, setDaysmartClientId] = useState('');
  const daysmartClientIdDebounced = useDebounce(daysmartClientId, 500);
  const [daysmartPetId, setDaysmartPetId] = useState('');
  const daysmartPetIdDebounced = useDebounce(daysmartPetId, 500);

  const [exactMatch, setExactMatch] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showClientModal, setShowClientModal] = useState(false);

  useEffect(() => {
    const getSearchResults = async () => {
      try {
        setIsLoading(true);
        const { data: results } = await http.get(
          `/client/search?first_name=${firstNameDebounced}&last_name=${lastNameDebounced}` +
            `&daysmart_client_id=${daysmartClientIdDebounced}&email=${emailDebounced}&pet_name=${petNameDebounced}` +
            `&daysmart_pet_id=${daysmartPetIdDebounced}&exact_match=${exactMatch ? 'Y' : 'N'}`
        );
        setSearchResults(results);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getSearchResults();
  }, [
    firstNameDebounced,
    lastNameDebounced,
    petNameDebounced,
    emailDebounced,
    daysmartClientIdDebounced,
    daysmartPetIdDebounced,
    exactMatch,
  ]);

  return (
    <Page selectedTab="home">
      <div className="search-fields box-shadow-bottom border-bottom">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <button className="btn-text-primary mb-4" onClick={() => setShowClientModal(true)}>
          <Icon name="add" />
          New Client
        </button>
        <h2 className="mb-4">Search</h2>
        <NotDesktop>
          <div className="d-flex flex-column align-items-center">
            <Input
              name="daysmartClientId"
              value={daysmartClientId}
              label="Daysmart Client Id"
              onChange={setDaysmartClientId}
              autoFocus={true}
            />
            <Input
              name="firstName"
              value={firstName}
              label="Client First Name"
              onChange={setFirstName}
            />
            <Input
              name="lastName"
              value={lastName}
              label="Client Last Name"
              onChange={setLastName}
            />
            <Input
              name="email"
              type="email"
              value={email}
              label="Client Email"
              onChange={setEmail}
            />
            <Input name="petName" value={petName} label="Pet Name" onChange={setPetName} />
            <Input
              name="daysmartPetId"
              value={daysmartPetId}
              label="Daysmart Pet Id"
              onChange={setDaysmartPetId}
            />
          </div>
        </NotDesktop>
        <Desktop>
          <div className="d-flex">
            <Input
              className="me-4"
              name="daysmartClientId"
              value={daysmartClientId}
              label="Daysmart Client Id"
              onChange={setDaysmartClientId}
              autoFocus={true}
            />
            <Input
              className="me-4"
              name="firstName"
              value={firstName}
              label="Client First Name"
              onChange={setFirstName}
            />
            <Input
              name="lastName"
              value={lastName}
              label="Client Last Name"
              onChange={setLastName}
            />
          </div>
          <div className="d-flex">
            <Input
              className="me-4"
              name="daysmartPetId"
              value={daysmartPetId}
              label="Daysmart Pet Id"
              onChange={setDaysmartPetId}
            />
            <Input
              className="me-4"
              name="petName"
              value={petName}
              label="Pet Name"
              onChange={setPetName}
            />
            <Input
              name="email"
              type="email"
              value={email}
              label="Client Email"
              onChange={setEmail}
            />
          </div>
        </Desktop>
        <InputCheckbox
          className="mt-3"
          name="exactMatch"
          label="Exact Match"
          checked={exactMatch}
          onChange={setExactMatch}
        />
      </div>
      <div className="search-results">
        {isLoading && <i className="flex-centered fa fa-circle-notch fa-spin fa-3x mt-5 subtle" />}
        {!isLoading && (
          <React.Fragment>
            {searchResults.length === 0 && (
              <div className="no-result">No Matching Search Results to Display</div>
            )}
            {searchResults.map((result, i) => (
              <div key={i} className="search-result d-flex flex-column">
                <Link to={{ pathname: `/client/${result.id}` }} className="client-result">
                  <span>
                    {result.last_name}, {result.first_name}
                  </span>
                  <span>Daysmart Id: {result.daysmart_client_id}</span>
                  <NotMobile>
                    <span>{result.email}</span>
                  </NotMobile>
                </Link>
                {result.pets.map(pet => (
                  <Link
                    to={{ pathname: `/client/${result.id}/pet/${pet.id}` }}
                    key={pet.id}
                    className="pet-result"
                  >
                    <span>{pet.name}</span>
                    <span>Daysmart Id: {pet.daysmart_pet_id}</span>
                    <NotMobile>
                      <span>{pet.species}</span>
                      <span>{pet.healthPlan}</span>
                      <span>{pet.healthPlanStatus}</span>
                    </NotMobile>
                  </Link>
                ))}
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
      {showClientModal && <ClientModal handleClose={() => setShowClientModal(false)} />}
    </Page>
  );
}

export default Home;

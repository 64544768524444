import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import PaymentMethodWidget from '../payment-method/paymentMethodWidget';

import ButtonPanel from '../common/buttonPanel';
import InputEitherOr from '../common/inputEitherOr';

import Constants from '../../constants';

function AnnualToMonthly({ contract, handleClose }) {
  const [percentageOffId, setPercentageOffId] = useState(Number(contract.percentage_off_id));
  const [automaticPaymentMethod, setAutomaticPaymentMethod] = useState({
    isStored: 'Y',
    isPreview: 'N',
  });

  const [currentStep, setCurrentStep] = useState('planDetails');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');
    await http.post('/healthPlan/annualToMonthly', {
      contractId: contract.id,
      percentageOffId,
      automaticPaymentMethodId: automaticPaymentMethod.id,
    });
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <React.Fragment>
          {currentStep === 'planDetails' && (
            <React.Fragment>
              <h2 className="mb-4">New Plan Details:</h2>
              <InputEitherOr
                name="percentageOffId"
                label="Health Plan Type"
                value={percentageOffId}
                option1={{ name: '50%', value: Constants.FIFTY_PERCENT }}
                option2={{ name: '90%', value: Constants.NINETY_PERCENT }}
                onChange={({ target }) => setPercentageOffId(Number(target.value))}
              />
              <ButtonPanel
                primaryButtonText="Next"
                handleCancel={handleClose}
                handleSubmit={() => setCurrentStep('automaticPaymentMethod')}
                handleErrorMessage={setErrorMessage}
              />
            </React.Fragment>
          )}

          {currentStep === 'automaticPaymentMethod' && (
            <React.Fragment>
              <h2 className="mb-4">Monthly Payments:</h2>
              <PaymentMethodWidget
                selectedPaymentMethod={automaticPaymentMethod}
                labelText={[
                  <span key={0}>
                    Select <strong>automatic</strong> payment method:
                  </span>,
                ]}
                displayStoredOnly={true}
                clientId={contract.client_id}
                handleErrorMessage={setErrorMessage}
                handlePaymentMethodChange={setAutomaticPaymentMethod}
              />
              <ButtonPanel
                primaryButtonText="Process"
                handleCancel={handleClose}
                handleBack={() => setCurrentStep('planDetails')}
                handleSubmit={handleSubmit}
                handleErrorMessage={setErrorMessage}
                disabled={!automaticPaymentMethod.id}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </form>
    </Modal>
  );
}

export default AnnualToMonthly;

import React, { useState } from 'react';
import moment from 'moment-timezone';

import Input from '../../common/input';

import http from '../../../services/httpService';

function HealthPlanRevenueVsRetail() {
  const [message, setMessage] = useState('');

  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      await http.post(`/report/getHealthPlanRevenueVsRetailReport`, { startDate, endDate });
      setMessage('Report will be emailed as soon as it is ready.');
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
      </div>
      <button
        className="my-3 me-5 btn-filled-primary input-width"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Run
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {isLoading && (
        <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
      )}
      {!isLoading && <p className="sura">{message}</p>}
    </React.Fragment>
  );
}

export default HealthPlanRevenueVsRetail;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import InputCheckbox from '../common/inputCheckbox';

import EstimateConstants from '../../estimateConstants';

function SurgeryEstimateFinderModal({ petId, appointmentId, handleClose }) {
  const [estimates, setEstimates] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getPetEstimates = async () => {
      const response = await http.get(`/estimate/getByPetId?pet_id=${petId}`);

      const estimates = response.data
        .filter(t => !t.appointment_id)
        .map(t => {
          t.attach = false;
          return t;
        });

      setEstimates(estimates);
    };
    if (petId) {
      getPetEstimates();
    }
  }, [petId]);

  const handleSubmit = async () => {
    const idsToAttach = estimates.filter(t => t.attach).map(t => t.id);
    await http.post('/estimate/attach', { appointmentId, estimateIds: idsToAttach });
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {estimates.length === 0 && (
          <p className="sura font-16 mb-4">
            This pet does not have any eligible estimates to attach.
          </p>
        )}
        {estimates.length > 0 && (
          <React.Fragment>
            <div className="p-2">
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th></th>
                    <th>Title</th>
                    <th>Health Plan</th>
                    <th>Creation Date</th>
                  </tr>
                </thead>
                <tbody>
                  {estimates.map((t, i) => {
                    const info = JSON.parse(t.info);
                    const isHpComparison = Number(info.type) === EstimateConstants.HP_COMPARISON;
                    return (
                      <tr key={i}>
                        <td>
                          <InputCheckbox
                            className="mt-3"
                            name={`attach_${i}`}
                            checked={estimates[i].attach}
                            onChange={value => {
                              const updatedEstimates = [...estimates];
                              updatedEstimates[i].attach = value;
                              setEstimates(updatedEstimates);
                            }}
                          />
                        </td>
                        <td>{info.title}</td>
                        <td>{isHpComparison ? 'HP Comparison' : 'Treatment Plan'}</td>
                        <td>{moment(t.insert_ts).tz('America/Denver').format('MM-DD-YYYY')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <ButtonPanel
              primaryButtonText="Attach"
              handleCancel={handleClose}
              handleSubmit={handleSubmit}
              disabled={estimates.filter(t => t.attach).length === 0}
            />
          </React.Fragment>
        )}
      </form>
    </Modal>
  );
}

export default SurgeryEstimateFinderModal;

import React, { useState } from 'react';

import InputSelect from '../../common/inputSelect';
import Page from '../../common/page';

import Appts from './appts';
import Cash from './cash';
import HealthPlanRevenueVsRetail from './healthPlanRevenueVsRetail';
import NewClients from './newClients';
import NewPets from './newPets';
import NoParamReport from './noParamReport';
import Rabies from './rabies';
import ScheduleStats from './scheduleStats';

const REPORT_TYPE_MAP = [
  { value: 'APPTS', name: 'Appointments' },
  { value: 'NEW_CLIENTS', name: 'New Clients' },
  { value: 'NEW_PETS', name: 'New Pets' },
  { value: 'ACTIVE_PET', name: 'Active Pets' },
  { value: 'RABIES', name: 'Rabies' },
  { value: 'INVALID_INVOICE_STATUS', name: 'Invalid Invoice Status' },
  { value: 'HP_REV_VS_RETAIL', name: 'HP Revenue vs. Retail' },
  { value: 'SCHEDULE_STATS', name: 'Schedule Stats' },
  { value: 'CASH', name: 'Cash' },
  { value: 'CLIENT_RECON', name: 'Client Reconciliation' },
  { value: 'PET_RECON', name: 'Pet Reconciliation' },
  { value: 'APPT_RECON', name: 'Appointment Reconciliation' },
  { value: 'INVOICE_RECON', name: 'Invoice Reconciliation' },
  { value: 'INVENTORY_RECON', name: 'Inventory Reconciliation' },
  { value: 'REMINDER_RECON', name: 'Reminder Reconciliation' },
];

function Reports() {
  const [reportType, setReportType] = useState('APPTS');

  return (
    <Page selectedTab="reports">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1 className="pb-4">Reports</h1>
        <InputSelect
          name="reportType"
          value={reportType}
          label="Report Type"
          optionConfig={REPORT_TYPE_MAP}
          onChange={setReportType}
        />
        {reportType === 'APPTS' && <Appts />}
        {reportType === 'NEW_CLIENTS' && <NewClients />}
        {reportType === 'NEW_PETS' && <NewPets />}
        {reportType === 'ACTIVE_PET' && <NoParamReport endpoint="getActivePetReport" />}
        {reportType === 'RABIES' && <Rabies endpoint="getRabiesReport" />}
        {reportType === 'INVALID_INVOICE_STATUS' && (
          <NoParamReport endpoint="getInvalidInvoiceStatusReport" />
        )}
        {reportType === 'HP_REV_VS_RETAIL' && <HealthPlanRevenueVsRetail />}
        {reportType === 'SCHEDULE_STATS' && <ScheduleStats />}
        {reportType === 'CASH' && <Cash />}
        {reportType === 'CLIENT_RECON' && <NoParamReport endpoint="getClientReconReport" />}
        {reportType === 'PET_RECON' && <NoParamReport endpoint="getPetReconReport" />}
        {reportType === 'APPT_RECON' && <NoParamReport endpoint="getApptReconReport" />}
        {reportType === 'INVOICE_RECON' && <NoParamReport endpoint="getInvoiceReconReport" />}
        {reportType === 'INVENTORY_RECON' && <NoParamReport endpoint="getInventoryReconReport" />}
        {reportType === 'REMINDER_RECON' && <NoParamReport endpoint="getReminderReconReport" />}
      </div>
    </Page>
  );
}

export default Reports;

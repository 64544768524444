import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

function Header({ form, showPetDetails, handleIsLoading, handleOwnerChange }) {
  const [info, setInfo] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getHeaderInfo = async () => {
      try {
        setIsLoading(true);
        handleIsLoading(true);
        setErrorMessage('');

        const { data: info } = await http.get(
          form.pet_id
            ? `/forms/getHeaderInfo?client_id=${form.client_id}&pet_id=${form.pet_id}`
            : `/forms/getHeaderInfo?client_id=${form.client_id}`
        );

        setInfo(info);
        handleOwnerChange(info.ownerName);

        setIsLoading(false);
        handleIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
        handleIsLoading(false);
      }
    };
    if (form) {
      getHeaderInfo();
    }
  }, [form, handleIsLoading, handleOwnerChange]);

  if (!form || isLoading) return null;

  return (
    <div>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <table className="mt-4 static-info-table">
        <tbody>
          <tr>
            <td>Date</td>
            <td>{moment().tz('America/Denver').format('MM-DD-YYYY')}</td>
          </tr>
        </tbody>
      </table>
      <table className="mt-4 static-info-table">
        <tbody>
          <tr>
            <td>Owner</td>
            <td>{info.ownerName}</td>
          </tr>
          <tr>
            <td>Mobile Phone</td>
            <td>{info.cell}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{info.email}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{info.address}</td>
          </tr>
        </tbody>
      </table>
      {form.pet_id && (
        <table className="mt-4 static-info-table">
          <tbody>
            <tr>
              <td>Pet</td>
              <td>{info.patientName}</td>
            </tr>
            {showPetDetails && (
              <React.Fragment>
                <tr>
                  <td>Species</td>
                  <td>{info.species}</td>
                </tr>
                <tr>
                  <td>Breed</td>
                  <td>{info.breed}</td>
                </tr>
                <tr>
                  <td>Sex</td>
                  <td>{info.sex}</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>{info.age}</td>
                </tr>
                <tr>
                  <td>Color</td>
                  <td>{info.color}</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Header;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Input from '../common/input';
import InputSelect from '../common/inputSelect';

const TEMPLATE_TYPE_MAP = [
  { value: 'DR_SURGERY', name: 'Doctor Surgery' },
  { value: 'DR_ROOMS', name: 'Doctor Rooms' },
  { value: 'DR_SATURDAY', name: 'Doctor Saturday' },
  { value: 'DR_OFF', name: 'Doctor Off' },
  { value: 'TECH', name: 'Techs' },
];

function ScheduleTemplater({ handleClose }) {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [templateType, setTemplateType] = useState('DR_SURGERY');
  const [scheduleColumn, setScheduleColumn] = useState('Kaiser');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      await http.post(`/schedule/generateTemplate`, { date, templateType, scheduleColumn });
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <div className="p-4 background-gray box-shadow rounded-bottom d-flex flex-column">
        <h1 className="pb-4">Schedule Templater</h1>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <Input name="date" type="date" value={date} label="Date" onChange={setDate} />
        <InputSelect
          name="templateType"
          value={templateType}
          label="Template Type"
          optionConfig={TEMPLATE_TYPE_MAP}
          onChange={setTemplateType}
        />
        <InputSelect
          name="scheduleColumn"
          value={scheduleColumn}
          label="Schedule Column"
          optionConfig={[
            'Kaiser',
            'White',
            'Relief DVM',
            'Tech Appts',
            'Overflow',
            'Meetings/Other',
          ]}
          rawOptions={true}
          onChange={setScheduleColumn}
        />
        <button
          className="btn-filled-primary input-width mt-3"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Generate
        </button>
        {isLoading && (
          <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
        )}
      </div>
    </Modal>
  );
}

export default ScheduleTemplater;

import React, { useState, useEffect } from 'react';

import http from '../../../services/httpService';

import { formatCurrency } from '../../../util';

function HealthPlanStats() {
  const [results, setResults] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getHealthPlanStats = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/report/getHealthPlanStats`);
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getHealthPlanStats();
  }, []);

  if (isLoading || !results) return <i className="mt-3 fa fa-circle-notch fa-spin subtle" />;
  if (errorMessage) return <p className="error">{errorMessage}</p>;

  return (
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Count</th>
            <th>Percentage</th>
            <th>MRR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Client</td>
            <td>{results.clientCount}</td>
            <td></td>
            <td>{formatCurrency(results.clientMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">50% Canine</td>
            <td>{results.fiftyCanineCount}</td>
            <td>{((results.fiftyCanineCount / results.clientCount) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(results.fiftyCanineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">50% Feline</td>
            <td>{results.fiftyFelineCount}</td>
            <td>{((results.fiftyFelineCount / results.clientCount) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(results.fiftyFelineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90% Canine</td>
            <td>{results.ninetyCanineCount}</td>
            <td>{((results.ninetyCanineCount / results.clientCount) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(results.ninetyCanineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90% Feline</td>
            <td>{results.ninetyFelineCount}</td>
            <td>{((results.ninetyFelineCount / results.clientCount) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(results.ninetyFelineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4 pt-4">50%</td>
            <td className="pt-4">{results.fiftyCount}</td>
            <td className="pt-4">
              {((results.fiftyCount / results.clientCount) * 100).toFixed(2)}%
            </td>
            <td className="pt-4">{formatCurrency(results.fiftyMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90%</td>
            <td>{results.ninetyCount}</td>
            <td>{((results.ninetyCount / results.clientCount) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(results.ninetyMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4 pt-4">Canine</td>
            <td className="pt-4">{results.canineCount}</td>
            <td className="pt-4">
              {((results.canineCount / results.clientCount) * 100).toFixed(2)}%
            </td>
            <td className="pt-4">{formatCurrency(results.canineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">Feline</td>
            <td>{results.felineCount}</td>
            <td>{((results.felineCount / results.clientCount) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(results.felineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4 pt-4">Plans in 1st Year</td>
            <td className="pt-4">{results.newPlanCount}</td>
            <td className="pt-4">
              {((results.newPlanCount / results.clientCount) * 100).toFixed(2)}%
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="pt-4">Employee</td>
            <td className="pt-4">{results.employeeCount}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="pt-4">Total</td>
            <td className="pt-4">{results.totalCount}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className="mt-4 sura font-14">
        Average Plan Payment: {formatCurrency(results.clientMRR / results.clientCount)}
      </div>
    </React.Fragment>
  );
}

export default HealthPlanStats;

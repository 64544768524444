import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TooltipWrapper({ tooltipText, element }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip style={{ position: 'fixed' }}>{tooltipText}</Tooltip>}
      trigger={['click', 'hover', 'focus']}
    >
      {element}
    </OverlayTrigger>
  );
}

export default TooltipWrapper;

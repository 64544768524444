import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ContractPreview from './contractPreview';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputEitherOr from '../common/inputEitherOr';

import Constants from '../../constants';

function InitiateHealthPlan({ pet, allowPaymentPlans, handleClose }) {
  const [percentageOff, setPercentageOff] = useState(Constants.FIFTY_PERCENT);
  const [paymentInterval, setPaymentInterval] = useState(
    allowPaymentPlans ? Constants.MONTHLY : Constants.ANNUAL
  );
  const [isMonthly, setIsMonthly] = useState(allowPaymentPlans);
  const [startDate, setStartDate] = useState(moment().tz('America/Denver').format('YYYY-MM-DD'));
  const [billingDay, setBillingDay] = useState(moment().tz('America/Denver').format('DD'));
  const [downPayment, setDownPayment] = useState(true);
  const [previewInfo, setPreviewInfo] = useState({});

  const [currentStep, setCurrentStep] = useState('planDetails');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));
  const isManager = Number(loggedInUser.role_id) === Constants.MANAGER;

  const handleStartDateChange = value => {
    setStartDate(value);
    setBillingDay(Number(value.slice(-2)));
  };

  const handlePaymentIntervalChange = ({ target }) => {
    const value = Number(target.value);
    const updatedIsMonthly = value === Number(Constants.MONTHLY);

    setPaymentInterval(value);
    setIsMonthly(updatedIsMonthly);
    setDownPayment(updatedIsMonthly);
    setBillingDay(Number(startDate.slice(-2)));
  };

  const handleNext = async nextStep => {
    const response = await http.post('/healthPlan/initiate/preview', getParams());
    setPreviewInfo(response.data);
    setCurrentStep(nextStep);
    setErrorMessage('');
  };

  const handleBack = previousStep => {
    setErrorMessage('');
    setCurrentStep(previousStep);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    await http.post('/healthPlan/initiate', getParams());
    navigate(0);
  };

  const getParams = () => {
    return {
      petId: pet.id,
      clientId: pet.client_id,
      locationId: 1,
      percentageOffId: percentageOff,
      paymentIntervalId: paymentInterval,
      startDate: startDate,
      billingDay: billingDay,
      downPayment: downPayment ? 'Y' : 'N',
    };
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error input-width">{errorMessage}</p>}

        {currentStep === 'planDetails' && (
          <React.Fragment>
            <h2 className="mb-4">Customize {pet.name}'s Health Plan:</h2>
            <Input
              className="mt-2"
              type="date"
              name="startDate"
              label="Contract Start Date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <InputEitherOr
              name="percentageOff"
              label="Type of Health Plan"
              value={percentageOff}
              option1={{ name: '50%', value: Constants.FIFTY_PERCENT }}
              option2={{ name: '90%', value: Constants.NINETY_PERCENT }}
              onChange={({ target }) => setPercentageOff(Number(target.value))}
            />
            <InputEitherOr
              name="paymentInterval"
              label="Payment Option"
              value={paymentInterval}
              option1={{ name: 'Monthly', value: Constants.MONTHLY }}
              option2={{ name: allowPaymentPlans ? 'Annual' : 'Annual*', value: Constants.ANNUAL }}
              onChange={handlePaymentIntervalChange}
              disabled={!allowPaymentPlans}
            />
            {!allowPaymentPlans && (
              <p className="sura mt-1">*Client is not eligible for Payment Plans</p>
            )}
            {isMonthly && isManager && (
              <InputEitherOr
                name="downPayment"
                label="Charge Down Payment?"
                value={downPayment}
                option1={{ name: 'Yes', value: true }}
                option2={{ name: 'No', value: false }}
                onChange={({ target }) => setDownPayment(JSON.parse(target.value))}
              />
            )}
            <ButtonPanel
              primaryButtonText="Next"
              handleCancel={handleClose}
              handleSubmit={() => handleNext('preview')}
              handleErrorMessage={setErrorMessage}
            />
          </React.Fragment>
        )}

        {currentStep === 'preview' && (
          <React.Fragment>
            <h2 className="mb-4">Preview</h2>
            <ContractPreview previewInfo={previewInfo} isHealthPlan={true} />
            <ButtonPanel
              primaryButtonText="Initiate Health Plan"
              handleCancel={handleClose}
              handleBack={() => handleBack('planDetails')}
              handleSubmit={handleSubmit}
              handleErrorMessage={setErrorMessage}
            />
          </React.Fragment>
        )}
      </form>
    </Modal>
  );
}

export default InitiateHealthPlan;
